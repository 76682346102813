import { useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { useAsyncEffect } from "use-async-effect";
import {
  InstagramUser,
  socialApi,
} from "../../api/socialApi";
import { ReactComponent as InstagramSvg } from "../../assets/social/instagram.svg";
import { ReactComponent as NoPagesSvg } from "../../assets/social/noPageFound.svg";
import { ReactComponent as CheckedBoxSVG } from "../../assets/whiteCheck.svg";
import { BasicMessagePopup } from "../../Massages/BasicMessagePopup";
import {
  InstagramConnectedPageInfo,
  refreshActiveProject,
} from "../../projects/projectsSlice";
import { ColorButton } from "../../projects/ProjectUIHelper";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { getAccessTokenSilence } from "../../auth/auth";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import "../social.scss";
import { SocialConnect } from "../Manager/SocialConnect";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import { ClientUrl } from "../../authConfig";
import { SuccessMessagePopup } from "../../Massages/SuccessMessagePopup";

export interface InstagramConnectProps {
  projectId: string;
  isClientView?: boolean;
  onConneted?(): void;
  projectInstagramInfo: InstagramConnectedPageInfo;
}

export const InstagramConnect: FC<InstagramConnectProps> = ({
  projectId,
  isClientView,
  onConneted,
  projectInstagramInfo,
}) => {
  const [showFacebookPages, setShowFacebookPages] =
    React.useState<boolean>(false);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [showNoPagesMessagePopup, setShowNoPagesMessagePopup] =
    React.useState<boolean>(false);
  const [showDeleteMessagePopup, setShowDeleteMessagePopup] =
    React.useState<boolean>(false);
  const [pageFetchDone, setPageFetchDone] = React.useState<boolean>(false);
  const [instagramUsers, setInstagramUsers] = React.useState<InstagramUser[]>(
    []
  );
  const [connectedUser, setConnectedUser] = React.useState<InstagramUser>();
  const [fbShortAccessToken, setFbShortAccessToken] =
    React.useState<string>("");
  const msalInstance = useMsal();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useAsyncEffect(async () => {
    if (fbShortAccessToken !== "") {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const longAccessInfoResponse =
          await socialApi.getLongInstagramAccessCode(
            fbShortAccessToken,
            projectId,
            token.accessToken
          );
        getUserPages(token.accessToken);
      }
    }
  }, [fbShortAccessToken]);

  const logInToInstagram = async () => {
    setInProgress(true);
    window.FB.login(
      (response: any) => {
        if (response.authResponse && response.authResponse.accessToken) {
          setFbShortAccessToken(response.authResponse.accessToken);
        } else {
          setInProgress(false);
        }
      },
      { config_id: "545977344388068" }
    );
  };

  const handleClose = () => {
    setShowFacebookPages(false);
  };

  const selectInstagramUser = async (user?: InstagramUser) => {
    if (user) {
      setInProgress(true);
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const data = await socialApi.setInstagramUser(
          projectId,
          user.instagramBusinessAccountId,
          token.accessToken
        );
        if (data) {
          setConnectedUser(user);
          setShowFacebookPages(false);
          if (onConneted) {
            onConneted();
          } else {
            dispatch(refreshActiveProject(token.accessToken));
            setTimeout(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
              });
            }, 500);
          }
        }
      }
      setInProgress(false);
    }
  };

  const getUserPages = async (token: string) => {
    setInProgress(true);
    if (!pageFetchDone) {
      const pageResponse = await socialApi.getInstagramPageList(
        projectId,
        token
      );
      if (pageResponse.users.length === 0) {
        setShowNoPagesMessagePopup(true);
      } else {
        setInstagramUsers(pageResponse.users);
        if (projectInstagramInfo.name) {
          const connectedUser = pageResponse.users.find(
            (p) => p.name === projectInstagramInfo.name
          );
          if (connectedUser) {
            setConnectedUser(connectedUser);
          }
        }
        setPageFetchDone(true);
      }
    } else if (projectInstagramInfo.name) {
      const connectedUser = instagramUsers.find(
        (p) => p.name === projectInstagramInfo.name
      );
      if (connectedUser) {
        setConnectedUser(connectedUser);
      }
    }
    setShowFacebookPages(true);
    setInProgress(false);
  };

  const onEditClick = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await getUserPages(token.accessToken);
    }
  };

  const sendLinkToClient = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await socialApi.sendClientRequuestToConnectToSocial(
        projectId,
        "facebook",
        `${ClientUrl}/social/collaborator/${projectId}`,
        token.accessToken
      );
    }
  };

  const disconnectFromInstagram = async () => {
    setShowDeleteMessagePopup(false);
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setInProgress(true);
      await socialApi.disconnectFromInstagram(projectId, token.accessToken);
      setConnectedUser(undefined);
      dispatch(refreshActiveProject(token.accessToken));
      setInProgress(false);
      setShowFacebookPages(false);
    }
  };

  return (
    <>
      <SocialConnect
        isClientView={isClientView}
        isConnected={
          projectInstagramInfo.connected &&
          projectInstagramInfo.id !== undefined
        }
        onConnectClick={() => logInToInstagram()}
        onSendClientConnect={() => sendLinkToClient()}
        socialIcon={<InstagramSvg />}
        onEditSocail={() => onEditClick()}
        bigSocialIcon={<InstagramSvg />}
        socialName={"Instagram"}
      />
      <BasicPageBlocker show={inProgress} />

      {showFacebookPages && (
        <BasicMessagePopup
          extraWidth="750px"
          onClose={handleClose}
          open={showFacebookPages}
        >
          <Box sx={{ margin: "20px 70px 0px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: "60px",
              }}
            >
              Please select the page you would like Lynxi to sync and manage
            </Box>
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "auto",
                paddingRight: "20px",
              }}
            >
              {instagramUsers.map((user: InstagramUser, pageIdx: number) => (
                <Box
                  key={`facebook-page-${pageIdx}`}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "50px",
                  }}
                >
                  <Box sx={{ fontSize: "16px" }}>
                    {user.name} ({user.userName})
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => setConnectedUser(user)}
                    className={
                      connectedUser === user ? "radioFull" : "radioEmpty"
                    }
                  >
                    {connectedUser === user && <CheckedBoxSVG />}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#6c3dab",
                }}
                onClick={() => setShowDeleteMessagePopup(true)}
              >
                Disconnect from Instagram
              </Box>
              <ColorButton
                onClick={() => selectInstagramUser(connectedUser)}
                disabled={!connectedUser}
              >
                Connect
              </ColorButton>
            </Box>
          </Box>
        </BasicMessagePopup>
      )}
      {showDeleteMessagePopup && (
        <DeleteMessageDialog
          onClose={() => setShowDeleteMessagePopup(false)}
          onDelete={disconnectFromInstagram}
          title={`Are you sure?`}
          message={`Please note disconnecting from Instagram will remove all your posts from Lynxi`}
        />
      )}
      {showNoPagesMessagePopup && (
        <SuccessMessagePopup
          open={showNoPagesMessagePopup}
          icon={<NoPagesSvg />}
          message={t("social.no_pages_facebook.message")}
          title={t("social.no_pages_facebook.title")}
          onClose={() => setShowNoPagesMessagePopup(false)}
          onContinue={() => setShowNoPagesMessagePopup(false)}
          continueAction={t("social.no_pages_facebook.action")}
          showAction={true}
        />
      )}
    </>
  );
};
