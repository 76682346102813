import moment from "moment";

export const getPrettyDate = (dateStr?: string, withTime = true, dateFormat = 'DD/MM/YYYY') => {
  if (dateStr) {
    const localTime  = moment.utc(dateStr).toDate();
    const dateFormatUsed = dateFormat === 'DD/MM/YYYY' ? 'DD.MM.YY' : 'MM.DD.YY';
    return moment(localTime).format(withTime ? `${dateFormatUsed} | HH:mm`: `${dateFormatUsed}`);
  }
  return "";
};

export const getYearAndMonth = (dateStr: string) => {
   const localTime  = moment.utc(dateStr).toDate();
   return {
    year: localTime.getFullYear(),
    month: localTime.getMonth()
   }
    
};

export const getPrettyDateOrTime = (dateStr?: string, dateFormat = 'DD/MM/YYYY') => {
  if (dateStr) {
    const date = moment.utc(dateStr);
    if (moment().diff(date, "days") >= 1) {
      const dateFormatUsed = dateFormat === 'DD/MM/YYYY' ? 'DD.MM.YY' : 'MM.DD.YY';
      return moment.utc(dateStr).format(`${dateFormatUsed} | HH:mm`);
    } else {
      if (moment().diff(date, "hours") > 1) {
        const minutes =
          moment().diff(date, "minutes") - moment().diff(date, "hours") * 60;
        return `${moment().diff(date, "hours")} hours, ${minutes} minutes ago`;
      } else {
        return `${moment().diff(date, "minutes")} minutes ago`;
      }
    }
  }
  return "";
};

export const getPassingDays = (dateStr?: string) => {
  if (dateStr) {
    const date = moment.utc(dateStr);
    const daysPast = moment().diff(date, "days")
    if (daysPast >= 1) {
      return `${daysPast} days ago`
    } else {
      if (moment().diff(date, "hours") >= 1) {
        const minutes =
          moment().diff(date, "minutes") - moment().diff(date, "hours") * 60;
        return `${moment().diff(date, "hours")} hours, ${minutes} minutes ago`;
      } else {
        return `${moment().diff(date, "minutes")} minutes ago`;
      }
    }
  }
  return "";
};

export const getInitialsFromFullName = (fullName: string) => {
  const names = fullName.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (fullName.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const setFirstLettersToUpper = (input: string) => {
  return input
    .split(" ")
    .map((word) => {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(" ");
};
