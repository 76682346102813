import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { FC } from "react";
import {
  Project,
  refreshActiveProject,
} from "../../projects/projectsSlice";

import { Box } from "@mui/material";
import {  keys } from "lodash";
import { useTranslation } from "react-i18next";
import { cmsApi } from "../../api/cmsApi";
import {
  breakTextToLines,
  ColorButtonPurpleOutLined,
} from "../../projects/ProjectUIHelper";
import "../social.scss";
import { getAccessTokenSilence } from "../../auth/auth";
import { getPrettyDate } from "../../utils/DateUtils";
import { useAppDispatch } from "../../app/hooks";
import { CmsValuesByTypes } from "../../cms/cmsSlice";
import { SocialSyncPreview } from "./SocialSyncPreview";
import { SocialSyncMessageFlow } from "./SocialSyncMessageFlow";
import { useNavigate } from "react-router-dom";
import { useAccountSettingsData } from "../../hooks/useAccountTasksTemplates";



export interface SocialItemDataDefinitions {
  key: string;
  cmsKey: number;
  compType: string;
  subKey?: string;
  ignoreForSocial?: boolean;
  socialLimit?: number;
  apiKey?: string;
  calculated?: boolean;
  socialOverideDescription?: string
}

export interface SocialSyncData {
  cmsDic: { [id: string]: any };
  cmsSchema: string;
  socialItems: SocialItemDataDefinitions[];
  locationExists: boolean;
  socialCmsDic: { [id: string]: any };
  cmsAiDic?: CmsValuesByTypes;
}

export interface SocialSyncResponse {
  success: boolean;
  socialSyncData?: SocialSyncData
}

export interface SocialSyncSuccessResponse {
  success: boolean;
  failedItems?: string[];
}

export interface SocialSyncProps {
  project: Project;
  updateDate?: string;
  onUpdateSocialData(socialItems: SocialItemDataDefinitions[],socialUpdateCmsRec: { [id: string]: any }, token: string):  Promise<SocialSyncSuccessResponse>
  onSync(): Promise<SocialSyncResponse>
  socialName: string;
  icon: React.ReactNode
  onlyFromSocial?: boolean;
  socialLink: string
}

export const SocialSync: FC<SocialSyncProps> = ({
  project,
  updateDate,
  onUpdateSocialData,
  onSync,
  socialName,
  icon,
  onlyFromSocial,
  socialLink
}) => {
  const [showSyncMessage, setShowSyncMessagee] = React.useState<boolean>(false);
  const [currentSyncStage, setCurrentSyncStage] = React.useState<number>(0);
  const [hasFailAddress, setHasFailAddress] = React.useState<boolean>(false);
  const {getAccountDateFormat} = useAccountSettingsData()


  const [socialSyncData, setSocialSyncData] = React.useState<
      SocialSyncData | undefined
  >();

  const msalInstance = useMsal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onContinueSync = async (
    cmsUpdateRec: { [id: string]: any },
    socialUpdateRec: { [id: string]: any },
    token: string
  ) => {
    setCurrentSyncStage(2);
    setShowSyncMessagee(true);
    const cmsDic = { ...socialSyncData?.cmsDic };
    setSocialSyncData(undefined);


    const cmsUpdateKeys = keys(cmsUpdateRec);
    if (cmsUpdateKeys.length > 0) {
      cmsUpdateKeys.forEach((key) => {
        cmsDic[key] = cmsUpdateRec[key];
      });
      await cmsApi.updateCmsData(
        JSON.stringify(cmsDic),
        project.cmsDataId??'',
        token
      );
    }

    if (keys(socialUpdateRec).length > 0 && socialSyncData) {
      const syncStatusOk = await onUpdateSocialData(socialSyncData.socialItems, socialUpdateRec, token);
      if (syncStatusOk.success) {
        setCurrentSyncStage(3);
      } else {
        if (syncStatusOk.failedItems) {
          setHasFailAddress(true)
          setCurrentSyncStage(3)
        }
        setCurrentSyncStage(4);
      }
    } else {
      setCurrentSyncStage(3);
    }
   
  };

  

  const goToNextStage = async () => {
    if (currentSyncStage === 0) {
      setHasFailAddress(false)
      setCurrentSyncStage(1);
      const socialSyncData = await onSync();
      if (socialSyncData.success) {
        setSocialSyncData(socialSyncData.socialSyncData);        
      } else {  
        setCurrentSyncStage(4);
      }
    } else {
      setCurrentSyncStage(0);
      setShowSyncMessagee(false);
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        dispatch(refreshActiveProject(token.accessToken));
      }
    }
  };

  const navigateResult= (toCms: boolean) => {
    if (toCms) {
      navigate(`/projects/${project.id}/cms/${project.cmsDataId}/content`)
    } else {
      window.open(socialLink, '_blank')
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          boxShadow: "0 30px 60px 0 rgba(0, 0, 0, 0.04)",
          border: "solid 1px #e7e7e7",
          backgroundColor: "#f5f8f7",
          padding: "20px",
          flexDirection: "column",
        }}
      >
        {updateDate ? (
          <>
            <Box>
              {breakTextToLines(t("social.manager.syncBox.message_sync"))}
            </Box>
            <Box sx={{ color: "#717171" }}>
              {`(last update ${getPrettyDate(
                updateDate,
                false,
                getAccountDateFormat()
              )})`}
            </Box>
          </>
        ) : (
          <>
            <Box>
              {breakTextToLines(t("social.manager.syncBox.message_not_synced"))}
            </Box>
            <Box sx={{ color: "#717171" }}>
              {t("social.manager.syncBox.message_not_synced_sub")}
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Box>
            {icon}
          </Box>
          <Box>
            <ColorButtonPurpleOutLined
            sx={{padding: '2px 8px'}}
              onClick={() => setShowSyncMessagee(true)}
            >
              {t("social.manager.syncBox.action")}
            </ColorButtonPurpleOutLined>
          </Box>
        </Box>
      </Box>
      {showSyncMessage && (
        <SocialSyncMessageFlow
          onClose={() => {
            setCurrentSyncStage(0);
            setShowSyncMessagee(false);
          }}
          open={showSyncMessage}
          stage={currentSyncStage}
          onNextStep={() => goToNextStage()}
          socialName={socialName}
          onFinish={(toCms: boolean) => navigateResult(toCms)}
          onlyFromSocial={onlyFromSocial}
          failedAddress={hasFailAddress}
        />
      )}
      {socialSyncData && (
        <SocialSyncPreview
          socialSyncData={socialSyncData}
          show={true}
          onClose={(noSyncNeeded) => {
            setSocialSyncData(undefined);
            if (noSyncNeeded) {
              setCurrentSyncStage(3);
              setShowSyncMessagee(true);
            } else {
              setCurrentSyncStage(0);
              setShowSyncMessagee(false);
            }
          }}
          onContineSync={onContinueSync}
          icon={icon}
          socialName={socialName}
          onlyFromSocial={onlyFromSocial}
        />
      )}
    </>
  );
};
