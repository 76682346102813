import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { breakTextToLines } from "../../projects/ProjectUIHelper";
import { useTranslation } from "react-i18next";
import { BasicMessagePopup } from "../../Massages/BasicMessagePopup";
import { ReactComponent as SyncPurpleSvg } from "../../assets/social/sync_purple.svg";
import { ReactComponent as GearLoading } from "../../assets/gearLloadingSmall.svg";
import { ReactComponent as ShareFailerSVG } from "../../assets/cms/shareFailer.svg";
import { ReactComponent as SuccessSVG } from "../../assets/cms/publishSuccess.svg";
import { SOCIAL_NAME_LANG_TOKEN } from "../../utils/Constants";

export interface SocialSyncMessageFlowProps {
  socialName: string;
  onClose(): void;
  open: boolean;
  extraWidth?: string;
  disableAutoClose?: boolean;
  stage: number;
  onNextStep(): void;
  onlyFromSocial?: boolean;
  onFinish(toCms: boolean): void;
  failedAddress?: boolean;
}

export const SocialSyncMessageFlow: FC<SocialSyncMessageFlowProps> = ({
  socialName,
  onClose,
  open,
  extraWidth,
  disableAutoClose,
  stage,
  onNextStep,
  onlyFromSocial,
  onFinish,
  failedAddress
}) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (stage) {
      case 0:
        return <SyncPurpleSvg />;
      case 1:
      case 2:
        return <GearLoading />;
      case 3:
        return <SuccessSVG />;
      case 4:
        return <ShareFailerSVG />;
      case 5:
        return <ShareFailerSVG />;
    }
  };

  return (
    <BasicMessagePopup
      icon={getIcon()}
      disableAutoClose={disableAutoClose}
      onClose={onClose}
      open={open}
      extraWidth={extraWidth}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          flexDirection: "column",
          fontFamily: "Nunito",
          justifyContent: "center",
          fontSize: "14px",
          width: "70%",
          paddingBottom: "20px",
        }}
      >
        <Box sx={{ fontWeight: 600, marginTop: "32px", fontSize: "16px" }}>
          {breakTextToLines(
            t(
              `social.manager.social_sync_process.stage_${stage}.title${
                onlyFromSocial ? "_only_social" : ""
              }`
            ).replace(SOCIAL_NAME_LANG_TOKEN, socialName)
          )}
        </Box>
        <Box sx={{ fontWeight: 400, marginTop: "20px", marginBottom: "35px" }}>
          {breakTextToLines(
            t(
              `social.manager.social_sync_process.stage_${stage}.message${
                onlyFromSocial ? "_only_social" : ""
              }`
            ).replace(SOCIAL_NAME_LANG_TOKEN, socialName)
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "80%",
          margin: "0 auto",
          justifyContent: stage === 3? "space-between": "center",
        }}
      >
        {stage === 3 ? (
          <>
            <Box sx={{cursor:'pointer'}}   onClick={ () => onFinish(false)}>To {socialName}</Box>
            <Box onClick={ () => onFinish(true)}><ColorButton>To Contnet Hub</ColorButton></Box>
          </>
        ) : (
          t(`social.manager.social_sync_process.stage_${stage}.action`) !==
            "" && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ColorButton onClick={() => onNextStep()}>
                {t(
                  `social.manager.social_sync_process.stage_${stage}.action`
                ).replace(SOCIAL_NAME_LANG_TOKEN, socialName)}
              </ColorButton>
            </Box>
          )
        )}
      </Box>
    </BasicMessagePopup>
  );
};
