import { Box, Divider } from "@mui/material";
import { FC } from "react";
import { PostInsightsData, PostItemData, SocialItemData } from "../../api/socialApi";
import { getPrettyDate } from "../../utils/DateUtils";
import * as React from "react";
import { AvaliableSocial } from "./PostUIHelper";
import useAsyncEffect from "use-async-effect";
import { ReactComponent as EmptyPostSVG } from "../../assets/social/defaultPostImage.svg";
import { ReactComponent as LikeVG } from "../../assets/social/like.svg";
import { ReactComponent as CommentSVG } from "../../assets/social/numComment.svg";
import { ReactComponent as ReUseSVG } from "../../assets/social/reusePost.svg";
import { ReactComponent as TrashSvg } from "../../assets/project/trash.svg";
import { get } from "lodash";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../projects/ProjectsRouter";
import Carousel from "react-material-ui-carousel";
import { useAccountSettingsData } from "../../hooks/useAccountTasksTemplates";

export interface PostHistoryLineProps {
  post: SocialItemData;
  narrow?:boolean
  onDeletePost(post: SocialItemData, selectedSocial?:string):void
  postInsights?: PostInsightsData
}

export const PostHistoryLine: FC<PostHistoryLineProps> = ({ post, narrow, onDeletePost, postInsights }) => {
  const [postData, setPostData] = React.useState<PostItemData | undefined>(
    undefined
  );
  const [likes, setLikes] = React.useState<number>(
    0
  );
  const [comments, setComments] = React.useState<number>(
    0
  );
  const [selectedSocial, setSelectedSocial] = React.useState<string>("");
  const navigate = useNavigate()

  const [showDeleteMessagePopup, setShowDeleteMessagePopup] =
  React.useState<boolean>(false);
  const projectContext = React.useContext(ProjectContext)
  const {getAccountDateFormat} = useAccountSettingsData()

  useAsyncEffect(async () => {
    if (post.facebook) {
      setPostData(post.facebook);
      setSelectedSocial("facebook");
    } else if (post.linkedin) {
      setPostData(post.linkedin);
      setSelectedSocial("linkedin");
    } else {
      setPostData(post.instagram);
      setSelectedSocial("instagram");
    }
  }, [post, postInsights]);

  useAsyncEffect(async () => {
    updatePostInsights(selectedSocial)
  }, [postInsights]);


  const updatePostInsights = (socialName: string) => {
    if (postInsights !== undefined) {
      const postSocialInsight = get(postInsights, socialName);
      if (postSocialInsight) {
        setLikes(postSocialInsight.likes??0);
        setComments(postSocialInsight.comments??0);
      } else {
        setLikes(0);
        setComments(0);
      }
    }
  }

  const onSelectSocial = (socialName: string) => {
    const social = get(post, socialName);
    if (social) {
      setPostData(social);
      setSelectedSocial(socialName);
      updatePostInsights(socialName)
    }
  };

  const renderSocialButton = (socialName: string) => {
    const social = AvaliableSocial.find(
      (s) => s.title.toLowerCase() === socialName
    );
    if (social && get(post, socialName)) {
      return (
        <Box
          sx={{
            cursor: selectedSocial === socialName ? "auto" : "pointer",
            borderTopLeftRadius:  "7.2px",
            borderTopRightRadius:  "7.2px",
            boxShadow: "inset 0px 1px 4px 0 rgba(0, 0, 0, 0.08)",
            padding: "10px 10px 4px 10px",
            background: selectedSocial === socialName? (narrow? "#F5F8F7": "#fff") :  (narrow? "#fff" : "#F5F8F7"),
          }}
          onClick={() =>
            selectedSocial === socialName ? {} : onSelectSocial(socialName)
          }
        >
          <img width='25px' src={`/images/social/${socialName}_${selectedSocial === socialName ?'on':'off'}.svg`}/>
        </Box>
      );
    } else return null;
  };

  // const onEditPost = () => {
  //   //
  // };

  const onDuplicatePost = () => {
     navigate(`/projects/${projectContext?.project.id}/posts/${post.id}/reuse`)
  };


  const onDeleteApproved = (selectedSocial?: string) => {
     setShowDeleteMessagePopup(false)
     onDeletePost(post, selectedSocial)
  }  

  return (
    <>
      {postData && (
        <Box sx={{ display: "flex", flexDirection: "column", marginBottom: '30px' , flexGrow:1}}>
          <Box sx={{ display: "flex", gap: "5px", marginLeft: '15px'}}>
            {renderSocialButton("facebook")}
            {renderSocialButton("linkedin")}
            {renderSocialButton("instagram")}
          </Box>
          <Box
            sx={{
              display: "flex",
              backgroundColor: narrow?'#f5f8f7': "#fff",
              minHeight: '160px',
              maxHeight:'160px',             
              borderRadius: "10px",
              padding: "10px",
              boxShadow: "0 30px 60px 0 rgba(0, 0, 0, 0.04)",
            }}
          >
            <Box
              sx={{
                marginTop: "10px",
                width: "135px",
                height: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: '20px',
                marginLeft: '20px'
              }}
            >
              {postData.images.length>0 ? (
                postData.images.length === 1 ? (
                    <img src={postData.images[0]} width="135px" />
                ): (
                  <Carousel
                      autoPlay={false}
                      animation="slide"
                      indicators={true}
                      indicatorContainerProps={{
                        style: {
                          marginTop: "40px", // 5
                          textAlign: "center", // 4
                        },
                      }}
                      sx={{ width: "135px", height: "120px" }}
                    >
                      {postData.images.map((item, i) => (
                        <Box
                          key={`sdd${i}`}
                          sx={{
                            width: "100%",
                            fontWeight: 300,
                            height: "50px",
                          }}
                        >
                          <img src={item} width="135px" />
                        </Box>
                      ))}
                    </Carousel>
                )
              ) : (
                <EmptyPostSVG />
              )}{" "}
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Divider sx={{ borderColor: "#d8d8d8" }} orientation="vertical" />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                height:'100%'
              }}
            >
              <Box sx={{ display: "flex", paddingTop:'5px', paddingBottom:'5px', fontSize:'14px', paddingLeft: '20px' }}>
                <Box>
                  {postData.published ? getPrettyDate(postData.published, false, getAccountDateFormat()) : ""}
                </Box>
              </Box>
              <Box sx={{display:'flex', height:'auto', alignItems:'center', padding:'10px 10px 10px 20px' , overflowY: 'auto'}}>{postData.content}</Box>
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Divider sx={{ borderColor: "#d8d8d8" }} orientation="vertical" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0 20px",                
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "10px",
                }}
              >
                <LikeVG /><Box sx={{display:'flex'}}>Likes: {likes}</Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <CommentSVG /><Box sx={{display:'flex', width:'max-content'}}>Comments: {comments}</Box>
              </Box>
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Divider sx={{ borderColor: "#d8d8d8" }} orientation="vertical" />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "30px",
                marginLeft:'10px',
                marginRight:'10px',
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                }}
                onClick={() => setShowDeleteMessagePopup(true)}
              >
                <HelpToolTipOnHover
                  position="bottom"
                  message={"Delete post"}
                  actionNode={<TrashSvg />}
                />
              </Box>
              {/* <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                }}
                onClick={() => onEditPost()}
              >
                <HelpToolTipOnHover
                  position="bottom"
                  message={"Edit post"}
                  actionNode={<EditBriefPencilSvg />}
                />
              </Box> */}
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  height: "30px",
                }}
                onClick={() => onDuplicatePost()}
              >
                <HelpToolTipOnHover
                  position="bottom"
                  message={"ReUse post"}
                  actionNode={<ReUseSVG />}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {showDeleteMessagePopup && (
       <DeleteMessageDialog 
          onClose={() => setShowDeleteMessagePopup(false)}
          onDelete={onDeleteApproved}
          actionText="Delete all"
          secondActionText={post.facebook && post.linkedin ?`Delete ${selectedSocial} only`: undefined}
          onSecondAction={post.facebook && post.linkedin? () => onDeleteApproved(selectedSocial): undefined}
          title={`Are you sure?`}
          message={`Please note deleting this post from Lynxi will delete it entirely (from all social networks).`}
        />
      )}
    </>
  );
};
