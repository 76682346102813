// import ImagePicker from '../ImagePicker/ImagePicker';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Fab, Grid, Icon } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useAsyncEffect } from "use-async-effect";
import "../cms.scss";
import { ImagePicker } from "./ImagePicker";
import { ReactComponent as PdfSvg } from "../../assets/cms/ext/PDF.svg";
import { ReactComponent as TxtSvg } from "../../assets/cms/ext/TXT.svg";
import { ReactComponent as ReplaceImageSvg } from "../../assets/cms/exchangeImage.svg";
import { ReactComponent as DeleteImageSvg } from "../../assets/cms/trashImage.svg";
import { ReactComponent as EyeSvg } from "../../assets/project/eye.svg";

export interface RenderImagepProps {
  value: string;
  index: number;
  updateImage(index: number, img: string, description?: string): void;
  removeImage(index: number): void;
  newWidth?: string;
  newHeight?: string;
  fullWidth?: boolean;
  center: boolean;
  readonly?: boolean;
  onlyUpload?: boolean;
  size?: string;
  contentType?: string;
  allfiles?: boolean;
  background?: string;
  display?: string;
  singleFile?: boolean;
  isAccount?: boolean;
}

export const RenderImage: React.FC<RenderImagepProps> = ({
  value,
  fullWidth,
  index,
  newWidth,
  center,
  newHeight,
  updateImage,
  removeImage,
  readonly,
  onlyUpload,
  size,
  contentType,
  allfiles,
  background,
  display,
  singleFile,
  isAccount
}) => {
  const [anchor, setAnchor] = useState<Element | undefined>(undefined);
  const [showImages, setShowImages] = useState<boolean>(false);
  const [imgWidth, setImgWidth] = useState<string>("");
  const [imgHeight, setImgHeight] = useState<string>("");

  const handlePopoverOpen = (event: React.MouseEvent) => {
    const r =   event.currentTarget
    setTimeout( () => setAnchor(r), 200);
  };

  const imageRef = React.useRef<HTMLImageElement>(null);

  const handlePopoverClose = () => {
    setTimeout( () => setAnchor(undefined), 100);
    // setAnchor(undefined);
  };

  const addImage = (imgSrc: string, description?: string) => {
    updateImage(index, imgSrc, description);
    setShowImages(false);
  };

  const deleteImage = () => {
    removeImage(index);
    setAnchor(undefined);
  };

  useAsyncEffect(() => {
    setImgWidth(
      newWidth ? newWidth : fullWidth ? size ?? "825px" : size ?? "150px"
    );
    setImgHeight(newHeight ? newHeight : "150px");
  }, []);

  const isDocument = () => {
    return contentType && contentType.indexOf("image") === -1;
  };

  const getDocIcon = () => {
    if (contentType) {
      if (contentType.toLowerCase().indexOf("pdf") > -1) {
        return <PdfSvg width={"150px"} height={"150px"} />;
      } else {
        <TxtSvg width={"150px"} height={"150px"} />;
      }
    } else {
      <TxtSvg width={"150px"} height={"150px"} />;
    }
  };

  return (
    <>
      {value !== "" ? (
        <Box
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={() => handlePopoverClose()}
          sx={{
            background: background ?? "#f9f9f9",
            width: imgWidth,
            margin: center ? "0px auto" : "",
            height: imgHeight,
            borderRadius: size === "142px" ? "10px" : "20px",
            position: "relative",
          }}
          className="image-container"
        >
          {fullWidth ? (
            <img
              onError={(e: any) => {
                if (e.target.src.indexOf("/images/attach.svg") === -1) {
                  e.target.onerror = null;
                  e.target.src = "/images/attach.svg";
                  e.target.removeAttribute("srcset");
                }
              }}
              ref={imageRef}
              style={{
                width: imgWidth,
                height: imgHeight,
                borderRadius: size === "142px" ? "10px" : "20px",
                backgroundImage: `url(${value})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: display? display: "cover",
                opacity: anchor ? 0.3 : 1,
              }}
            />
          ) : isDocument() ? (
            <Box
              sx={{
                width: imgWidth,
                height: imgHeight,
                borderRadius: size === "142px" ? "10px" : "20px",
                opacity: anchor ? 0.3 : 1,
              }}
            >
              {getDocIcon()}
            </Box>
          ) : (
            <img
              // src={value}
              ref={imageRef}
              onError={(e: any) => {
                if (e.target.src.indexOf("/images/attach.svg") === -1) {
                  e.target.onerror = null;
                  e.target.src = "/images/attach.svg";
                  e.target.removeAttribute("srcset");
                }
              }}
              style={{
                backgroundImage: `url(${value})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: imgWidth,
                height: imgHeight,
                borderRadius: size === "142px" ? "10px" : "20px",
                opacity: 1
              }}
              className="image-under-hover"
            />
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              // backgroundColor: 'rgba(0, 0, 0, 0.3)',
              transition: 'opacity 0.3s ease',
              pointerEvents: 'auto'
            }}
            className="image-hover"
          >
            {readonly ? (
              <Grid
                container
                sx={{
                  width: imageRef.current?.getBoundingClientRect().width,
                  height: imgHeight,
                  pointerEvents: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Fab
                  size="small"
                  sx={{
                    background: "#fff",
                    "&:hover": { background: "#e0e0e0" },
                  }}
                  aria-label="replace"
                  onClick={() => window.open(value, "_blank")}
                  style={{ margin: "10px" }}
                >
                  <EyeSvg style={{ transform: "scale(0.8)" }} />
                </Fab>
              </Grid>
            ) : (
              <Grid
                container
                sx={{
                  width: imageRef.current?.getBoundingClientRect().width,
                  height: imgHeight,
                  pointerEvents: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Fab
                  size="small"
                  sx={{
                    background: "#fff",
                    "&:hover": { background: "#e0e0e0" },
                  }}
                  aria-label="delete"
                  onClick={() => deleteImage()}
                  style={{ transform: 'scale(0.8)' }} 
                >
                  <DeleteImageSvg style={{ transform: "scale(0.6)" }} />
                </Fab>
                {isDocument() ? (
                  <Fab
                    size="medium"
                    color="primary"
                    aria-label="replace"
                    onClick={() => window.open(value, "_blank")}
                    style={{ transform: 'scale(0.8)' }} 
                  >
                    <VisibilityIcon />
                  </Fab>
                ) : (
                  <Fab
                    sx={{
                      background: "#fff",
                      "&:hover": { background: "#e0e0e0" },
                    }}
                    size="small"
                    aria-label="replace"
                    onClick={() => setShowImages(true)}
                    style={{ transform: 'scale(0.8)' }} 
                  >
                    <ReplaceImageSvg style={{ transform: "scale(0.6)" }} />
                  </Fab>
                )}
                <Fab
                  size="small"
                  sx={{
                    background: "#fff",
                    "&:hover": { background: "#e0e0e0" },
                  }}
                  aria-label="replace"
                  onClick={() => window.open(value, "_blank")}
                  style={{ transform: 'scale(0.8)' }} 
                   >
                  <EyeSvg style={{ transform: "scale(0.7)" }} />
                </Fab>
              </Grid>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            background: background ?? "#f9f9f9",
            width: imgWidth,
            margin: center ? "0px auto" : "",
            height: imgHeight,
            border: "0.8px dashed #087FED",
            borderRadius: "7px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!readonly && (
            <Icon
              style={{
                fontSize: 50,
                cursor: "pointer",
              }}
              onClick={() => setShowImages(true)}
            >
              upload
            </Icon>
          )}
        </Box>
      )}
      <ImagePicker
        onClose={() => setShowImages(false)}
        onAddImages={(img: string, description?: string) =>
          addImage(img, description)
        }
        showView={showImages}
        onlyUpload={onlyUpload}
        allfiles={allfiles}
        singleFile={singleFile}
        isAccount={isAccount}
      />
    </>
  );
};
