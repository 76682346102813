import { useMsal } from "@azure/msal-react";
import AddIcon from "@mui/icons-material/Add";
import { Backdrop, Box, CircularProgress, Fab, Grid } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { Project } from "../projects/projectsSlice";
import { getAccessTokenSilence } from "../auth/auth";
import { TableHeader } from "../projects/ProjectUIHelper";
import { DeleteConfirmationPopup } from "../PopUpMessage/DeleteProjectPopup";
import { SharedFile, projectsApi } from "../api/projectsApi";
import { ShareFilesPopup } from "./ShareFilesPopup";
import { EmptyFilesView } from "./EmptyFilesView";
import { FileSharedLine } from "./FileSharedLine";
import { orderBy } from "lodash";
import { SuccessMessagePopup } from "../Massages/SuccessMessagePopup";
import { ReactComponent as InviteSvg } from "../assets/storage/invitationDashboard.svg";
import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";
import { FilesCommentsManager } from "./FilesCommentsManager";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";

export interface FilesManagerViewProps {
  project: Project;
  isClient?: boolean;
  shareTitle: string;
}

export const FilesManagerView: FC<FilesManagerViewProps> = ({
  project,
  isClient,
  shareTitle,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [showManager, setShowManager] = useState<boolean>(false);
  const [showShareFilePopup, setShowShareFilePopup] = useState<boolean>(false);
  const [showShareMessage, setShowShareMessage] = useState<boolean>(false);
  const [showInviteMessage, setShowInviteMessage] = useState<boolean>(false);
  const [showSaveMessage, setShowSaveMessage] = useState<boolean>(false);
  const [showInvitePopup, setShowInvitePopup] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<any>();
  const [sharedFiles, setSharedFiles] = useState<SharedFile[]>([]);
  const [fileToShare, setFileToShare] = useState<SharedFile | undefined>(
    undefined
  );
  const [commentAnchorEl, setCommentAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const projectsState = useAppSelector((state: RootState) => state.projectsData);

  const [fileForComments, setFileForComments] = useState<
    SharedFile | undefined
  >(undefined);
  const [onProcessing, setOnProcessing] = useState<boolean>(false);
  const { t } = useTranslation();
  const msalInstance = useMsal();

  const TableTitleList = [
    {
      name: t("file_sharing.table.header.file_name"),
      size: 2,
      align: "left",
    },
    {
      name: t("file_sharing.table.header.uploaded_by"),
      size: 2,
      align: "center",
    },
    {
      name: t("file_sharing.table.header.updated_date"),
      size: 2,
      align: "center",
    },
    {
      name: t("file_sharing.table.header.sent"),
      size: 2,
      align: "center",
    },
    {
      name: t("file_sharing.table.header.status"),
      size: 1,
      align: "center",
    },
    {
      name: "",
      size: 3,
      align: "center",
    },
  ];

  const onDeleteFile = (fileInfo: any, anchor: HTMLDivElement) => {
    setAnchorEl(anchor);
    setFileToDelete(fileInfo);
  };

  const onCancelDelete = () => {
    setFileToDelete(undefined);
    setAnchorEl(null);
  };

  const onDeleteApproved = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token && fileToDelete) {
      setOnProcessing(true);
      await projectsApi.deleteSharedFile(
        project.id,
        fileToDelete.id,
        token.accessToken
      );
      await getSharedFiles();
      setOnProcessing(false);
    }
    setAnchorEl(null);
    setFileToDelete(undefined);
  };

  useAsyncEffect(async () => {
    await getSharedFiles();
  }, [project.id, projectsState.filesForceUpdate]);

  const getSharedFiles = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    setOnProcessing(true);
    if (token) {
      const getFilesResponse = await projectsApi.getAllSharedFiles(
        project.id,
        token.accessToken
      );
      if (getFilesResponse) {
        if (getFilesResponse.files) {
          setSharedFiles(
            orderBy(getFilesResponse.files, ["sharedAt"], ["asc"])
          );
          setShowManager(true);
        }
      } else {
        setShowManager(true);
        setSharedFiles([]);
      }
    }
    setOnProcessing(false);
  };

  const onCloseShare = (onlySave: boolean) => {
    if (onlySave) {
      setShowSaveMessage(onlySave);
      getSharedFiles();
    }
    setShowShareFilePopup(false);
    setShowInvitePopup(false);
    setFileToShare(undefined);
  };

  const onSharedSuccess = () => {
    setFileToShare(undefined);
    if (showInvitePopup) {
      setShowInviteMessage(true);
    } else {
      setShowShareMessage(true);
    }
    getSharedFiles();
    setShowShareFilePopup(false);
    setShowInvitePopup(false);
  };

  const onShareFile = async (fileInfo: SharedFile) => {
    setFileToShare(fileInfo);
  };

  const onCommentClick = (fileInfo: SharedFile, comp: HTMLDivElement) => {
    setCommentAnchorEl(comp);
    setFileForComments(fileInfo);
  };

  const updateFileComments = async (fileInfo: SharedFile, comments: any[]) => {
    const _sharedFiles =  [...sharedFiles];
    const fileIndex = _sharedFiles.findIndex((f) => f.id === fileInfo.id);
    if (fileIndex > -1) {
      _sharedFiles[fileIndex].comments = comments;
      setSharedFiles(_sharedFiles);
    }
  };

  return (
    <>
      {showManager && (
        <Box sx={{ display: "flex", width: "100%", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderRadius: "10px",
              flexGrow: 1,
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "27px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "30px",
                  flexGrow: 1,
                  fontFamily: "Nunito",
                  fontSize: "17px",
                  fontWeight: "bold",
                }}
              >
                {t("file_sharing.title")}
              </Box>
              <Box sx={{ marginRight: "30px", cursor: "pointer" }}>
                {!isClient && (
                  <HelpToolTipOnHover
                    position="left"
                    message={"Invite your client to share files"}
                    actionNode={
                      <InviteSvg onClick={() => setShowInvitePopup(true)} />
                    }
                  />
                )}
              </Box>
            </Box>
            {sharedFiles.length === 0 ? (
              <EmptyFilesView
                isClient={isClient}
                onAddFile={() => setShowShareFilePopup(true)}
                onInvite={() => setShowInvitePopup(true)}
              />
            ) : (
              <Box
                sx={{
                  flexGrow: 1,
                  margin: "30px 30px 0px",
                }}
              >
                <Grid container spacing={1}>
                  {TableTitleList.map((title, idx) => (
                    <Grid item xs={title.size} key={`table-title-${idx}`}>
                      <TableHeader
                        sx={{
                          paddingLeft: title.align === "left" ? "10px" : "0px",
                          justifyContent: title.align,
                        }}
                      >
                        {title.name}
                      </TableHeader>
                    </Grid>
                  ))}
                </Grid>
                <Box>
                  {sharedFiles.map((shareFile, idx) => (
                    <FileSharedLine
                      key={`file-share-${idx}`}
                      fileInfo={shareFile}
                      selected={false}
                      isClient={isClient}
                      onDeleteFile={onDeleteFile}
                      onCommentClick={onCommentClick}
                      onShareFile={onShareFile}
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    marginTop: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        marginRight: "12px",
                        fontFamily: "Nunito",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Upload new file
                    </Box>
                    <Box>
                      <Fab
                        size="small"
                        sx={{
                          background: "#000",
                          color: "#fff",
                          width: "35px",
                          height: "35px",
                          "&:hover": { color: "#000" },
                        }}
                        onClick={() => setShowShareFilePopup(true)}
                      >
                        <AddIcon fontSize="medium" sx={{ width: "18px" }} />
                      </Fab>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {(showShareFilePopup || fileToShare || showInvitePopup) && (
              <ShareFilesPopup
                onClose={(onlySave: boolean) => onCloseShare(onlySave)}
                onSharedSuccess={() => onSharedSuccess()}
                project={project}
                fileToShare={fileToShare}   
                title={
                  showInvitePopup
                    ? "Invite your client to share files"
                    : shareTitle
                }
                onlyInvite={showInvitePopup}
              />
            )}

            {fileForComments && commentAnchorEl && (
              <FilesCommentsManager
                anchorEl={commentAnchorEl}
                isClient={isClient}
                onClose={ () => {
                  setFileForComments(undefined);
                  setCommentAnchorEl(null);
                }}
                projectId={project.id}
                comments={fileForComments.comments}
                commentId={fileForComments.id}
                setComments={(comments: any[]) => {
                  updateFileComments(fileForComments, comments);
                }}
              />
            )}

            <DeleteConfirmationPopup
              title={`Are you sure you want to delete "${fileToDelete?.description}" file?`}
              description={
                "This file will no longer be avaliable to your client."
              }
              onDeleteSite={() => onDeleteApproved()}
              onClose={() => onCancelDelete()}
              anchorEl={anchorEl}
              autoClose={true}
            />
            {showSaveMessage && (
              <SuccessMessagePopup
                open={showSaveMessage}
                message={
                  isClient
                    ? t("file_sharing.success_save.message_client")
                    : t("file_sharing.success_save.message")
                }
                title={t("file_sharing.success_save.title")}
                onClose={() => setShowSaveMessage(false)}
                onContinue={() => setShowSaveMessage(false)}
                continueAction={t("file_sharing.success_save.action")}
                showAction={true}
              />
            )}
            {(showShareMessage || showInviteMessage) && (
              <SuccessMessagePopup
                open={showShareMessage || showInviteMessage}
                message={showInviteMessage? t("file_sharing.success_invite.message"): t("file_sharing.success_share.message")}
                title={t("file_sharing.success_share.title")}
                onClose={() => {
                  setShowShareMessage(false)
                  setShowInviteMessage(false)
                }}
                onContinue={() =>{
                  setShowShareMessage(false)
                  setShowInviteMessage(false)
                }}
                continueAction={t("file_sharing.success_share.action")}
                showAction={true}
              />
            )}
          </Box>
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={onProcessing}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
    </>
  );
};
