import { AccountInfo } from "@azure/msal-browser";
import _, { get, intersection, pick } from "lodash";
import { flatMap, map } from "lodash/fp";
import { adminApi, ProcessAiRequest } from "../api/adminApi";
import { cmsApi, CMSResponse } from "../api/cmsApi";
import { projectsApi } from "../api/projectsApi";
import { SavedQuestion } from "../api/surveyApi";
import {
  uploadApi,
  UploadCmsImageResponse,
  UploadDocumentResponse,
} from "../api/uploadApi";
import {
  CMSState,
  CmsValuesByTypes,
  ListValues,
  Page,
  PositionIndexBuilder,
  Section,
} from "../cms/cmsSlice";
import {
  BUSINESS_CATEGORY_CMS_ID,
  BUSINESS_FOUNDED_CMS_ID,
  BUSINESS_NAME_CMS_ID,
  BUSINESS_SUB_CATEGORY_CMS_ID,
  DAYS_OF_THE_WEEK,
  KEYWORDS_CMS_ID,
  SERVICE_CMS_ID,
} from "../utils/Constants";
import TreeUtils from "../utils/TreeUtils";
import { DupicateCheckResults } from "./BriefPicker/BriefPicker";
import { Project, UserInfo } from "./projectsSlice";
import { LynxiAccountInfo } from "../api/backofficeApi";

export interface ProjectMenuItem {
  text: string;
  icon: string;
  action: string;
  color?: string;
  mustHaveQuestionnaire?: boolean;
  status?: string;
  projectStatus?: string;
}

export interface ImageAnswerValue {
  name: string;
  type: string;
  content: string;
  metaId: string;
  url?: string;
  src?: string;
}

export default class ProjectUtils {
  static statusArray = {
    new: { name: " New ", color: "primary" },
    inprogress: { name: " In Progress ", color: "secondary" },
    review: { name: " Review ", color: "warning" },
    cms: { name: " CMS ", color: "success" },
    onhold: { name: "On Hold", color: "#EB5757" },
  };

  static menuItems: ProjectMenuItem[] = [
    { text: "Edit Project", icon: "edit", action: "edit" },
    { text: "Questionnaire", icon: "visibility", action: "questionnaire" },
    {
      text: "Share Questionnaire",
      icon: "mail",
      action: "email",
      mustHaveQuestionnaire: true,
    },
    { text: "Go To CMS", icon: "article", action: "cms" },
    { text: "Delete Project", icon: "delete", color: "red", action: "delete" },
  ];

  static getRequiredProjectKeyValue = (projectDetails?: Section) => {
    if (projectDetails) {
      const dic: { [id: string]: string } = {};
      TreeUtils.extractFromSection(projectDetails.singles, dic);
      const projectName = get(dic, "8001", "");
      const email = get(dic, "8000", "");
      return { email: email, projectName: projectName };
    }
    return { email: "", projectName: "" };
  };

  static getAiItemsFromAnswers = (surveyResults: SavedQuestion[]) => {
    const aiItemDic: { [id: string]: any } = {};
    surveyResults
      .filter((s) => s.ai && s.ai !== "")
      .forEach((s) => {
        if (s.ai && s.value) {
          if (s.commentValue && s.commentValue !== "") {
            aiItemDic[s.ai] = s.commentValue;
          } else {
            if (!(s.type === "dropdown" && s.value.toLowerCase() === "other")) {
              aiItemDic[s.ai] = s.value;
            }
          }
        }
      });
    return aiItemDic;
  };

  static b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  static updateClientEmailIfMissing = () => {
    //
  };

  static uploadBase64ImageToAzure = async (
    token: string,
    base64Image: string,
    cmsId: string,
    fileType: string
  ) => {
    const blob = ProjectUtils.b64toBlob(base64Image, fileType);
    const fileExt = fileType.split("/").pop();
    const fileToUploadUrl = await uploadApi.getUploadCMSUrl(
      cmsId,
      fileExt ? fileExt : "png",
      token
    );
    await uploadApi.uploadImageToAzure(
      fileToUploadUrl.uploadLink,
      blob,
      fileType
    );
    return Promise.resolve(fileToUploadUrl);
  };

  static uploadImagesForMetaSiteId = async (
    savedQuestions: SavedQuestion[],
    cmsID: string,
    token: string
  ): Promise<ImageAnswerValue[]> => {
    const fileAnsweres = savedQuestions.filter((res) => res.type === "file");
    const imageListToUpload: ImageAnswerValue[] = fileAnsweres.flatMap(
      (res) => {
        return res.value.map((valueRes: any) => ({
          ...valueRes,
          metaId: res.metaId,
        }));
      }
    );

    const dynamicPanelAnswers = savedQuestions.filter(
      (res) => res.type === "paneldynamic" && res.metaId
    );
    const imageListToUploadFromDynamic: ImageAnswerValue[] =
      dynamicPanelAnswers.flatMap((r) => {
        return r.value.flatMap((item: any) => {
          const image = get(item, "image", []);
          if (image.length > 0) {
            return [{ ...image[0], metaId: r.metaId }];
          }
          return [];
        });
      });

    const allUploaded = [...imageListToUpload, ...imageListToUploadFromDynamic];

    const allPromises: Promise<UploadCmsImageResponse>[] = allUploaded.map(
      (imageInfo) => {
        return ProjectUtils.uploadBase64ImageToAzure(
          token,
          imageInfo.content.replace("data:", "").replace(/^.+,/, ""),
          cmsID,
          imageInfo.type
        );
      }
    );

    const allResponse = await Promise.all(allPromises);
    allResponse.forEach((res, idx) => {
      allUploaded[idx].src = res.imageUrl;
    });
    return allUploaded.map((res) => ({
      ...pick(res, ["src", "metaId", "name", "type"]),
      content: "",
    }));
  };

  static convertBriefHoursToCMS = (value: any) => {
    const openHours = get(value, "open_hour", {});
    const closeHours = get(value, "close_hour", {});
    return DAYS_OF_THE_WEEK.map((day) => {
      const dayOpen = get(openHours, day.toLowerCase(), "");
      const dayClose = get(closeHours, day.toLowerCase(), "");
      if (dayOpen !== "" || dayClose !== "") {
        return {
          name: day,
          openHour: dayOpen,
          closeHour: dayClose,
          closeOnDay: false,
        };
      } else {
        return { name: day, openHour: "", closeHour: "", closeOnDay: true };
      }
    });
  };

  static extractFromBriefDynamicArray = (
    valuesArray: any,
    keyValue: string
  ) => {
    return valuesArray.map((val: any) => {
      const value = get(val, keyValue, "");
      return value;
    });
  };

  static extractServiceListFromBriefData = (valueArray: any) => {
    //
    return valueArray.map((item: any) => {
      const title = get(item, "title", "");
      const subTitle = get(item, "subtitle", "");
      const paragraph = get(item, "paragraph", "");
      const name = get(item, "name", "");
      const image = get(item, "image", []);
      let src = "";
      if (image.length > 0) {
        src = image[0].content;
      }
      return { title, subTitle, paragraph, name, image: src };
    });
  };

  static checkIfItemExistsOrEmpty = (value: any) => {
    //
    if (value) {
      if (typeof value == "string") {
        const text = TreeUtils.htmlToText(value);
        return text === "";
      } else {
        return false;
      }
    } else return true;
  };

  static addToCmsDicIfNotExits = (
    dic: { [id: string]: any },
    key: string,
    value: any,
    overwrite = true,
    addTo = false
  ) => {
    if (overwrite || ProjectUtils.checkIfItemExistsOrEmpty(get(dic, key))) {
      if (addTo && get(dic, key)) {
        dic[key] = [...dic[key], ...value];
      } else {
        dic[key] = value;
      }
    }
  };

  static getUserNameFromAccount = (
    account: AccountInfo | null,
    userInfo?: UserInfo,
    defaultStr = "Unknown"
  ) => {
    if (userInfo && userInfo.displayName) {
      return userInfo.displayName;
    }

    if (account) {
      if (account.idTokenClaims) {
        const firstName = get(account.idTokenClaims, "given_name", defaultStr);
        const familytName = get(account.idTokenClaims, "family_name", "");
        return `${firstName} ${familytName}`.trim();
      } else {
        return account.name ? account.name : defaultStr;
      }
    } else {
      return defaultStr;
    }
  };

  // static getUserName = (accountName: string, userInfo?: UserInfo) => {
  //   const { getDesignerName } = useDesginerName();
  //   return getDesignerName();
  //   // if (userInfo && userInfo.displayName) {
  //   //   return userInfo.displayName;
  //   // } else return accountName;
  // };

  static checkIfAiAlreadyFillAll = async (
    pages: Page[],
    cmsDataId: string,
    token: string,
    cmsDic: { [id: string]: any },
    cmsValuesByTypes?: CmsValuesByTypes
  ) => {
    const alreadyExistsKeys = cmsValuesByTypes
      ? _.keys(_.get(cmsValuesByTypes, "ai", {}))
      : [];
    // const cmsDic: { [id: string]: any } = cmsState.cmsItems //TreeUtils.extractDicFromCms(pages);
    const category = get(
      get(cmsDic, BUSINESS_CATEGORY_CMS_ID, ""),
      "value",
      ""
    );
    let subCategory = get(
      get(cmsDic, BUSINESS_SUB_CATEGORY_CMS_ID, ""),
      "value",
      ""
    );
    if (category.toLowerCase() === "other") {
      subCategory = get(get(cmsDic, BUSINESS_CATEGORY_CMS_ID, ""), "other", "");
    } else if (subCategory.toLowerCase() === "other") {
      subCategory = get(
        get(cmsDic, BUSINESS_SUB_CATEGORY_CMS_ID, {}),
        "other",
        ""
      );
    }
    const businessName = get(cmsDic, BUSINESS_NAME_CMS_ID, "");
    const foundedYear = get(cmsDic, BUSINESS_FOUNDED_CMS_ID, "");
    const searchTokens = TreeUtils.htmlToText(get(cmsDic, KEYWORDS_CMS_ID, ""));
    const services: ListValues[] = get(
      cmsDic,
      SERVICE_CMS_ID,
      []
    ) as ListValues[];
    const usedAnswers: { [id: string]: any } = {
      SUB_CATEGORY_NAME: subCategory,
      COMPANY_NAME: businessName,
      ESTABLISH_AT: foundedYear,
      CATEGORY_NAME: category,
      SEO_KEYWORDS: searchTokens,
    };
    const aiItemList = await adminApi.getAllAiItems(token);
    const aiItemsForCMS = aiItemList.items.filter(
      (item) =>
        item.cmsIds.length > 0 &&
        item.cmsIds[0] !== "" &&
        item.cmsIds.indexOf("732") === -1 &&
        item.tokens.length > 0 &&
        alreadyExistsKeys.indexOf(item.cmsIds[0]) === -1
    );

    return aiItemsForCMS
      .flatMap((aiItem) => {
        if (aiItem.tokens.indexOf("SERVICE_NAME") >= 0) {
          const values = services
            .filter((r) => r.title !== "")
            .map((r) => r.title);
          return values.map((a: any) => {
            const r: { [id: string]: string } = {};
            r["SERVICE_NAME"] = a;
            return {
              id: aiItem.id,
              data: { ...usedAnswers, ...r },
              cmsIds: aiItem.cmsIds,
            };
          });
        } else
          return { id: aiItem.id, data: usedAnswers, cmsIds: aiItem.cmsIds };
      })
      .filter((f) => _.keys(f.data).length > 0);
  };

  static getAiResponseWithRetries = async (
    aiRequest: ProcessAiRequest,
    projectId: string,
    token: string
  ) => {
    let aiResponse = undefined;
    let retries = 0;
    while (!aiResponse && retries < 2) {
      aiResponse = await adminApi.processAiTemaplate(
        projectId,
        aiRequest,
        token
      );
      retries = retries + 1;
    }
    return aiResponse;
  };

  static featchAiItems = async (
    aiRequesrList: ProcessAiRequest[],
    projectId: string,
    token: string
  ) => {
    const cmsAiItems: { [id: string]: string[] } = {};
    const chunks = [];
    const results = [];
    const chunkSize = 6;

    // Split the array of URLs into chunks
    for (let i = 0; i < aiRequesrList.length; i += chunkSize) {
      chunks.push(aiRequesrList.slice(i, i + chunkSize));
    }

    for (const chunk of chunks) {
      const requests = chunk.map((aiRequest) =>
        adminApi.processAiTemaplate(projectId, aiRequest, token)
      );
      const chunkResults = await Promise.all(requests);
      results.push(...chunkResults);
    }

    const failedRequests: ProcessAiRequest[] = [];
    results.map((value: string[] | undefined, idx: number) => {
      if (value && value.length > 0) {
        const cmsIds = aiRequesrList[idx].cmsIds;
        cmsIds.forEach((id: string) => {
          if (get(cmsAiItems, id)) {
            cmsAiItems[id] = [...cmsAiItems[id], value.join(" ").trim()];
          } else {
            cmsAiItems[id] = [value.join(" ").trim()];
          }
        });
      } else if (value === undefined) {
        failedRequests.push(aiRequesrList[idx]);
      }
    });
    return { cmsAiItems, failedRequests };
  };

  static runAiOnAllItems = async (
    projectId: string,
    pages: Page[],
    cmsDataId: string,
    token: string,
    aiRequesrList: ProcessAiRequest[],
    selectedSeoKeyList: string,
    _cmsDic: { [id: string]: any },
    cmsValuesByTypes?: CmsValuesByTypes
  ) => {
    const cmsDic: { [id: string]: any } = _.cloneDeep(_cmsDic);
    // const cmsDic: { [id: string]: any } = TreeUtils.extractDicFromCms(pages);
    let cmsAiItems: { [id: string]: string[] } = {};
    let faildCount = 0;

    const fetchAiItemsResult = await ProjectUtils.featchAiItems(
      aiRequesrList,
      projectId,
      token
    );
    cmsAiItems = { ...cmsAiItems, ...fetchAiItemsResult.cmsAiItems };
    if (fetchAiItemsResult.failedRequests.length > 0) {
      const failedRequests = fetchAiItemsResult.failedRequests;
      const failedRequestsResult = await ProjectUtils.featchAiItems(
        failedRequests,
        projectId,
        token
      );
      cmsAiItems = { ...cmsAiItems, ...failedRequestsResult.cmsAiItems };
      faildCount = failedRequestsResult.failedRequests.length;
    }

    if (selectedSeoKeyList !== "") {
      cmsDic[KEYWORDS_CMS_ID] = selectedSeoKeyList;
    }

    const updatesCmsValuesByTypes = {
      brief: get(cmsValuesByTypes, "brief", {}),
      ai: { ...get(cmsValuesByTypes, "ai", {}), ...cmsAiItems },
      expert: get(cmsValuesByTypes, "expert", {}),
      experts: get(cmsValuesByTypes, "experts", {}),
      nonCMSList: get(cmsValuesByTypes, "nonCMSList", []),
      social: get(cmsValuesByTypes, "social", []),
      config: get(cmsValuesByTypes, "config", {}),
      website: get(cmsValuesByTypes, "website", {}),
      markedChanges: get(cmsValuesByTypes, "markedChanges", []),
    };

    await cmsApi.updateCmsAiData(
      JSON.stringify(updatesCmsValuesByTypes),
      cmsDataId,
      token
    );    

    const cmsTypes = TreeUtils.extractTypesDicFromCms(pages);

    const cmsAiItemsKeys = _.keys(cmsAiItems);
    cmsAiItemsKeys.forEach((aiCmsId) => {
      const compType = cmsTypes[aiCmsId];
      if (compType === "serviceList") {
        const cmsDicData = cmsDic[aiCmsId];
        if (cmsDicData) {
          const updateData = cmsDicData.map((data: any, idx: number) => {
            if (idx < cmsAiItems[aiCmsId].length) {
              return { ...data, paragraph: cmsAiItems[aiCmsId][idx] };
            } else return { ...data };
          });
          ProjectUtils.addToCmsDicIfNotExits(
            cmsDic,
            aiCmsId,
            updateData,
            true,
            false
          );
        }
      } else {
        if (cmsAiItems[aiCmsId].length === 1) {
          ProjectUtils.addToCmsDicIfNotExits(
            cmsDic,
            aiCmsId,
            cmsAiItems[aiCmsId][0],
            false,
            false
          );
        }
      }
    });

    await cmsApi.updateCmsData(JSON.stringify(cmsDic), cmsDataId, token);

    return { cms: cmsDic, types: updatesCmsValuesByTypes, faildCount };
  };

  static insertCMSFormToCMS = async (
    formResults: { [id: string]: any },
    project: Project,
    cmsDicData: CMSResponse,
    token: string
  ) => {
    //
    const formKeys = _.keys(formResults);
    const cmsDic = JSON.parse(cmsDicData.content);

    const designerDataDic: { [id: string]: any } = {};
    formKeys.forEach((key) => {
      const value = get(cmsDic, key);
      if (value) {
        designerDataDic[key] = value;
      }
    });

    const currentAiContent = JSON.parse(cmsDicData.aiContent);
    const data: CmsValuesByTypes = {
      brief: { ...get(currentAiContent, "brief", {}), ...formResults },
      ai: { ...get(currentAiContent, "ai", {}) },
      expert: { ...get(currentAiContent, "expert", {}), ...designerDataDic },
      experts: {},
      nonCMSList: [...get(currentAiContent, "nonCMSList", [])],
      social: { ...get(currentAiContent, "social", {}) },
      config: { ...get(currentAiContent, "config", {}) },
      website: get(currentAiContent, "website", {}),
      markedChanges: _.uniq([...get(currentAiContent, "markedChanges", []), ..._.keys(formResults)]),
    };
    await cmsApi.updateCmsAiData(
      JSON.stringify(data),
      project.cmsDataId ?? "",
      token
    );

    await cmsApi.updateCmsData(
      JSON.stringify({ ...cmsDic, ...formResults }),
      project.cmsDataId ?? "",
      token
    );
  };

  static convertSurevyToCMSData = async (
    surveyResults: SavedQuestion[],
    project: Project,
    cmsDicData: CMSResponse,
    token: string
  ) => {
    if (!project.cmsDataId) return;

    const cmsAiItems: { [id: string]: string[] } = {};

    const cmsDic = JSON.parse(cmsDicData.content);
    const cmsTypes = TreeUtils.extractTypesDicFromCms(
      JSON.parse(cmsDicData.schema).pages
    );

    const nonCMSList: SavedQuestion[] = [];

    const dicFromSurvey: { [id: string]: any } = {};
    surveyResults.forEach((res) => {
      if (res.items.length > 0) {
        let metaFound = false;
        res.items.forEach((item) => {
          if (item.metaId) {
            metaFound = true;
            dicFromSurvey[item.metaId] = item.value;
            ProjectUtils.addToCmsDicIfNotExits(cmsDic, item.metaId, item.value);
          }
        });
        if (!metaFound) {
          nonCMSList.push(res);
        }
      } else {
        if (res.metaId) {
          if (res.type === "file") {
            const uploadedImagesList = res.value.map((imageData: any) => ({
              ...imageData,
              src: imageData.content,
              title:
                imageData.name.indexOf(".") > -1
                  ? imageData.name.substring(0, imageData.name.indexOf("."))
                  : imageData.name,
            }));
            dicFromSurvey[res.metaId] = uploadedImagesList;
            const compType = cmsTypes[res.metaId];
            if (compType === "media") {
              ProjectUtils.addToCmsDicIfNotExits(
                cmsDic,
                res.metaId,
                uploadedImagesList[0].src
              );
            } else {
              ProjectUtils.addToCmsDicIfNotExits(
                cmsDic,
                res.metaId,
                uploadedImagesList,
                true,
                true
              );
            }
          } else if (res.metaId && res.ai && res.type === "tagbox") {
            const otherOptionsUsed =
              res.commentValue && res.commentValue !== "";
            let options = res.value;
            if (otherOptionsUsed && res.commentValue) {
              options = [...options, ...res.commentValue.split(",")].filter(
                (r) => r.toLowerCase() !== "other"
              );
            }
            const values = options.map((value: any) => ({
              title: value,
            }));
            dicFromSurvey[res.metaId] = values;
            ProjectUtils.addToCmsDicIfNotExits(cmsDic, res.metaId, values);
          } else if (res.type === "dropdown") {
            const otherUsed = res.commentValue && res.commentValue !== "";
            const newVal = {
              value: otherUsed ? "Other" : res.value,
              other: otherUsed ? res.commentValue : "",
            };
            dicFromSurvey[res.metaId] = newVal;
            ProjectUtils.addToCmsDicIfNotExits(cmsDic, res.metaId, newVal);
          } else if (res.type === "matrixdropdown") {
            ProjectUtils.addToCmsDicIfNotExits(
              cmsDic,
              res.metaId,
              ProjectUtils.convertBriefHoursToCMS(res.value)
            );
          } else if (res.type === "paneldynamic") {
            //
            const compType = cmsTypes[res.metaId];
            if (compType === "videos") {
              ProjectUtils.addToCmsDicIfNotExits(
                cmsDic,
                res.metaId,
                ProjectUtils.extractFromBriefDynamicArray(
                  res.value,
                  "added_video"
                )
              );
            } else if (compType === "serviceList") {
              const otherServicesUsed =
                res.commentValue && res.commentValue !== "";
              let services = res.value;
              if (otherServicesUsed && res.commentValue) {
                services = [...services, ...res.commentValue.split(",")].filter(
                  (r) => r.toLowerCase() !== "other"
                );
              }
              ProjectUtils.addToCmsDicIfNotExits(
                cmsDic,
                res.metaId,
                ProjectUtils.extractServiceListFromBriefData(services),
                true,
                res.metaId === "167"
              );
            }
          } else {
            dicFromSurvey[res.metaId] = res.value;
            ProjectUtils.addToCmsDicIfNotExits(cmsDic, res.metaId, res.value);
          }
        } else {
          if (res.type === "file") {
            res.value = res.value.map((valueRes: any) => ({
              ...valueRes,
              src: valueRes.content,
              title:
                valueRes.name.indexOf(".") > -1
                  ? valueRes.name.substring(0, valueRes.name.indexOf("."))
                  : valueRes.name,
            }));
            nonCMSList.push(res);
          } else {
            nonCMSList.push(res);
          }
        }
      }
    });

    const currentAiContent = JSON.parse(cmsDicData.aiContent);
    const data = {
      brief: { ...get(currentAiContent, "brief", {}), ...dicFromSurvey },
      ai: { ...get(currentAiContent, "ai", {}), ...cmsAiItems },
      expert: JSON.parse(cmsDicData.content),
      experts: {},
      nonCMSList: [...get(currentAiContent, "nonCMSList", []), ...nonCMSList],
      social: { ...get(currentAiContent, "social", {}) },
      config: { ...get(currentAiContent, "config", {}) },
      website: get(currentAiContent, "website", {}),
      markedChanges: get(currentAiContent, "markedChanges", []),
    };
    await cmsApi.updateCmsAiData(
      JSON.stringify(data),
      project.cmsDataId,
      token
    );

    await cmsApi.updateCmsData(
      JSON.stringify(cmsDic),
      project.cmsDataId,
      token
    );
  };

  static fillCMSFromProject = async (
    projectDic: { [id: string]: any },
    cmsId: string,
    token: string
  ) => {
    const cmsData = await cmsApi.getCmsDataFromServer(cmsId, token);
    const cmsDic: { [id: string]: any } = JSON.parse(cmsData.content);
    _.keys(projectDic).map((key) => {
      cmsDic[key] = projectDic[key];
    });
    await cmsApi.updateCmsData(JSON.stringify(cmsDic), cmsId, token);
  };

  static getMetaIdFromElements = (elements: any, metaIdList: string[]) => {
    map((element: any) => {
      const elementsInElement = get(element, "elements", []);
      if (elementsInElement.length > 0) {
        ProjectUtils.getMetaIdFromElements(elementsInElement, metaIdList);
      } else {
        const metaId = get(element, "metaId");
        if (metaId) {
          metaIdList.push(metaId);
        } else {
          const items = get(element, "items", []);
          map((item: any) => {
            const metaId = get(item, "metaId");
            if (metaId) {
              metaIdList.push(metaId);
            }
          }, items);
        }
      }
    }, elements);
  };

  static getPanelsAndCmsConnectedDataFromBrief = (briefJson: any) => {
    const pages = get(briefJson, "pages", []);
    const metaIdList: string[] = [];
    map((page: any) => {
      const elements = get(page, "elements", []);
      if (elements.length > 0) {
        ProjectUtils.getMetaIdFromElements(elements, metaIdList);
      }
    }, pages);
    return metaIdList;
  };

  static getQuestionsCountFromList = (elements: any) => {
    let count = 0;
    map((element: any) => {
      const elementsInElement = get(element, "elements", []);
      if (elementsInElement.length > 0) {
        count =
          count + ProjectUtils.getQuestionsCountFromList(elementsInElement);
      } else {
        count += 1;
      }
    }, elements);
    return count;
  };

  static getQuestionsCountFromBrief = (briefJson: any) => {
    let count = 0;
    const pages = get(briefJson, "pages", []);
    map((page: any) => {
      const elements = get(page, "elements", []);
      if (elements.length > 0) {
        count = count + ProjectUtils.getQuestionsCountFromList(elements);
      }
    }, pages);
    return count;
  };

  static getAllProjectBriefsSchema = async (
    project: Project,
    token: string
  ) => {
    const projectTemplates = await projectsApi.getProjectBriefs(
      project.id,
      token
    );
    const allMetaDataId = projectTemplates.briefs.flatMap((res) => {
      return ProjectUtils.getPanelsAndCmsConnectedDataFromBrief(
        JSON.parse(res.template)
      );
    });
    return allMetaDataId;
  };

  static removeDuplicatesFromElements = (
    elements: any,
    duplicateList: string[]
  ) => {
    return flatMap((element: any) => {
      const elementElements = get(element, "elements", []);
      if (elementElements.length > 0) {
        element.elements = ProjectUtils.removeDuplicatesFromElements(
          elementElements,
          duplicateList
        );
        if (element.elements.length > 0) {
          return [element];
        } else {
          return [];
        }
      } else {
        const metaId = get(element, "metaId");
        if (metaId && duplicateList.indexOf(metaId) >= 0) {
          return [];
        } else {
          const items = get(element, "items", []);
          if (items.length > 0) {
            element.items = flatMap((item: any) => {
              const metaId = get(item, "metaId");
              if (metaId && duplicateList.indexOf(metaId) >= 0) {
                return [];
              } else {
                return [item];
              }
            }, items);
            if (element.items.length === 0) {
              return [];
            } else {
              return [element];
            }
          } else {
            return [element];
          }
        }
      }
    }, elements);
  };

  static removeDuplicateQuestionFromBrief = (
    duplicateList: string[],
    briefJson: any
  ) => {
    const pages = get(briefJson, "pages", []);
    const newSchema = flatMap((page: any) => {
      page.elements = ProjectUtils.removeDuplicatesFromElements(
        get(page, "elements", []),
        duplicateList
      );
      if (page.elements.length > 0) {
        return [page];
      } else {
        return [];
      }
    }, pages);
    return JSON.stringify({ pages: newSchema });
  };

  static verifyBriefNotContainingUsedQuestions = async (
    project: Project,
    newBriefId: string,
    token: string
  ): Promise<DupicateCheckResults | undefined> => {
    if (project.briefs.length === 0) {
      return undefined;
    }
    const briefData = await projectsApi.getTemplateQuestionerById(
      newBriefId,
      token
    );

    const briefJson = JSON.parse(briefData.schema ?? "{}");

    const currentBriefMetaIds =
      ProjectUtils.getPanelsAndCmsConnectedDataFromBrief(briefJson);
    const existingBriefsMetaIds = await ProjectUtils.getAllProjectBriefsSchema(
      project,
      token
    );

    const duplicatedQuestions = intersection(
      currentBriefMetaIds,
      existingBriefsMetaIds
    );

    if (duplicatedQuestions.length > 0 && briefData.schema) {
      return {
        schema: ProjectUtils.removeDuplicateQuestionFromBrief(
          duplicatedQuestions,
          briefJson
        ),
        duplicateIds: duplicatedQuestions,
        templateId: newBriefId,
        templateName: `${briefData.name} (modified)`,
        numOfQuestions: ProjectUtils.getQuestionsCountFromBrief(briefJson),
      };
    } else return undefined;
  };

  static silentSaveContentHubData = async (
    token: string,
    cmsData: CMSState
  ) => {
    const cmsDic = cmsData.cmsItems; // TreeUtils.extractDicFromCms(cmsData.pages);
    if (cmsData.cmsValuesByTypes) {
      await cmsApi.updateCmsAiData(
        JSON.stringify(cmsData.cmsValuesByTypes),
        cmsData.metaDataId,
        token
      );
    }
    await cmsApi.updateCmsData(
      JSON.stringify(cmsDic),
      cmsData.metaDataId,
      token
    );
  };

  static getEditorAccountUpdate = (
    accountInfo: LynxiAccountInfo,
    page: Page,
    lastAddedMetaId: number
  ) => {
    const accountSettings = accountInfo.additionalData
      ? JSON.parse(accountInfo.additionalData)
      : {};
    // const newSections: Section[] = page.sections.filter(
    //   (section) =>
    //     section.type === "new" &&
    //     section.singles.filter((s) => s.isGlobal).length > 0
    // );
    // const newSectionUpdates = newSections.map((section) => {
    //   const newSingles = section.singles.filter((s) => s.isNew && s.isGlobal);
    //   return { ...section, singles: newSingles };
    // });
    // const existingSections: Section[] = page.sections
    //   .filter((s) => s.type !== "new")
    //   .flatMap((section) => {
    //     const singles = section.singles.filter(
    //       (single) => single.isNew && single.isGlobal
    //     );
    //     if (singles.length > 0) {
    //       return [{ ...section, singles: singles }];
    //     } else {
    //       return [];
    //     }
    //   });
    return {
      ...accountSettings,
      editor: {
        // existingSections: existingSections,
        // addedSections: newSectionUpdates,
        lastAddedMetaId: lastAddedMetaId,
      },
    };
  };

  static getEditorUpdate = (cmsState: CMSState, page: Page) => {
    const newSections: Section[] = page.sections.filter(
      (section) =>
        section.type === "new" &&
        section.singles.filter((s) => !s.isGlobal).length > 0
    );
    const newSectionUpdates = newSections.map((section) => {
      const newSingles = section.singles.filter((s) => s.isNew && !s.isGlobal);
      return { ...section, singles: newSingles };
    });

    const existingSections: Section[] = page.sections
      .filter((s) => s.type !== "new")
      .flatMap((section) => {
        const singles = section.singles.filter(
          (single) => single.isNew && !single.isGlobal
        );
        if (singles.length > 0) {
          return [{ ...section, singles: singles }];
        } else {
          return [];
        }
      });
    if (existingSections.length === 0 && newSectionUpdates.length === 0) {
      return undefined;
    }
    if (cmsState.cmsValuesByTypes) {
      return {
        ...cmsState.cmsValuesByTypes,
        editor: {
          existingSections: existingSections,
          addedSections: newSectionUpdates,
        },
      };
    } else {
      return {
        brief: {},
        ai: {},
        expert: {},
        experts: {},
        nonCMSList: [],
        social: {},
        editor: {
          existingSections: existingSections,
          addedSections: newSections,
        },
      };
    }
  };

  static uploadImageToAzure = async (
    file: File,
    token: string,
    cmsId: string,
    accountId: string
  ) => {
    const fileType = file.type;
    const fileExt = fileType.split("/").pop();
    if (cmsId !== "") {
      const fileToUploadUrl = await uploadApi.getUploadCMSUrl(
        cmsId,
        fileExt ? fileExt : "png",
        token
      );
      await uploadApi.uploadImageToAzure(
        fileToUploadUrl.uploadLink,
        file,
        fileType
      );
      return Promise.resolve({ ...fileToUploadUrl, file: file });
    } else {
      const fileToUploadUserUrlResponse =
        await uploadApi.getUploadUrlToUserAccount(
          fileExt ? fileExt : "png",
          accountId,
          token
        );
      await uploadApi.uploadImageToAzure(
        fileToUploadUserUrlResponse.uploadLink,
        file,
        fileType
      );
      return Promise.resolve({
        file: file,
        uploadLink: fileToUploadUserUrlResponse.uploadLink,
        imageUrl: fileToUploadUserUrlResponse.imageUrl,
      });
    }
  };

  static uploadDocumentToAzure = async (
    file: File,
    projectId: string,
    token: string
  ) => {
    const fileType = file.type;
    const fileExt = fileType.split("/").pop();
    const fileToUploadUrl =
      await uploadApi.getUploadUrlToUserProjectDocumentsAccount(
        projectId,
        fileExt ? fileExt : "png",
        token
      );
    await uploadApi.uploadImageToAzure(
      fileToUploadUrl.uploadLink,
      file,
      fileType
    );
    return Promise.resolve({ ...fileToUploadUrl, file: file });
  };

  static uploadDocumentToAzureByEmail = async (
    file: File,
    projectId: string,
    email: string
  ) => {
    const fileType = file.type;
    const fileExt = fileType.split("/").pop();
    const fileToUploadUrl =
      await uploadApi.getUploadUrlToUserProjectDocumentsAccountByEmail(
        projectId,
        fileExt ? fileExt : "png",
        email
      );
    await uploadApi.uploadImageToAzure(
      fileToUploadUrl.uploadLink,
      file,
      fileType
    );
    return Promise.resolve({ ...fileToUploadUrl, file: file });
  };

  static uploadSharedFileToAzureStorage = async (
    file: File,
    token: string,
    projectId: string
  ): Promise<UploadDocumentResponse> => {
    const fileType = file.type;
    const fileExt = fileType.split("/").pop();
    const fileToUploadUrl = await uploadApi.getuploadSharedFileToAzureStorage(
      projectId,
      fileExt ? fileExt : "png",
      token
    );
    if (fileToUploadUrl) {
      await uploadApi.uploadImageToAzure(
        fileToUploadUrl.uploadLink,
        file,
        fileType
      );
      return Promise.resolve({ ...fileToUploadUrl, file: file });
    }
    return Promise.resolve({ uploadLink: "", url: "", file: file });
  };

  static uploadBriefFilesForMetaSiteId = async (
    files: File[],
    projectId: string,
    token: string
  ) => {
    const filesWithType = Array.prototype.slice
      .call(files)
      .filter((file) => file.type);

    const allPromises: Promise<UploadDocumentResponse>[] = filesWithType.map(
      (file: File) => {
        return ProjectUtils.uploadDocumentToAzure(file, token, projectId);
      }
    );

    const allResponse = await Promise.all(allPromises);
    return allResponse.map((res) => {
      return {
        file: res.file,
        content: res.url,
      };
    });
  };

  static GetFakePosition = (index: number) => {
    return PositionIndexBuilder(index, index, index, index, index);
  };
}
