import axios from "axios";
import { CMSApprovalList, Page } from "../cms/cmsSlice";
import ApiUtils from "./ApiUtils";
import { GetShareLinkResponse } from "./notificationApi";

export interface SiteInfo {
  jsonVersion: number;
  metadataId: string;
  origDomain: string;
  pages: Page[];
  projectName?: string;
  siteName: string;
  templateId: number;
}

export interface UserSiteImage {
  description: string;
  imageId: string;
  url: string;
}

export interface CMSResponse {
  content: string;
  aiContent: string;
  schema: string;
  designerName?: string;
  approvals: string;
  isPublished: boolean;
  lastPublished?: string;
  editorSettings?: string;
}

export interface CMSContentReply {
  contents: string[];
}

export interface CommentData {
  createdAt: string;
  viewedAt?: string;
  createdBy: string;
  source: string;
  name: string;
  comment: string;
  countIndex?: string;
}

export interface CommentListReponse {
  comments: CommentData[];
}

export interface NewCommentData {
  name: string;
  comments: string;
}

export interface ShareCMSInfo {
  emails: string[];
  message: string;
  subject: string;
  isAnonymous?: boolean;
}

export interface ShareCmsInfo {
  sharedAt: string;
  publishedAt: string;
  email: string;
  state: string;
  unreadComments: number;
}

export interface ShareInfoResponse {
  data: ShareCmsInfo[];
}

class CmsApi {
  getCmsDataFromServer = async (
    cmsDataId: string,
    token: string
  ): Promise<CMSResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/briefs/api/Cms/${cmsDataId}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  updateCmsData = async (
    cmsData: string,
    cmsDataId: string,
    token: string
  ): Promise<CMSResponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/Cms/`,
      {
        id: cmsDataId,
        content: cmsData,
      },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  publishCmsData = async (
    cmsDataId: string,
    emails: string[],
    token: string
  ): Promise<CMSResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/Cms/publish`,
      {
        id: cmsDataId,
        message:
          "We updated the content. Please review and approve to move the process forward.",
        subject: "Your content was updated",
        emails: emails,
      },
      ApiUtils.Post(token)
    );
    return response.data;
  };

  updateCmsDataStatus = async (
    status: string,
    cmsDataId: string,
    token: string
  ): Promise<CMSResponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/Cms/${cmsDataId}/status?value=${status}`,
      {},
      ApiUtils.Put(token)
    );
    return response.data;
  };

  updateCmsAiData = async (
    aiData: string,
    cmsDataId: string,
    token: string
  ): Promise<CMSResponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsDataId}/ai_content`,
      {
        id: cmsDataId,
        aiContent: aiData,
      },
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getCMSImages = async (
    cmsId: string,
    token: string
  ): Promise<UserSiteImage[]> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/images/cms/${cmsId}/images`,
      ApiUtils.Get(token)
    );
    if (response.status === 200) {
      const res: string[] = response.data;
      return res.map((src, idx) => ({
        url: src,
        imageId: `${idx}`,
        description: "",
      }));
    }
    return [];
  };

  updateCMSComments = async (
    comments: any,
    id: string,
    token: string
  ): Promise<boolean> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/cms/${id}/comments`,
      comments,
      ApiUtils.Put(token)
    );
    return response.data;
  };

  getCMSContentByCategory = async (
    cmsKey: string,
    category: string,
    subCategory: string,
    token: string
  ): Promise<CMSContentReply> => {
    const params = {
      cmsId: cmsKey,
      category: category.trim(),
      subcategory: subCategory.trim(),
    };
    const response = await axios.get(
      `${ApiUtils.serverDomain}/backoffice/api/cms`,
      ApiUtils.GetWithParams(token, params)
    );
    return response.data;
  };

  getCmsComments = async (
    cmsId: string,
    token: string
  ): Promise<CommentListReponse> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/comments`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return { comments: [] };
      }
    } catch {
      return { comments: [] };
    }
  };

  addCmsComments = async (
    cmsId: string,
    data: NewCommentData,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/comments`,
      { comments: [data] },
      ApiUtils.Post(token)
    );
    return response.data;
  };

  updateCmsComments = async (
    cmsId: string,
    name: string,
    token: string
  ): Promise<CommentListReponse> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/comments`,
      { names: [name] },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  shareCMS = async (
    cmsId: string,
    shareData: ShareCMSInfo,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/share/by_email`,
        shareData,
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  getShareInfo = async (
    cmsId: string,
    token: string
  ): Promise<ShareInfoResponse> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/share/info`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return { data: [] };
      }
    } catch {
      return { data: [] };
    }
  };

  sendCMSEmailReminder = async (
    cmsId: string,
    email: string,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/reminder/approve`,
        email,
        ApiUtils.Post(token)
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  updateApprovalFields = async (
    cmsId: string,
    approvalList: CMSApprovalList,
    token: string
  ): Promise<string> => {
    const response = await axios.put(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/cms/approve_fields`,
      { json: JSON.stringify(approvalList) },
      ApiUtils.Put(token)
    );
    return response.data;
  };

  ApprovesCMS = async (
    cmsId: string,
    state: number,
    token: string
  ): Promise<string> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/cms/approved`,
      { state: state },
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getShareLink = async (
    cmsId: string,
    email: string,
    isAnonymous: boolean,
    token: string
  ): Promise<GetShareLinkResponse> => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/briefs/api/cms/${cmsId}/share/by_link`,
      { email: email, isAnonymous },
      ApiUtils.Post(token)
    );
    return response.data;
  };
}

export const cmsApi = new CmsApi();
