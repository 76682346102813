import { Badge, Box, Divider, Grid, Tooltip,Typography } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { ReactComponent as CommentSVG } from "../../assets/project/purpleComment.svg";
import { ReactComponent as CommentAddSvg } from "../../assets/storage/commentAdd.svg";
import { ReactComponent as BulletsIconSVG } from "../../assets/project/bullets.svg";
import { ReactComponent as SmallEditSvg } from "../../assets/project/smallEdit.svg";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";
import { ReactComponent as InviteSvg } from "../../assets/storage/invitationDashboard.svg";


import { TaskInfo, TaskPriority, TaskStatus } from "../../api/tasksApi";
import { DesignerContext } from "../../routes/AppRouter";
import CommentsUtils from "../../Comments/CommentsUtils";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { getPrettyDate } from "../../utils/DateUtils";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import BasicStaticDropDown from "../../basic/BasicStaticDropDown";
import { StatusDropDown } from "../../basic/StatusDropDown";
import parse from "html-react-parser";
import { useAccountSettingsData } from "../../hooks/useAccountTasksTemplates";


export interface TaskLineProps {
  task: TaskInfo;
  selected: boolean;
  onDeleteTask(task: TaskInfo, anchor: HTMLDivElement): void;
  onCommentClick(task: TaskInfo, anchor: HTMLDivElement): void;
  onTaskToEdit(task: TaskInfo): void;
  onUpdteTask(task: TaskInfo): void;
  onSendReminder(task: TaskInfo): void;
  isClient?: boolean;
}

export const TaskLine: FC<TaskLineProps> = ({
  task,
  selected,
  onDeleteTask,
  onCommentClick,
  onTaskToEdit,
  onUpdteTask,
  onSendReminder,
  isClient,
}) => {
  const designerContext = React.useContext(DesignerContext);
  const [onProcessing, setOnProcessing] = React.useState(false);
  const {getAccountDateFormat} = useAccountSettingsData()
  

  const onStatusChange = (value: string) => {
    onUpdteTask({ ...task, status: value });
  };

  const onPriorityChange = (value: number) => {
    onUpdteTask({ ...task, priority: TaskPriority[value] });
  };

  const renderComments = () => {
    const newCount = CommentsUtils.checkForNewItemsCount(
      task.comments ?? [],
      isClient
    );
    return (
      <HelpToolTipOnHover
        position="top"
        message={
          (task.comments ?? []).length === 0 ? "Add comment" : "View comments"
        }
        actionNode={
          newCount > 0 ? (
            <Badge badgeContent={newCount} color="error">
              <CommentSVG />
            </Badge>
          ) : (task.comments ?? []).length > 0 ? (
            <CommentSVG />
          ) : (
            <CommentAddSvg />
          )
        }
      />
    );
  };

  return (
    <Box
      className="briefline"
      sx={{
        flexGrow: 1,
        display: "flex",
        background: selected ? "#f5effb" : "#f9f9f9",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
        height: "70px",
        margin: "10px 0px",
        fontSize: "14px",
      }}
    >
      <Grid container spacing={0} alignItems="center" height="100%">
        <Grid
          item
          xs={4}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%" }}
        >          
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              fontWeight: "500",
              height: "100%",
              justifyContent: "flex-start",
              padding: "0 5px 0 10px",
              alignItems: "center",
              overflow: "auto",
              wordBreak: "break-all",
              width: "100%"
            }}
            className={"compDescription"}
          >
            <div style={{height:'auto',maxHeight:'70px', display:'flex', flexDirection:'column', justifyContent:'middle'}}>{parse(task.name)}</div>
          </Box>
        </Grid>
        <Grid item xs={1.7}>
          <Box sx={{ textAlign: "center" }}>
            {task.assignedTo === "" ? (
              <Box
                sx={{
                  color: "#6c3dab",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => onTaskToEdit(task)}
              >
                Add
              </Box>
            ) : designerContext.email.toLowerCase() ===
              task.assignedTo.toLowerCase() ? (
              `Me ${
                task.assignedTo.toLowerCase() !== task.createdBy.toLowerCase()
                  ? isClient
                    ? "(by designer)"
                    : "(by client)"
                  : ""
              }`
            ) : (
              <Typography
                        noWrap
                        >{task.assignedTo.toLowerCase()}</Typography>
              
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ textAlign: "center" }}>{task.tag}</Box>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{ textAlign: "center" }}>
            {getPrettyDate(task.created, false, getAccountDateFormat())}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              cursor: "pointer",
              alignItems: "center",
              position: "relative",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={(e) => onCommentClick(task, e.currentTarget)}
          >
            {renderComments()}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <BasicStaticDropDown
              options={TaskPriority}
              value={`${TaskPriority.indexOf(task.priority) ?? 0}`}
              onChange={(value: number) => {
                onPriorityChange(value);
              }}
              overRideWidth={0}
              keyName={"TaskPriority"}
            />
          </Box>
        </Grid>

        <Grid item xs={1.5}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <StatusDropDown
              currentStatus={task.status}
              onStatusChange={onStatusChange}
              statusList={TaskStatus}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={0.8}
          sx={{ justifyContent: "flex-end", display: "flex" }}
        >
          {designerContext.email.toLowerCase() ===
            task.createdBy.toLowerCase() && (
            <Tooltip
              placement={"bottom"}
              componentsProps={{
                tooltip: {
                  sx: {
                    color: "#000",
                    padding: "20px 26px 20px 26px",
                    boxShadow: "0 9px 15px 0 rgba(0, 0, 0, 0.07)",
                    bgcolor: "#f5f8f7",
                    "& .MuiTooltip-arrow": {
                      color: "#000",
                    },
                  },
                },
              }}
              title={
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  {designerContext.email.toLowerCase() !==
                    task.assignedTo.toLowerCase() && task.status !== 'Done' && (
                    <Box
                      sx={{
                        cursor: "pointer",
                        alignItems: "center",
                        display: "flex",
                      }}
                      onClick={() => onSendReminder(task)}
                    >
                      <Box sx={{ marginRight: "20px" }}>
                        <InviteSvg width='20px' />
                      </Box>
                      Send a reminder
                    </Box>
                  )}
                  <Box
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      display: "flex",
                    }}
                    onClick={(e) => onTaskToEdit(task)}
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      <SmallEditSvg />
                    </Box>
                    Edit task
                  </Box>

                  <Divider />
                  <Box
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      display: "flex",
                    }}
                    onClick={(e) => onDeleteTask(task, e.currentTarget)}
                  >
                    <Box sx={{ marginRight: "20px" }}>
                      <SmallTrashSvg />
                    </Box>
                    Delete Task
                  </Box>
                </Box>
              }
              arrow={false}
            >
              <Box
                sx={{ display: "flex", padding: "0 20px", cursor: "pointer" }}
              >
                <BulletsIconSVG />
              </Box>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <BasicPageBlocker show={onProcessing} />
    </Box>
  );
};
