import { Box, Divider } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Project } from "../../projects/projectsSlice";
import { FacebookConnect } from "../Facebook/FacebookConnect";
import { LinkedInConnect } from "../LinkedIn/LinkedInConnect";
import { RecentPosts } from "../Posts/RecentPosts";
import useAsyncEffect from "use-async-effect";
import { LinkedInSync } from "../LinkedIn/LinkedInSync";
import { FacebookSync } from "../Facebook/FacebookSync";
import { InstagramConnect } from "../Instagram/InstagramConnect";
import { InstagramSync } from "../Instagram/InstagramSync";

export interface SocialManagerProps {
  project: Project;
}

const SocialManager: FC<SocialManagerProps> = ({ project }) => {
  const [forceUpdate, setForceUpdate] = React.useState(0);
  const { t } = useTranslation();

  useAsyncEffect(async () => {
    setForceUpdate(forceUpdate + 1);
  }, [project]);

  return (
    <>
      {project.social ? (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderRadius: "10px",
              flexGrow: 1,
              paddingBottom: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "27px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "30px",
                  flexGrow: 1,
                  fontFamily: "Nunito",
                  fontSize: "17px",
                  fontWeight: "bold",
                }}
              >
                {t("social.manager.title")}
              </Box>
            </Box>
            <Box sx={{ padding: "40px 50px 0px" }}>
              <Box sx={{ fontSize: "16px", marginBottom: "40px" }}>
                {`To manage your client’s social media content and posts in one place, connect their accounts.`}
              </Box>
              <Box sx={{ display: "flex" }} id="syncSocial">
                <Box>
                  <FacebookConnect
                    projectId={project.id}
                    projectFaceBookInfo={project.social.facebook}
                  />
                </Box>
                <Box sx={{ marginLeft: "30px" }}>
                  <LinkedInConnect
                    projectId={project.id}
                    isClientView={false}
                    isConnected={project.social.linkedin.connected}
                    organizationId={project.social.linkedin.id}
                  />
                </Box>
                <Box sx={{ marginLeft: "30px" }}>
                  <InstagramConnect
                    projectId={project.id}
                    projectInstagramInfo={project.social.instagram}
                  />
                </Box>
              </Box>
            </Box>
            {((project.social.facebook.connected &&
              project.social.facebook.id) ||
              (project.social.instagram.connected &&
                project.social.instagram.id) ||
              (project.social.linkedin.connected &&
                project.social.linkedin.id)) && (
              <>
                <Box sx={{ margin: "60px 40px 20px" }}>
                  <Divider />
                </Box>
                <Box sx={{ padding: "40px 50px 0px" }}>
                  <Box sx={{ fontSize: "16px", marginBottom: "30px" }}>
                    {`Sync your client's social media content`}
                  </Box>
                  <Box sx={{ display: "flex", gap: "30px" }}>
                    {project.social.facebook.connected && project.social.facebook.id && (
                      <Box sx={{ width: "355px" }}>
                        <FacebookSync
                          project={project}
                          projectFaceBookInfo={project.social.facebook}
                        />
                      </Box>
                    )}
                    {project.social.linkedin.connected && project.social.linkedin.id && (
                      <Box sx={{ width: "355px" }}>
                        <LinkedInSync
                          project={project}
                          projectLinkedInInfo={project.social.linkedin}
                        />
                      </Box>
                    )}
                    {project.social.instagram.connected && project.social.instagram.id && (
                      <Box sx={{ width: "355px" }}>
                        <InstagramSync
                          project={project}
                          projectInstagramInfo={project.social.instagram}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ margin: "60px 40px 20px" }}>
                  <Divider sx={{ borderColor: "#e9ebea" }} />
                </Box>
                <Box>
                  <RecentPosts project={project} />
                </Box>
              </>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default SocialManager;
