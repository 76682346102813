import { useMsal } from "@azure/msal-react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  Grid,
  ImageList,
  ImageListItem,
  InputBase,
  List,
  ListItem,
  ListItemText,
  NativeSelect,
  Typography,
} from "@mui/material";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { TemplateImage, templatesApi } from "../../api/templatesApi";
import {
  UploadCmsImageResponse,
  UploadDocumentResponse,
} from "../../api/uploadApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { ProjectState, setTemplateImages } from "../../projects/projectsSlice";
import "../cms.scss";
import { CMSState, setSiteImages } from "../cmsSlice";
import ProjectUtils from "../../projects/ProjectUtils";
import { DesignerContext } from "../../routes/AppRouter";
import { UserSiteImage } from "../../api/cmsApi";
import { getAccessTokenSilence } from "../../auth/auth";
import { ErrorMessagePopup } from "../../Massages/ErrorMessagePopup";
import { DragAndDropInput } from "../../basic/DragAndDropInput";

export interface ImagePickerProps {
  onClose(): void;
  showView: boolean;
  onAddImages(img: string, description?: string): void;
  onlyUpload?: boolean;
  allfiles?: boolean;
  singleFile?: boolean;
  isAccount?: boolean;
}

export const ImagePicker: React.FC<ImagePickerProps> = ({
  showView,
  onClose,
  onAddImages,
  onlyUpload,
  allfiles,
  singleFile,
  isAccount,
}) => {
  const [categories, setCategories] = React.useState<string[]>([]);
  const [selectedId, setSelectedId] = React.useState<number>(0);
  const [activeTab, setActiveTab] = React.useState<number>(2);
  const [onLaoding, setOnLaoding] = React.useState<boolean>(false);
  const [showUploadError, setShowUploadError] = React.useState<boolean>(false);

  const projectsData: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const msalInstance = useMsal();
  const dispatch = useAppDispatch();
  const accountContext = React.useContext(DesignerContext);

  const uploadTemplate = async () => {
    if (
      projectsData.templateImages.length === 0 &&
      !cmsState.blockCopy &&
      !cmsState.isClientMode
    ) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const templateImages = await templatesApi.getTemplateImages(
          token.accessToken
        );
        setCategories([
          ...["All"],
          ...templateImages.map((cat) => cat.sectionType),
        ]);
        dispatch(setTemplateImages(templateImages));
      }
    } else {
      setCategories([
        ...["All"],
        ...projectsData.templateImages.map((cat) => cat.sectionType),
      ]);
    }
    setActiveTab(
      onlyUpload ? 1 : cmsState.siteImages.length > 0 ? (onlyUpload ? 1 : 0) : 1
    );
  };

  useAsyncEffect(uploadTemplate, []);

  const onCancel = () => {
    onClose();
  };

  const renderImages = () => {
    let images: TemplateImage[] = [];
    if (selectedId === 0) {
      for (let i = 0; i < projectsData.templateImages.length; i++) {
        images = [...images, ...projectsData.templateImages[i].images];
      }
    } else {
      images = projectsData.templateImages[selectedId - 1].images;
    }
    return (
      <ImageList
        sx={{ marginLeft: "20px", width: "100%", height: "calc(100% - 80px)" }}
        gap={8}
        variant="quilted"
        cols={4}
        rowHeight={164}
      >
        {images.map((image) => (
          <ImageListItem key={image.imgSrc}>
            <img
              src={`${image.imgSrc}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${image.imgSrc}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={image.description}
              loading="lazy"
              onClick={() => onAddImages(image.imgSrc)}
              style={{ cursor: "pointer" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/attach.svg";
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = Number(e.target.value);
    setSelectedId(id);
  };

  const getFileNameWithoutExt = (file: File) => {
    try {
      return file.name.split(".").slice(0, -1).join(".");
    } catch {
      return "";
    }
  };

  const uploadFilesByEmail = async (files: File[]) => {
    const email = cmsState.anonymousEmail ?? accountContext.email;
    try {
      setOnLaoding(true);

      let imageList: UserSiteImage[] = [];

      if (cmsState.isClientMode && cmsState.projectId) {
        const projectId = cmsState.projectId;
        const allPromises: Promise<UploadDocumentResponse>[] = files.map(
          (file: File) => {
            return ProjectUtils.uploadDocumentToAzureByEmail(
              file,
              projectId,
              email
            );
          }
        );

        const allResponse = await Promise.all(allPromises);
        imageList = allResponse.map((res, idx) => {
          return {
            description: getFileNameWithoutExt(files[idx]),
            url: res.url,
            imageId: "",
          };
        });
      } else {
        const token = await getAccessTokenSilence(msalInstance);
        if (token) {
          const allPromises: Promise<UploadCmsImageResponse>[] = files.map(
            (file: File) => {
              return ProjectUtils.uploadImageToAzure(
                file,
                token.accessToken,
                isAccount ? "" : cmsState.metaDataId,
                accountContext.accountId
              );
            }
          );

          const allResponse = await Promise.all(allPromises);
          imageList = allResponse.map((res, idx) => {
            return {
              url: res.imageUrl,
              description: getFileNameWithoutExt(files[idx]),
              imageId: res.imageUrl,
            };
          });
        }
      }

      setOnLaoding(false);
      if (imageList.length === 1) {
        dispatch(setSiteImages([...imageList, ...cmsState.siteImages]));
        onAddImages(imageList[0].url, imageList[0].description);
      } else {
        dispatch(setSiteImages([...imageList, ...cmsState.siteImages]));
        setActiveTab(0);
      }
    } catch (e) {
      setShowUploadError(true);
    }
  };

  const uploadFiles = async (files: File[]) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      try {
        setOnLaoding(true);

        let imageList: UserSiteImage[] = [];

        if (cmsState.isClientMode && cmsState.projectId) {
          const projectId = cmsState.projectId;
          const allPromises: Promise<UploadDocumentResponse>[] = files.map(
            (file: File) => {
              return ProjectUtils.uploadDocumentToAzure(
                file,
                token.accessToken,
                projectId
              );
            }
          );

          const allResponse = await Promise.all(allPromises);
          imageList = allResponse.map((res, idx) => {
            return {
              description: getFileNameWithoutExt(files[idx]),
              url: res.url,
              imageId: "",
            };
          });
        } else {
          const allPromises: Promise<UploadCmsImageResponse>[] = files.map(
            (file: File) => {
              return ProjectUtils.uploadImageToAzure(
                file,
                token.accessToken,
                isAccount ? "" : cmsState.metaDataId,
                accountContext.accountId
              );
            }
          );

          const allResponse = await Promise.all(allPromises);
          imageList = allResponse.map((res, idx) => {
            return {
              url: res.imageUrl,
              description: getFileNameWithoutExt(files[idx]),
              imageId: res.imageUrl,
            };
          });
        }

        setOnLaoding(false);
        if (imageList.length === 1) {
          dispatch(setSiteImages([...imageList, ...cmsState.siteImages]));
          onAddImages(imageList[0].url, imageList[0].description);
        } else {
          dispatch(setSiteImages([...imageList, ...cmsState.siteImages]));
          setActiveTab(0);
        }
      } catch (e) {
        setShowUploadError(true);
      }
    }
  };

  const getImagesByPhase = () => {
    if (onlyUpload) {
      return cmsState.accountImages ?? [];
    } else {
      return cmsState.siteImages ?? [];
    }
  };

  return (
    <Modal onClose={onCancel} open={showView} sx={{ zIndex: 4001 }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          outline: "none",
          width: cmsState.mobileView && !cmsState.sideBarMode ? "90vw" : "60vw",
          background: "#fff",
          height:
            cmsState.mobileView && !cmsState.sideBarMode ? "40vh" : "60vh",
        }}
      >
        <div className="imageList">
          <Grid container>
            {(!cmsState.mobileView || cmsState.sideBarMode) && (
              <Grid item xs={3}>
                <div className={"imageListDrawerContainer"}>
                  <List
                    dense={true}
                    component="div"
                    disablePadding
                    style={{ marginTop: "30px", paddingLeft: "43px" }}
                  >
                    <ListItem
                      button
                      key={"SiteImages"}
                      onClick={() => setActiveTab(0)}
                    >
                      <ListItemText
                        style={{ color: activeTab === 0 ? "#5AC4F3" : "#FFF" }}
                        primary={
                          <Typography
                            sx={{ color: activeTab === 0 ? "#5AC4F3" : "#FFF" }}
                          >
                            {onlyUpload ? "Account Images" : "Project Images"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      button
                      key={"MyComputer"}
                      onClick={() => setActiveTab(1)}
                    >
                      <ListItemText
                        style={{ color: activeTab === 1 ? "#5AC4F3" : "#FFF" }}
                        primary={
                          <Typography
                            sx={{ color: activeTab === 1 ? "#5AC4F3" : "#FFF" }}
                          >
                            My Computer
                          </Typography>
                        }
                      />
                    </ListItem>
                    {!onlyUpload && !cmsState.isClientMode && (
                      <ListItem
                        button
                        key={"ImageLibrary"}
                        onClick={() => setActiveTab(2)}
                      >
                        <ListItemText
                          style={{
                            color: activeTab === 2 ? "#5AC4F3" : "#FFF",
                          }}
                          primary={
                            <Typography
                              sx={{
                                color: activeTab === 2 ? "#5AC4F3" : "#FFF",
                              }}
                            >
                              Image Library
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </div>
              </Grid>
            )}
            <Grid item xs={cmsState.mobileView && !cmsState.sideBarMode ? 12 : 9}>
              <Box width="100%" height="60vh">
                {activeTab === 0 && (
                  <ImageList
                    sx={{
                      marginLeft: "20px",
                      width: "100%",
                      height: "calc(100% - 40px)",
                    }}
                    gap={8}
                    variant="quilted"
                    cols={4}
                    rowHeight={164}
                  >
                    {getImagesByPhase().map((image) => (
                      <ImageListItem key={image.imageId}>
                        <img
                          src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${image.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={image.description}
                          loading="lazy"
                          onClick={() => onAddImages(image.url)}
                          style={{ cursor: "pointer", objectFit: "contain" }}
                          onError={(e: any) => {
                            if (
                              e.target.src.indexOf("/images/attach.svg") === -1
                            ) {
                              e.target.onerror = null;
                              e.target.src = "/images/attach.svg";
                              e.target.removeAttribute("srcset");
                            }
                          }}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
                {activeTab === 1 && (
                  <>
                    <Box
                      width="100%"
                      sx={{
                        marginLeft: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      height={cmsState.mobileView && !cmsState.sideBarMode? "40vh" : "60vh"}
                      position="relative"
                    >
                      <Box
                        // top="50%"
                        // left="50%"
                        width="80%"
                        height={cmsState.mobileView && !cmsState.sideBarMode ? "20vh" : "40vh"}
                        // position="absolute"
                        border="1px dotted #000"
                        display="flex"
                        style={{
                          // transform: "translate(-50%,-50%)",
                          verticalAlign: "middle",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Backdrop
                          sx={{
                            color: "#fff",
                            position: "absolute",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={onLaoding}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                        {/* <Box
                          width="200px"
                          margin="0 auto"
                          textAlign="center"
                          sx={{ fontSize: "18px" }}
                        > */}
                        {/* <Box>
                            {onlyUpload
                              ? "Upload Image"
                              : allfiles
                              ? "Upload Files"
                              : "Upload Images"}
                          </Box> */}

                        <DragAndDropInput
                          onFileSelect={uploadFilesByEmail}
                          onlyUpload={onlyUpload}
                          allfiles={allfiles}
                          singleFile={false}
                        />

                        {/* <input
                            accept={allfiles ? "*" : "image/*"}
                            className={"input"}
                            id="contained-button-file"
                            multiple={!!onlyUpload}
                            type="file"
                            onChange={handleUploadClick}
                          /> */}
                        {/* <label htmlFor="contained-button-file">
                            <Fab component="span" className={"uploadbutton"}>
                              <AddPhotoAlternateIcon fontSize="large" />
                            </Fab>
                          </label> */}
                        {/* </Box> */}
                      </Box>
                    </Box>
                  </>
                )}

                {activeTab === 2 && (
                  <>
                    <NativeSelect
                      value={selectedId}
                      sx={{
                        marginLeft: "20px",
                        marginTop: "10px",
                        "& .MuiInputBase-input": {
                          borderRadius: 4,
                          position: "relative",
                          border: "1px solid #ced4da",
                          fontSize: 16,
                          padding: "10px 26px 10px 12px",
                          fontFamily: "Nunito",
                          "&:focus": {
                            borderRadius: 4,
                            borderColor: "#80bdff",
                            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                          },
                        },
                      }}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      input={<InputBase />}
                    >
                      {categories.map((item, idx) => (
                        <option key={`img-cat-${idx}`} value={idx}>
                          {item}
                        </option>
                      ))}
                    </NativeSelect>
                    {renderImages()}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
        {showUploadError && (
          <ErrorMessagePopup
            message="Upload Failed"
            onClose={() => setShowUploadError(false)}
          />
        )}
      </Box>
    </Modal>
  );
};
