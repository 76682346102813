import { Box, Popper, TextField, Typography } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { ReactComponent as ShareFailerSVG } from "../assets/cms/shareFailer.svg";
import { ReactComponent as CheckSvg } from "../assets/checkbox.svg";

import { ReactComponent as CopyLinkSVG } from "../assets/link.svg";
import { Project, ProjectState } from "../projects/projectsSlice";
import { ProjectUpdateRequest, projectsApi } from "../api/projectsApi";
import { useMsal } from "@azure/msal-react";
import { BasicMessagePopup } from "../Massages/BasicMessagePopup";
import { getAccessTokenSilence } from "../auth/auth";
import { ShareLinkPopup } from "../projects/ProjectBriefs/BriefStageStatus/ShareLinkPopup";
import { ColorButton, CustomCheckBox } from "../projects/ProjectUIHelper";
import { CUSTOMER_EMAIL_CMS_ID, DESIGNER_LANG_TOKEN } from "../utils/Constants";
import { InputEmailField } from "./InputEmailField";
import { useDesginerInfo } from "../hooks/useDesginerName";
import { ShareBtnWithBrandingCheck } from "../settings/ShareBtnWithBrandigCheck";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { HelpToolTipOnHover } from "./HelpToolTipOnHover";
import InfoIcon from "@mui/icons-material/Info";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAccountSettingsData } from "../hooks/useAccountTasksTemplates";



export interface ShareDialogWithStepPopupProps {
  onClose(): void;
  onShare(
    subject: string,
    message: string,
    emails: string[],
    isAnonymous: boolean,
    validForInDays: number,
    token: string,
    dueDate?: Dayjs,
  ): Promise<boolean>;
  onSharedSuccess(): void;
  copyLinkId: string;
  firstStage: boolean;
  firstStageComp: React.ReactNode;
  project: Project;
  title: string;
  header?: string;
  addedContent?: React.ReactNode;
  emailMessageDefault: string;
  emailSubjectDefault: string;
  shareBtnTitle?: string;
  hideCloseOnFirstStage?: boolean;
  blockUser?: boolean;
  allowAnonymous?: boolean;
}

export const ShareDialogWithStepPopup: React.FC<
  ShareDialogWithStepPopupProps
> = ({
  onClose,
  onShare,
  firstStage,
  firstStageComp,
  onSharedSuccess,
  project,
  copyLinkId,
  title,
  header,
  addedContent,
  emailMessageDefault,
  emailSubjectDefault,
  shareBtnTitle,
  hideCloseOnFirstStage,
  blockUser,
  allowAnonymous,
}) => {
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [showCopyLinkPopup, setShowCopyLinkPopup] =
    React.useState<boolean>(false);
  const [emailSubject, setEmailSubject] = React.useState<string>("");
  const [emailMessage, setEmailMessage] = React.useState<string>("");
  const [shareResult, setShareResult] = React.useState<boolean | undefined>();
  const [onProcessing, setOnProcessing] = React.useState<boolean>(false);
  const [missingEmail, setMissingEmail] = React.useState<boolean>(false);
  const [dirtyFlag, setDirtyFlag] = React.useState<boolean>(false);
  const [linkForAll, setLinkForAll] = React.useState<boolean>(false);
  const [validForInDays, setValidForInDays] = React.useState<number>(0);
  const [enableDueDate, setEnableDueDate] = React.useState<boolean>(false);
  const [dueDate, setDueDate] = React.useState<Dayjs | null>(null);
  const {getAccountDateFormat} = useAccountSettingsData()

  const dateFormat = React.useMemo(() => getAccountDateFormat(), [getAccountDateFormat]);

  const { getDesignerName } = useDesginerInfo();

  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const msalInstance = useMsal();

  useAsyncEffect(async () => {
    const email = get(JSON.parse(project.additionalData), "8000", "");
    if (email !== "") {
      setUserEmail(email);
    } else {
      setMissingEmail(true);
    }
  }, []);

  useAsyncEffect(async () => {
    setOnProcessing(blockUser ? true : false);
  }, [blockUser]);

  useAsyncEffect(async () => {
    setEmailSubject(
      emailSubjectDefault.replace(DESIGNER_LANG_TOKEN, getDesignerName())
    );
    setEmailMessage(
      emailMessageDefault.replace(DESIGNER_LANG_TOKEN, getDesignerName())
    );
  }, [emailSubjectDefault, emailMessageDefault]);

  useAsyncEffect(async () => {
    if (!dirtyFlag) {
      setEmailSubject(
        emailSubjectDefault.replace(DESIGNER_LANG_TOKEN, getDesignerName())
      );
      setEmailMessage(
        emailMessageDefault.replace(DESIGNER_LANG_TOKEN, getDesignerName())
      );
    }
  }, [projectsState.showAccountPopup]);

  const updateMissingEmail = async (email: string, token: string) => {
    const projectAdded = JSON.parse(project.additionalData);
    const projectContent = JSON.parse(project.content ?? "{}");
    const data: ProjectUpdateRequest = {
      id: project.id,
      name: project.name,
      content: JSON.stringify({
        ...projectContent,
        [CUSTOMER_EMAIL_CMS_ID]: email,
      }),
      additionalData: JSON.stringify({ ...projectAdded, ["8000"]: email }),
      status: project.status,
    };
    await projectsApi.editUserProject(data, token);
  };

  const onShareClick = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnProcessing(true);
      const shareResponse = await onShare(
        emailSubject,
        emailMessage,
        [userEmail],
        linkForAll,
        validForInDays,
        token.accessToken,
        dueDate && enableDueDate ? dueDate : undefined,        
      );
      if (shareResponse && missingEmail) {
        await updateMissingEmail(userEmail, token.accessToken);
      }
      setOnProcessing(false);
      if (shareResponse) {
        onSharedSuccess();
      }
      setShareResult(shareResponse ? shareResponse : false);
    } else {
      setOnProcessing(false);
      setShareResult(false);
    }
  };

  const renderEmailFailed = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <Box sx={{ marginBottom: "30px" }}>
          <ShareFailerSVG />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            Opps, there was a problem
          </Typography>
          <br />
          <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
            Please try sending it again
          </Box>
          <ColorButton
            sx={{ marginTop: "40px" }}
            onClick={() => setShareResult(undefined)}
          >
            Try again
          </ColorButton>
        </Box>
      </Box>
    );
  };

  return (
    <BasicMessagePopup
      title={title}
      onClose={() => onClose()}
      open={true}
      extraWidth={"40vw"}
      disableAutoClose={true}
      zIndex={3000}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          margin: "20px 10% 0px",
          position: "relative",
          padding: "5px",
        }}
      >
        {firstStage ? (
          <>{firstStageComp}</>
        ) : shareResult === undefined ? (
          <>
            <Box
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: 300,
                paddingTop: "20px",
                marginBottom: "10px",
              }}
            >
              {header ?? ""}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Box sx={{ width: "80%", flexGrow: 1 }}>
                <InputEmailField
                  handleChange={(val: string) => setUserEmail(val)}
                  defaultValue={userEmail}
                  placeholder={"Email"}
                  isFullWidth={true}
                  required={false}
                />
              </Box>
            </Box>
            <Box sx={{ width: "100%", marginTop: "28px" }}>
              <TextField
                sx={{ width: "100%", background: "#f9f9f9" }}
                label={"Subject"}
                className={emailSubject !== "" ? "inputWithVal" : ""}
                required={true}
                value={emailSubject}
                placeholder="Message"
                onChange={(e) => {
                  setDirtyFlag(true);
                  setEmailSubject(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ width: "100%", marginTop: "28px" }}>
              <TextField
                sx={{ width: "100%", background: "#f9f9f9" }}
                multiline
                label={"Message"}
                className={emailMessage !== "" ? "inputWithVal" : ""}
                maxRows={5}
                rows={5}
                value={emailMessage}
                onChange={(e) => {
                  setDirtyFlag(true);
                  setEmailMessage(e.target.value);
                }}
              />
            </Box>
            {addedContent}
            {allowAnonymous && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "5px",
                    alignItems: "center",
                  }}
                >
                  <CustomCheckBox
                    checkedIcon={
                      <CheckSvg style={{ transform: "scale(0.7)" }} />
                    }
                    checked={linkForAll}
                    onChange={() => setLinkForAll(!linkForAll)}
                  />
                  <Box sx={{ fontSize: "16px" }}>
                    Anyone with a link can access
                  </Box>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomCheckBox
                      checkedIcon={
                        <CheckSvg style={{ transform: "scale(0.7)" }} />
                      }
                      checked={validForInDays !== 0}
                      onChange={() =>
                        setValidForInDays(validForInDays === 0 ? 100 : 0)
                      }
                    />
                    <Box
                      sx={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Send a daily reminder
                      <HelpToolTipOnHover
                        position="top-start"
                        size={250}
                        message={
                          "Lynxi will send a daily email reminder with the number of unanswered questions until the form is submitted."
                        }
                        actionNode={<InfoIcon fontSize="small" />}
                      />
                    </Box>
                    
                  </Box>
                  <Box sx={{ display: "flex", alignItems:'center' }}>
                      <CustomCheckBox
                        checkedIcon={
                          <CheckSvg style={{ transform: "scale(0.7)" }} />
                        }
                        checked={enableDueDate}
                        onChange={() => setEnableDueDate(!enableDueDate)}
                      />
                      <DatePicker
                      slots={{
                        popper: (props) => <Popper {...props} sx={{  zIndex: 5000 }} />
                    }}
                        label="Due date"
                        className="datePicker"
                        value={dueDate}
                        format={dateFormat}
                        disabled={!enableDueDate}
                        onChange={(newValue: any) => setDueDate(newValue)}
                      />
                    </Box>
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                marginTop: "28px",
                paddingBottom: "10px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => setShowCopyLinkPopup(true)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CopyLinkSVG style={{ marginRight: "5px" }} /> Copy link
                </Box>
              </Box>
              <ShareBtnWithBrandingCheck
                btnText={shareBtnTitle ? shareBtnTitle : "Share"}
                disabled={
                  userEmail === "" || emailSubject === "" || onProcessing
                }
                onContinue={() => onShareClick()}
                onProcessing={onProcessing}
              />
            </Box>
          </>
        ) : (
          renderEmailFailed()
        )}
      </Box>

      {showCopyLinkPopup && (
        <ShareLinkPopup
          projectId={project.id}
          id={copyLinkId}
          isFileShare={project.id === copyLinkId}
          initialEmail={userEmail}
          allowAnonymous={allowAnonymous}
          defaultLinkForAll={linkForAll}
          allowTimeLimit={allowAnonymous}
          defaultValidForInDays={validForInDays}
          onClose={(linkWasGenerated: boolean) => {
            setShowCopyLinkPopup(false);
            if (linkWasGenerated) {
              onSharedSuccess();
            }
          }}
        />
      )}
    </BasicMessagePopup>
  );
};
