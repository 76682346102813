import { Box, Grid, IconButton, TextField } from "@mui/material";
import * as React from "react";
import { InputEmailField } from "../../basic/InputEmailField";
import { InputURLField } from "../../basic/InputURLField";
import ClearIcon from "@mui/icons-material/Clear";

import "../cms.scss";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import { CMSFieldCopyBtn } from "../../basic/CMSFieldCopyBtn";
import useAsyncEffect from "use-async-effect";
import { ProjectDetalisContext } from "../../projects/ProjectForm/ProjectSettings";
import { useUpdateCmsWatchedItems } from "../../hooks/useUpdateCmsWatchedItems";

export interface SimpleTextCompProps {
  comp: CMSComponent;
  padding: number;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const SimpleTextComp: React.FC<SimpleTextCompProps> = ({
  comp,
  padding,
  location,
  onUpdateComp,
  readonly,
}) => {
  const [value, setValue] = React.useState<string>("");
  const context = React.useContext(ProjectDetalisContext);
  const { updateWatchedItems } = useUpdateCmsWatchedItems();

  useAsyncEffect(async () => {
    setValue(comp.value);
  }, []);

  const updateCompData = (text: string) => {
    const updateComp = { ...comp, value: text, isFromTemplate: false };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  const onInputChange = (newValue: string) => {
    if (newValue !== comp.value) {
      updateCompData(newValue);
    }
    setValue(newValue);
  };

  return (
    <Grid item xs={padding}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          {comp.inputType ? (
            comp.inputType === "email" ? (
              <InputEmailField
                data-id={comp.mapKey}
                handleChange={(val) => onInputChange(val)}
                required={comp.required}
                isFullWidth={true}
                defaultValue={value}
                label={comp.description}
                disabled={readonly}
                id={`compKey-${comp.mapKey}`}
              />
            ) : (
              <InputURLField
                handleChange={(val) => onInputChange(val)}
                required={comp.required}
                defaultValue={value}
                label={comp.description}
                disabled={readonly}
                id={`compKey-${comp.mapKey}`}
              />
            )
          ) : (
            <TextField
              // onBlur={ () => onInputChange2()}
              // id={`compKey-${comp.mapKey}`}
              id={`compKey-${comp.mapKey}`}
              sx={{
                width: "100%",
                background: "#f9f9f9",
                "& input.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                },
              }}
              onFocus={() => {
                if (comp.mapKey !== "") updateWatchedItems([comp.mapKey]);
              }}
              label={comp.description}
              className={value !== "" ? "inputWithVal" : ""}
              variant="outlined"
              disabled={readonly}
              required={comp.required}
              value={value}
              placeholder={comp.description}
              onChange={(e) => onInputChange(e.target.value)}
              inputProps={{ className: "dropDownOther" }}
              InputProps={
                !readonly
                  ? {
                      // sx:{fontSize: '14px', fontFamily: 'Nunito'},
                      endAdornment: (
                        <IconButton
                          sx={{ visibility: value ? "visible" : "hidden" }}
                          onClick={() => {
                            setValue("");
                            onInputChange("");
                          }}
                        >
                          <ClearIcon
                            fontSize="small"
                            sx={{ color: "#d3d3d3" }}
                          />
                        </IconButton>
                      ),
                    }
                  : {}
              }
            />
          )}
          {context &&
            context.errors.filter((r) => r !== "").indexOf(comp.mapKey) >=
              0 && (
              <Box sx={{ color: "red", fontSize: "12px", marginLeft: "3px" }}>
                This field is required
              </Box>
            )}
        </Box>
        <CMSFieldCopyBtn value={comp.value} />
      </Box>
    </Grid>
  );
};
