import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

interface StatusDropDownProps {
  currentStatus: string;
  onStatusChange(value: string): void;
  statusList: string[];
  // Define any props needed for the component
}

export const StatusDropDown: React.FC<StatusDropDownProps> = ({
  currentStatus,onStatusChange, statusList
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "In Progress":
      case "In Process":
        return "#12d87c";
      case "Done":
      case "Completed":
        return "#0a9856";
      case "Not Started":
      case "Pending":
        return "#f6a111";
      case "Canceled":
        return "#e4572e";
      case "On Hold":
        return "#929292";
    }
  };

  const onChange = async (event: SelectChangeEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onStatusChange(event.target.value);
  }

  const renderDot = (bgColor?: string) => {
    return (
      <Box
        className={"dot2"}
        sx={{
          marginRight: "6px",
          width: "12px",
          height: "12px",
          borderRadius: "8px",
          background: bgColor,
        }}
      ></Box>
    );
  };

  return (
    <FormControl sx={{ borderRadius: "20px" }}>
      <Select
        sx={{
          fontFamily: "Nunito",
          fontSize: "14px",
          fontWeight: "700",
          background: getStatusColor(currentStatus),
          color: "#fff",
          borderRadius: "20px",
          border: "none",
          width: "140px",
          textAlign: "left",

          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: "20px",
          },
          " > *": {
            borderRadius: "20px",
          },
          "&:focus": {
            borderRadius: "20px !important",
          },
          "&:hover": {
            "&& fieldset": {
              border: "none",
              borderRadius: "20px",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
        displayEmpty
        inputProps={{
          sx: {
            color: "#fff",
            background: getStatusColor(currentStatus),
            borderRadius: "20px",
            border: "none",
            fontFamily: "Nunito",
            padding: "6px 6px",
            margin: "0px 0px 0 8px",
            "& .dot2": { display: "none" },
            "&:hover": { border: "none" },
            "&:focus": {
              borderRadius: "20px !important",
            },
          },
        }}
        MenuProps={{
          disablePortal: true,
          sx: {
            "& .MuiMenuItem-root.Mui-selected": {
              display: "none",
            },
            "& .MuiMenuItem-root.Mui-selected:hover": {
              backgroundColor: "#000",
              color: "#fff",
              border: "1px solid #000",
            },
          },
        }}
        onChange={onChange}
        value={currentStatus}
      >
        {statusList.map((status, index) => (
          <MenuItem
            key={`menu-status-${index}`}
            sx={{ color: "#000", fontFamily: "Nunito" }}
            value={status}
          >
            {renderDot(getStatusColor(status))}{status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
