import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { ProjectState, setAccountInfo } from "../projects/projectsSlice";
import { TaskSavedList } from "../api/tasksApi";
import { useMsal } from "@azure/msal-react";
import { getAccessTokenSilence } from "../auth/auth";
import { backofficeApi } from "../api/backofficeApi";

export const useAccountSettingsData = () => {
  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const dispatch = useAppDispatch();
  const instance = useMsal()


  const getAccountDateFormat = () => {
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(projectState.accountInfo.additionalData??'{}') ;  
      const dateFormat  = _.get(additionalData, "dateFormat", ['DD/MM/YYYY']);
      return dateFormat[0]
    } else return 'DD/MM/YYYY';
  };


  const getTaskTemplateList = () => {
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(projectState.accountInfo.additionalData??'{}') ;  
      const templates  = _.get(additionalData, "taskList", []);
      return templates
    } else return [];
  };

  const addTaksTemplatesList = async (template: TaskSavedList) => {
    if (projectState.accountInfo) {
      const additionalData = JSON.parse(projectState.accountInfo?.additionalData??'{}') ;  
      const templates  = _.get(additionalData, "taskList", []);
      const updatedAdditionalData = {...additionalData, taskList: [...templates, template]}
      const updatedAccountInfo = {...projectState.accountInfo, additionalData: JSON.stringify(updatedAdditionalData)}
      dispatch(setAccountInfo(updatedAccountInfo))
      const token = await getAccessTokenSilence(instance)
      if (token) {
        const data = {
          ...updatedAccountInfo,
          accountId: projectState.accountInfo.id,
        }
        await backofficeApi.updateAccountSettings(data, token.accessToken);
      }
    }
  }

  return { getTaskTemplateList, addTaksTemplatesList, getAccountDateFormat};
};
