import axios from "axios";
import ApiUtils from "./ApiUtils";
import { TemplateStructure } from "../SiteEditor/Stages/PickTemplate/PickTemplate";
import { ElementorSite } from "../projects/projectsSlice";
import { TemplateMappingObject } from "../SiteEditor/Stages/EditTemplate/EditTemplateUtils";

export interface TemplateImage {
  description: string;
  imgSrc: string;
}

export interface ImageCategory {
  sectionType: string;
  images: TemplateImage[];
}

export interface TemplateData {
  templateId: number;
  templateName: string;
  imgSrc: string;
  domain: string;
}

export interface TemplateInfoData {
  BlogId: string;
  FromBlogId: string;
  description: string;
  name: string;
  imageUrl?: string;
  templateStruct: TemplateStructure;
}

export interface CheckDomainResponse {
  exists: boolean;
  alternative_name: string;
}

export interface TemplateInfogDataResponse {
  templates: TemplateInfoData[];
}

export interface TemplateDefaultContentResponse {
  cmsContent: { [id: string]: any };
}

class TemplatesApi {
  getTemplateImages = async (token: string): Promise<ImageCategory[]> => {
    try {
      const response = await axios.get(
        `${ApiUtils.serverDomain}/templates/api/templates/images`,
        ApiUtils.Get(token)
      );
      if (response.status === 200) {
        return response.data;
      } else return [];
    } catch (e) {
      return [];
    }
  };

  getTemplates = async (token: string): Promise<TemplateData[]> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/templates/api/templates`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  createTemplate = async (token: string, templateJson: any) => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/sites/api/template`,
      templateJson,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  createTemplateMapping = async (
    templateId: string,
    token: string,
    templateJson: any
  ) => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/sites/api/template/${templateId}/mapping`,
      templateJson,
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getTemplateList = async (
    token: string
  ): Promise<TemplateInfogDataResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/template`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  createSiteFromTemplate = async (
    projectId: string,
    token: string,
    templateJson: TemplateStructure,
    publish?: boolean
  ) => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/sites/api/elementor`,
      {
        projectId,
        template: templateJson,
        publish: publish ? true : false,
      },
      ApiUtils.Post(token)
    );
    return response.data;
  };

  publishSite = async (
    projectId: string,
    token: string,
    templateJson: TemplateStructure,
    contentDicString?: string
  ) => {
    const response = await axios.post(
      `${ApiUtils.serverDomain}/sites/api/elementor`,
      {
        projectId,
        template: templateJson,
        publish: true,
        cmsContent: contentDicString,
      },
      ApiUtils.Post(token)
    );
    return response.data;
  };

  getSiteByProjectID = async (
    projectId: string,
    token: string
  ): Promise<ElementorSite> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/elementor/project/${projectId}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  checkIfNameExists = async (
    subdomain: string,
    token: string
  ): Promise<CheckDomainResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/elementor/site_exists/${subdomain}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  deleteSiteByProjectID = async (projectId: string, token: string) => {
    try {
      const response = await axios.delete(
        `${ApiUtils.serverDomain}/sites/api/elementor/project/${projectId}`,
        ApiUtils.Delete(token)
      );
      if (response.status === 200) {
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  getTemplateFontAndColorInfo = async (blogId: string, token: string) => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/template/${blogId}/fonts_colors`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getSiteMappingByBlogId = async (
    blogId: string,
    token: string
  ): Promise<TemplateMappingObject> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/Template/${blogId}/mapping`,
      ApiUtils.Get(token)
    );
    return response.data;
  };

  getSiteContentByBlogId = async (
    blogId: string,
    token: string
  ): Promise<TemplateDefaultContentResponse> => {
    const response = await axios.get(
      `${ApiUtils.serverDomain}/sites/api/elementor/generate_cms_data/${blogId}`,
      ApiUtils.Get(token)
    );
    return response.data;
  };
}

export const templatesApi = new TemplatesApi();
