import { Box } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Project } from "../../projectsSlice";
import { ColorButton } from "../../ProjectUIHelper";
import { ClientUrl } from "../../../authConfig";
import { BasicEmptyStage } from "../../../basic/BasicEmptyStage/BasicEmptyStage";
import { ReactComponent as EmptyWebsitesSVG } from "../../../assets/project/emptyWebSite.svg";



export interface EmptyWebSite {
  project: Project;
}

export const EmptyWebSite: FC<EmptyWebSite> = ({ project }) => {
  const { t } = useTranslation();

  const onGoToSiteBuilder = () => {
    window.open(
      `${ClientUrl}/projects/${project.id}/websiteBuilder/${project.cmsDataId}`,
      "_lynxiEditor"
    );
  };

  //<img src="/images/website/emptyWebSite.svg" />
  return (
    <BasicEmptyStage icon={<EmptyWebsitesSVG />}>
      <>
        <Box sx={{ fontSize: "18px", fontWeight: "500" }}></Box>
        <Box sx={{ display: "flex", marginTop: "10px" }}>
          {`Utilize Lynxi's capabilities to automatically create a website`}
        </Box>
        <Box sx={{ display: "flex", marginTop: "5px" }}>
          {`using the content from your client's Content Hub.`}
        </Box>
        <Box>
          <ColorButton
            onClick={() => onGoToSiteBuilder()}
            sx={{ marginTop: "30px" }}
          >
            Go to Site Builder
          </ColorButton>
        </Box>
      </>
    </BasicEmptyStage>
  );
};
