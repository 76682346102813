import { useMsal } from "@azure/msal-react";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as InstagramSvg } from "../../assets/social/instagram.svg";

import {
  InstagramConnectedPageInfo,
  Project,
} from "../../projects/projectsSlice";

import { get } from "lodash";
import { cmsApi, CMSResponse } from "../../api/cmsApi";
import { InstagramUser, socialApi } from "../../api/socialApi";
import { getAccessTokenSilence } from "../../auth/auth";
import {
  SocialItemDataDefinitions,
  SocialSync,
  SocialSyncResponse,
  SocialSyncSuccessResponse,
} from "../Manager/SocialSync";
import "../social.scss";

export interface InstagramSyncProps {
  project: Project;
  projectInstagramInfo: InstagramConnectedPageInfo;
}

const TranslationMapFromInstagram: SocialItemDataDefinitions[] = [
  { key: "biography", cmsKey: 48, compType: "paragraph" },
  { key: "webSite", cmsKey: 5, compType: "text" },
  {
    key: "profilePictureUrl",
    cmsKey: 43,
    compType: "img",
  },
];

export const InstagramSync: FC<InstagramSyncProps> = ({
  project,
  projectInstagramInfo,
}) => {
  const msalInstance = useMsal();

  const updateCMSFromInstagram = async (instagramUserInfo: InstagramUser) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (!token) {
      return { success: false };
    }
    const cmsResponse: CMSResponse = await cmsApi.getCmsDataFromServer(
      project.cmsDataId ?? "",
      token.accessToken
    );
    const cmsDic = JSON.parse(cmsResponse.content);
    const cmsAiDic = JSON.parse(cmsResponse.aiContent);

    let facebookImages = {};
    const hasImages =
      TranslationMapFromInstagram.filter((r) => r.compType === "img").length >
      0;
    if (hasImages) {
      facebookImages = await socialApi.getFaceBookPageImages(
        project.id,
        token.accessToken
      );
    }

    const instagramCmsDic: { [id: string]: any } = {};

    TranslationMapFromInstagram.filter((r) => !r.calculated).forEach(
      (mapDic) => {
        switch (mapDic.compType) {
          case "paragraph": {
            instagramCmsDic[mapDic.cmsKey] = get(instagramUserInfo, mapDic.key);
            break;
          }
          case "img": {
            const src = get(instagramUserInfo, mapDic.key);
            if (src && src !== "") {
              instagramCmsDic[mapDic.cmsKey] = src;
            }
            break;
          }
          case "text": {
            instagramCmsDic[mapDic.cmsKey] = get(instagramUserInfo, mapDic.key);
            break;
          }
        }
      }
    );

    return {
      success: true,
      socialSyncData: {
        cmsDic,
        cmsSchema: cmsResponse.schema,
        socialItems: TranslationMapFromInstagram,
        locationExists: false,
        socialCmsDic: instagramCmsDic,
        cmsAiDic,
      },
    };
  };

  const onSync = async (): Promise<SocialSyncResponse> => {
    //
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      try {
        const instagramUserData = await socialApi.getInstagramConnectUserInfo(
          project.id,
          token.accessToken
        );
        if (instagramUserData && instagramUserData.user) {
          return await updateCMSFromInstagram(instagramUserData.user);
        }
      } catch (e: any) {
        if (e.message === "Reconnecting") {
          //
        }
      }
    }
    return { success: false };
  };

  const prepareSocialDataForSync =
    async (): Promise<SocialSyncSuccessResponse> => {
      return { success: true };
    };

  return (
    <>
      <SocialSync
        project={project}
        onUpdateSocialData={prepareSocialDataForSync}
        onSync={onSync}
        socialName="Instagram"
        icon={<InstagramSvg/>}
        onlyFromSocial={true}
        socialLink={`https://linkedin.com/company/${project.social?.linkedin.id}`}
      />
    </>
  );
};
