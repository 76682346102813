import React from "react";
import { BasicDraggableDialog } from "../../basic/BasicDraggableDialog";
import { Box, Fab, TextField } from "@mui/material";
import { InputEmailField } from "../../basic/InputEmailField";
import {
  ColorButton,
  ColorButtonPurpleOutLined,
  PurpleButton,
} from "../../projects/ProjectUIHelper";
import { StaticDropDown } from "../../Intro/StaticDropDown";
import { TaskInfo, TaskPriority } from "../../api/tasksApi";
import { DesignerContext } from "../../routes/AppRouter";
import { AccountMember } from "../../api/backofficeApi";
import { BasicRichEditor } from "../../basic/BasicRichEditor/BasicRichEditor";
import AddIcon from "@mui/icons-material/Add";

interface NewTaskFormProps {
  onClose(): void;
  isClient?: boolean;
  onCreateNewTask(task: TaskInfo): void;
  onUpdateTask(task: TaskInfo): void;
  task?: TaskInfo;
  coWorkers?: AccountMember[];
  avaliableTags: string[];
  designerEmail?: string;
}

export const NewTaskForm: React.FC<NewTaskFormProps> = ({
  onClose,
  isClient,
  onCreateNewTask,
  onUpdateTask,
  task,
  coWorkers,
  avaliableTags,
  designerEmail,
}) => {
  const [taskName, setTaskName] = React.useState<string>("");
  const [assignedType, setAssignedType] = React.useState<string | null>(
    "Myself"
  );
  const [taskPriority, setTaskPriority] = React.useState<string>("Low");
  const [email, setEmail] = React.useState<string>("");
  const [tag, setTag] = React.useState<string | null>(null);
  const [tagOptions, setTagOptions] = React.useState<string[]>(avaliableTags);
  const designerContext = React.useContext(DesignerContext);
  const [showNewTag, setShowNewTag] = React.useState<boolean>(false);
  const [newTagValue, setNewTagValue] = React.useState<string>("");

  React.useEffect(() => {
    if (task) {
      setTaskName(task.name);
      if (
        task.assignedTo === "" ||
        task.assignedTo.toLowerCase() === designerContext.email.toLowerCase()
      ) {
        setEmail(designerContext.email);
      } else if (
        coWorkers &&
        coWorkers.find(
          (coWorker) =>
            coWorker.email.toLowerCase() === task.assignedTo.toLowerCase()
        )
      ) {
        setEmail(task.assignedTo);
        setAssignedType(isClient ? "Designer" : "Co-worker");
      } else {
        setEmail(task.assignedTo);
        setAssignedType(isClient ? "Designer" : "Client");
      }
      setTaskPriority(task.priority);
      setTag(task.tag);
    } else {
      setEmail(designerContext.email);
    }
  }, [task]);

  const onAssignedTypeChange = (val: string | null) => {
    setAssignedType(val);
    if (val === "Myself") {
      setEmail(designerContext.email);
    } else if (val === "Co-worker") {
      setEmail(coWorkers ? coWorkers[0].email : "");
    } else if (val === "Designer") {
      setEmail(designerEmail ?? "");
    } else {
      setEmail("");
    }
  };

  const onSetTag = (val: string | null) => {
    if (val) {
      if (val.indexOf("Add ") === 0) {
        const _val = val.replace(`Add `, "").replace(`"`, "").replace(`"`, "");
        setTag(_val);
        setTagOptions([...tagOptions, _val]);
      } else {
        setTag(val);
      }
    } else {
      setTag(null);
    }
  };

  const onCoWorkerChange = (val: string | null) => {
    if (val && coWorkers) {
      setEmail(val);
    }
  };

  const onButtonClick = () => {
    if (task) {
      onUpdateTask({
        ...task,
        name: taskName,
        assignedTo: email,
        priority: taskPriority,
        tag: tag ?? "",
      });
    } else {
      onCreateNewTask({
        name: taskName,
        assignedTo: email,
        createdBy: designerContext.email,
        id: "",
        status: "Not Started",
        tag: tag ?? "",
        priority: taskPriority,
      });
    }
  };

  const addNewTag = () => {
    if (newTagValue !== "") {
      setTagOptions([...tagOptions, newTagValue]);
      setTag(newTagValue);
    }
    setShowNewTag(false);
    setNewTagValue("");
  };

  return (
    <BasicDraggableDialog
      title={task ? "Update task" : "New task"}
      onClose={onClose}
      extraWidth="800px"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            position: "relative",
            paddingTop: "10px",
          }}
        >
          <Box sx={{ fontSize: "14px" }}>Task:</Box>
          <BasicRichEditor
            value={taskName}
            topToolBar={true}
            onUpdate={(value: string) => setTaskName(value)}
            addTextDecoration={true}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              width: "60%",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Assigned to:</Box>
            <StaticDropDown
              handleChange={(val: string | null) => onAssignedTypeChange(val)}
              value={assignedType}
              showError={false}
              options={
                isClient
                  ? ["Myself", "Designer"]
                  : coWorkers && coWorkers.length > 0
                  ? ["Myself", "Co-worker", "Client"]
                  : ["Myself", "Client"]
              }
              placeHolder=""
              background="#f9f9f9"
              disableClear={true}
            />
            {assignedType === "Client" && (
              <InputEmailField
                handleChange={(val) => setEmail(val)}
                defaultValue={email}
                placeholder={"Assigned to- Email"}
                isFullWidth={true}
                required={false}
              />
            )}
            {assignedType === "Co-worker" && (
              <StaticDropDown
                handleChange={(val: string | null) => onCoWorkerChange(val)}
                value={email}
                showError={false}
                options={(coWorkers ?? []).map((coWorker) =>
                  coWorker.email.toLowerCase()
                )}
                placeHolder=""
                background="#f9f9f9"
                disableClear={true}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3px",
              width: "35%",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ fontSize: "14px" }}>Priority:</Box>
            <StaticDropDown
              handleChange={(val: string | null) =>
                setTaskPriority(val ?? TaskPriority[0])
              }
              value={taskPriority}
              showError={false}
              options={TaskPriority}
              placeHolder=""
              background="#f9f9f9"
              disableClear={true}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
          <Box sx={{ fontSize: "14px" }}>Select label:</Box>
          {/* <StaticDropDown
            allowSolo={true}
            handleChange={(val: string | null) => onSetTag(val)}
            value={tag}
            showError={false}
            options={tagOptions}
            background="#f9f9f9"
            disableClear={true}
            placeHolder={"Pick group label or add new"}
          /> */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginTop: "40px",
            }}
          >
            {tagOptions.map((s: string, idx: number) => (
              <div key={`token-${idx}`} style={{ position: "relative" }}>
                {tag !== s ? (
                  <ColorButtonPurpleOutLined
                    onClick={() => onSetTag(s)}
                    key={`seokey-${idx}`}
                    sx={{ padding: "4px 30px" }}
                  >
                    {s}
                  </ColorButtonPurpleOutLined>
                ) : (
                  <PurpleButton
                    onClick={() => onSetTag("")}
                    key={`seokey-${idx}`}
                    sx={{ padding: "4px 30px" }}
                  >
                    {s}
                  </PurpleButton>
                )}
              </div>
            ))}
            {showNewTag ? (
              <TextField
                onBlur={() => addNewTag()}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewTag();
                  }
                }}
                autoFocus
                className={"dropDownOther"}
                sx={{ width: "100%", background: "#f9f9f9" }}
                variant="outlined"
                autoComplete={"off"}
                placeholder={"Add a new label"}
                value={newTagValue}
                onChange={(e) => setNewTagValue(e.target.value)}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <Fab
                  size="small"
                  sx={{
                    boxShadow: "none",
                    background: "#000",
                    color: "#fff",
                    width: "30px",
                    height: "30px",
                    minHeight: "20px",
                    "&:hover": { color: "#000" },
                  }}
                  onClick={() => setShowNewTag(true)}
                >
                  <AddIcon fontSize="small" sx={{ width: "18px" }} />
                </Fab>
              </Box>
            )}
          </Box>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            marginTop: "30px",
          }}
        >
        <StaticDropDown
          handleChange={(val: string | null) => setViewedBy(val)}
          value={viewedBy}
          showError={false}
          options={["Viewed by All", "User"]}
          placeHolder=""
          background="#f9f9f9"
          disableClear={true}
        />
        </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
            Cancel
          </Box>
          <ColorButton
            onClick={() => onButtonClick()}
            disabled={taskName === "" || email === ""}
          >
            {task ? "Update" : "Create"}
          </ColorButton>
        </Box>
      </Box>
    </BasicDraggableDialog>
  );
};
