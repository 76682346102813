import { useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { LinkedInOrganizationInfo, socialApi } from "../../api/socialApi";
import { useAppDispatch } from "../../app/hooks";
import { ReactComponent as LinkedInSvg } from "../../assets/social/linkedinRound.svg";
import { ReactComponent as WhiteCheckSVG } from "../../assets/whiteCheck.svg";
import { ReactComponent as NoPagesSvg } from "../../assets/social/noPageFound.svg";
import { ReactComponent as ReConnectSvg } from "../../assets/social/broken_link.svg";

import { getAccessTokenSilence } from "../../auth/auth";
import { ClientDomain, ClientUrl } from "../../authConfig";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import { BasicMessagePopup } from "../../Massages/BasicMessagePopup";
import { refreshActiveProject } from "../../projects/projectsSlice";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { SocialConnect } from "../Manager/SocialConnect";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import { SuccessMessagePopup } from "../../Massages/SuccessMessagePopup";
import { useTranslation } from "react-i18next";

export interface LinkedInConnectProps {
  isClientView: boolean;
  projectId: string;
  isConnected: boolean;
  organizationId?: string;
  onConneted?(): void;
}

export const LinkedInConnect: React.FC<LinkedInConnectProps> = ({
  organizationId,
  projectId,
  isConnected,
  isClientView,
  onConneted,
}) => {
  const [showOrganizations, setShowOrganizations] =
    React.useState<boolean>(false);
  const [onLoad, setOnLoad] = React.useState<boolean>(false);
  const [showDeleteMessagePopup, setShowDeleteMessagePopup] =
    React.useState<boolean>(false);
  const [showNoPagesMessagePopup, setShowNoPagesMessagePopup] = React.useState<boolean>(false);
  const [showReConnectMessage, setShowReConnectMessage] = React.useState<boolean>(false);
  
  const [organizations, setOrganizations] = React.useState<
    LinkedInOrganizationInfo[]
  >([]);
  const [organizationInfo, setOrganizationInfo] =
    React.useState<LinkedInOrganizationInfo>();

  const msalInstance = useMsal();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const getOrganizations = async (token: string) => {
    setOnLoad(true);
    const response = await socialApi.getLinkedInOrganizationsNames(
      projectId,
      token
    );
    if (response) {
      if (response.loginIssue) {
        setShowReConnectMessage(true)
      } else if (response.organizations.length === 0) {
        await disconnectFromLinkedIn()
        setShowNoPagesMessagePopup(true)
      } else {
        setOrganizations(response.organizations);
        if (organizationId) {
          const connectedOrg = response.organizations.find(
            (r) => r.id === organizationId
          );
          if (connectedOrg) {
            setOrganizationInfo(connectedOrg);
          }
        }
        setShowOrganizations(true);
      }
    }
    setOnLoad(false);
  };

  useAsyncEffect(async () => {
    if (searchParams && searchParams.get("connected") === "linkedIn") {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        await getOrganizations(token.accessToken);
      }
      searchParams.delete("connected");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const logInToLinkedIn = () => {
    const redirectUrl = `https://${ClientDomain}/auth/linkedin?projectId=${projectId}`;
    const clientId = "77qdsxz5uq0f2u";
    const stateCode = "123456";
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${stateCode}&scope=rw_organization_admin%20r_basicprofile%20r_emailaddress%20w_member_social%20r_organization_social%20w_organization_social`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const handleClose = () => {
    setShowOrganizations(false);
  };

  const disconnectFromLinkedIn = async () => {
    setShowDeleteMessagePopup(false);
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoad(true);
      await socialApi.disconnectFromLinkedIn(projectId, token.accessToken);
      setOrganizationInfo(undefined);
      dispatch(refreshActiveProject(token.accessToken));
      setOnLoad(false);
      handleClose();
    }
  };

  const selectOrg = async (org?: LinkedInOrganizationInfo) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      if (org) {
        await socialApi.updateLinkedInOrganization(
          org,
          projectId,
          token.accessToken
        );
        setOrganizationInfo(org);
        handleClose();
        if (onConneted) {
          onConneted();
        }
      }
    }
  };

  const onEditClick = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      getOrganizations(token.accessToken);
    }
  };

  const sendLinkToClient = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await socialApi.sendClientRequuestToConnectToSocial(
        projectId,
        "LinkedIn",
        `${ClientUrl}/social/collaborator/${projectId}`,
        token.accessToken
      );
    }
  };

  return (
    <>
      <SocialConnect
        isClientView={isClientView}
        isConnected={isConnected}
        onConnectClick={() => logInToLinkedIn()}
        onSendClientConnect={() => sendLinkToClient()}
        socialIcon={<LinkedInSvg />}
        bigSocialIcon={<LinkedInSvg />}
        onEditSocail={() => onEditClick()}
        socialName={"Linkedin"}
      />
      {showOrganizations && (
        <BasicMessagePopup onClose={handleClose} open={showOrganizations}>
          <Box sx={{ margin: "30px 70px 0px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
                fontWeight: 500,
                marginBottom: "60px",
              }}
            >
              Which page do you want to use with Lynxi?
            </Box>
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "auto",
                paddingRight: "20px",
              }}
            >
              {organizations.map(
                (page: LinkedInOrganizationInfo, pageIdx: number) => (
                  <Box
                    key={`facebook-page-${pageIdx}`}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "50px",
                    }}
                  >
                    <Box sx={{ fontSize: "16px" }}>
                      {page.name} ({page.id})
                    </Box>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => setOrganizationInfo(page)}
                      className={
                        organizationInfo === page ? "radioFull" : "radioEmpty"
                      }
                    >
                      {organizationInfo === page && <WhiteCheckSVG />}
                    </Box>
                  </Box>
                )
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{ cursor: "pointer", fontSize: "14px", fontWeight: 500, color:'#6c3dab' }}
                onClick={() => setShowDeleteMessagePopup(true)}
              >
                Disconnect from Linkedin
              </Box>
              <ColorButton
                onClick={() => selectOrg(organizationInfo)}
                disabled={!organizationInfo}
              >
                Connect
              </ColorButton>
            </Box>
          </Box>
        </BasicMessagePopup>
      )}
      <BasicPageBlocker show={onLoad} />
      {showDeleteMessagePopup && (
        <DeleteMessageDialog
          onClose={() => setShowDeleteMessagePopup(false)}
          onDelete={disconnectFromLinkedIn}
          title={`Are you sure?`}
          message={`Please note disconnecting from LinkedIn will remove all your posts from Lynxi`}
        />
      )}
       {showNoPagesMessagePopup && (
          <SuccessMessagePopup
            open={showNoPagesMessagePopup}
            icon={<NoPagesSvg />}
            message={t("social.no_pages_linkedin.message")}
            title={t("social.no_pages_linkedin.title")}
            onClose={() => setShowNoPagesMessagePopup(false)}
            onContinue={() => setShowNoPagesMessagePopup(false)}
            continueAction={t("social.no_pages_linkedin.action")}
            showAction={true}
            size={"compact"}
          />
        )}
        {showReConnectMessage && (
          <SuccessMessagePopup
            open={showReConnectMessage}
            icon={<ReConnectSvg />}
            message={t("social.reconnect_linkedin.message")}
            title={t("social.reconnect_linkedin.title")}
            onClose={() => setShowReConnectMessage(false)}
            onContinue={() => logInToLinkedIn()}
            continueAction={t("social.reconnect_linkedin.action")}
            showAction={true}
            size={"compact"}
          />
        )}
    </>
  );
};
