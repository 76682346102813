
import * as React from "react";
import {
  Box,
  styled,
  Button,
  ButtonProps,
  Switch,
  alpha,
  Checkbox,
  SwitchProps,
  Tooltip,
} from "@mui/material";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


import { ReactComponent as RocketInProgresSVG } from "../assets/project/rocket/rocket_in_progress.svg"
import { ReactComponent as RocketInCanceledSVG } from "../assets/project/rocket/rocket_canceled.svg"
import { ReactComponent as RocketInCompleteSVG } from "../assets/project/rocket/rocket_complete.svg"
import { ReactComponent as RocketInOnHoldSVG } from "../assets/project/rocket/rocket_on_hold.svg"
import { ReactComponent as RocketInPendingSVG } from "../assets/project/rocket/rocket_pending.svg"

export function TextWithTooltip({
  text,
  maxWidth,
}: {
  text: string;
  maxWidth: string | number;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isTextTrimmed, setIsTextTrimmed] = React.useState(false);

  React.useEffect(() => {
    const container = containerRef.current;

    if (container && container.scrollWidth > container.clientWidth) {
      // Text has been truncated
      setIsTextTrimmed(true);
    } else {
      setIsTextTrimmed(false);
    }
  }, [text, maxWidth]);

  return (
    <Tooltip title={isTextTrimmed ? text : ""}>
      <div
        style={{
          maxWidth: maxWidth, // Set your desired max width, e.g., '200px'
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        ref={containerRef}
      >
        {text}
      </div>
    </Tooltip>
  );
}

export const TableHeader = styled(Box)(() => ({
  alignItems: "center",
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: "500",
}));

export const TableCell = styled(Box)(() => ({
  alignItems: "center",
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: "300",
  height:'100%'
}));


export const EmptyCheck = styled(Box)(() => ({
  width: "25px !important",
  height: "25px  !important",
  boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
  backgroundColor: "#fff",
}));

export const FullCheck = styled(Box)(() => ({
  width: "25px  !important",
  height: "25px  !important",
	cursor: 'pointer',
  boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
  backgroundImage: "linear-gradient(159deg, #fb8332 0%, #f8c332 112%)",
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
}));

export const CustomCheckBox = styled(Checkbox)(({ disabled }) => ({
  "&.Mui-checked": {
    color: '#fb8332',
  },
  '& .MuiSvgIcon-root': { fontSize: 28 } ,
  color: '#c4c4c4',
  strokeWidth: '1px',
  opacity: disabled ? 0.3 : 1,
  stroke: "#F5F8F7",
}));

export const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fb8332",
    "&:hover": {
      backgroundColor: alpha("#fb8332", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundImage: "linear-gradient(148deg, #fb8332 8%, #f8c332 100%)",
  },
}));

interface CustomButtonProps extends ButtonProps {
  size?: 'small' | 'medium' | 'large'; // Example of an extra size prop
}

export const ColorButton = styled(Button)<CustomButtonProps>(({ disabled, size }) => ({
  color: "#fff !important",
  padding: size === 'small'? "6px 15px 6px 15px": "6px 32px 6px 31px",
  backgroundImage: disabled ? "linear-gradient(131deg, #ababab 16%, #ababab 137%)" : "linear-gradient(131deg, #fb8332 16%, #f8c332 137%)",
  fontFamily: "Nunito",
  fontWeight: 600,
  textTransform: "none",
  borderRadius: "22px",
  fontSize: size === 'small'? '12px': '14px',
  opacity: disabled ? 0.5 : 1,
  ':hover': {
    backgroundImage: 'linear-gradient(131deg, #f8c332 16%, #fb8332 137%)'
  },
  lineHeight: 1.3
}));

export const PurpleButton = styled(Button)<ButtonProps>(({ disabled, size }) => ({
  color: "#fff !important",
  padding: size === 'small'? "6px 15px 6px 15px" : "6px 22px 6px 21px",
  backgroundImage: disabled ? "linear-gradient(131deg, rgba(135,128,128,1) 16%, rgba(61,58,58,1) 137%)" : "linear-gradient(127deg, #3023ae -47%, #c86dd7 103%)",
  fontFamily: "Nunito",
  fontWeight: 600,
  margin: '2px',
  textTransform: "none",
  borderRadius: "16px",
  fontSize: size === 'small'? '12px':'14px',
  opacity: disabled ? 0.5 : 1,
  cursor: 'pointer',
  lineHeight: 1.3
}));

export const ColorButtonOutLined = styled(Button)<ButtonProps>(() => ({
  padding: "6px 32px 6px 31px",
  borderImageSource: 'linear-gradient(122deg, #fb8332 21%, #f8c332 82%)',
  borderImageSlice: 1,
  fontFamily: "Nunito",
  fontWeight: 600,
  textTransform: "none",
  border: '1px solid #fb8332',
  borderRadius: "22px !important",
  fontSize: '14px',
  opacity: 1,
  ':disabled': {
    color: 'rgba(0, 0, 0, 0.36)',
    border: '1px solid rgba(0, 0, 0, 0.22)'
  },
  lineHeight: 1.3
}));

export const ColorButtonPurpleOutLined = styled(Button)<ButtonProps>(() => ({
  padding: "6px 15px 6px 15px",
  fontFamily: "Nunito",
  fontWeight: 600,
  margin: '2px',
  // height: "32px",
  textTransform: "none",
  outline: '1px solid #c86dd7',
  borderRadius: "22px !important",
  fontSize: '14px',
  opacity: 1,
  ':disabled': {
    color: 'rgba(0, 0, 0, 0.36)',
    outline: '1px solid rgba(0, 0, 0, 0.22)'
  } ,
  lineHeight: 1.3
}));

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundImage: 'linear-gradient(159deg, #fb8332 0%, #f8c332 112%)',
        // backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#fb8332',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    // backgroundImage: 'linear-gradient(159deg, #fb8332 0%, #f8c332 112%)',
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#ecdbf5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#aa5ecf',
  },
}));


// export const TextButton = styled(Button)<ButtonProps>(({ disabled }) => ({
//   fontFamily: "Nunito",
//   fontWeight: 600,
//   textTransform: "none",
//   padding: "4px 32px 4px 31px",
//   cursor: 'pointer',
//   fontSize: '14px',
//   opacity: disabled ? 0.8 : 1,
//   '&:hover': {
//     color: "#fff !important",
//     borderRadius: "22px",
//     backgroundImage: "linear-gradient(131deg, #fb8332 16%, #f8c332 137%)",
//   },
//   ':disabled': {
//     color: 'rgba(0, 0, 0, 0.6) !important',
//     cursor: 'none'
//   }
// }));

export const TextButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  fontFamily: "Nunito",
  fontWeight: 600,
  textTransform: "none",
  textDecoration: "none",
  padding: "4px 10px 4px 10px",
  cursor: 'pointer',
  fontSize: '14px',
  opacity: disabled ? 0.8 : 1,
  '&:hover': {
    textDecoration: 'underline',
    background: 'inherit'
  },
  ':disabled': {
    color: 'rgba(0, 0, 0, 0.6) !important',
    cursor: 'none'
  }
}));

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}



export const breakTextToLines = (text: string) => {
    const lines = text.split('\n')
    return  lines.map( (l,idx) => {
      return (
        <span key={`line-${idx}`}>{l}{idx< lines.length? (<br/>):null}</span>
      )
    })
}



export const getProjectSvgRocket = (status: string) => {
    switch (status.toLowerCase()) {
      case "in progress":
        return  <RocketInProgresSVG />
        case "completed":
          return  <RocketInCompleteSVG />
        case "pending":
          return <RocketInPendingSVG/>
        case "canceled":
          return <RocketInCanceledSVG />
        case "on hold":
          return <RocketInOnHoldSVG />
    }
  };
