import { useMsal } from "@azure/msal-react";
import * as React from "react";
import useAsyncEffect from "use-async-effect";
import { useTranslation } from "react-i18next";
import { Box, Modal } from "@mui/material";
import { getAccessTokenSilence } from "../../../auth/auth";
import { SecondStageMsg } from "../../../cms/AIManager/SecondStageMsg";
import { adminApi } from "../../../api/adminApi";
import { ReactComponent as FailedSvg } from "../../../assets/project/shareMailFail.svg";
import { ColorButton } from "../../../projects/ProjectUIHelper";

export interface AiDialogForPostProps {
  startAiProcess: boolean;
  onFinish(postText: string, aiFailed?: boolean): void;
  helpParagraph: string;
  audiance: string;
  projectId: string;
  overRideTitle: string;
  isPerfectText?: boolean;
  timeOut?: number;
}

const AiDialogForPost: React.FC<AiDialogForPostProps> = ({
  startAiProcess,
  helpParagraph,
  onFinish,
  audiance,
  projectId,
  overRideTitle,
  isPerfectText,
  timeOut,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [aiRunFinished, setAiRunFinished] = React.useState<boolean>(false);
  const [timerIsOn, setTimerIsOn] = React.useState<boolean>(false);
  const [startCounting, setStartCounting] = React.useState<boolean>(false);
  const [postText, setPostText] = React.useState<string>("");
  const { t } = useTranslation();
  const msalInstance = useMsal();
  const [aiFailed, setAiFailed] = React.useState<boolean>(false);
  const [showAiFailedMessage, setShowAiFailedMessage] =
    React.useState<boolean>(false);

  useAsyncEffect(async () => {
    if (startAiProcess) {
      checkAiStatus();
    }
  }, [startAiProcess]);

  const checkAiStatus = async () => {
    setAiRunFinished(false);
    setStartCounting(false);
    setTimerIsOn(false);
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOpen(true);
      setStartCounting(true);
      const aiRequest = {
        id: isPerfectText ? "997" : "998",
        data: { BODY: helpParagraph, AUDIENCE: audiance },
        cmsIds: [isPerfectText ? "997" : "998"],
      };
      const aiResponse = await adminApi.processAiTemaplate(
        projectId,
        aiRequest,
        token.accessToken
      );
      if (aiResponse && aiResponse.length > 0) {
        setPostText(aiResponse.join(" ").trim());
        setAiRunFinished(true);
      } else if (!aiResponse) {
        setAiFailed(true);
      } else {
        setPostText("");
        setAiRunFinished(true);
      }
    }
  };

  const onClose = () => {
    if (aiFailed) {
      setShowAiFailedMessage(true);
    } else {
      setOpen(false);
      onFinish(postText, aiFailed);
    }
  };

  const onCloseAfterFail = () => {
    setOpen(false);
    onFinish(postText, aiFailed);
  };

  useAsyncEffect(async () => {
    if (startCounting) {
      setTimerIsOn(true);
      setTimeout(
        () => {
          setTimerIsOn(false);
        },
        timeOut ? timeOut : 8000
      );
    }
  }, [startCounting]);

  useAsyncEffect(async () => {
    if (!timerIsOn && (aiRunFinished || aiFailed)) {
      onClose();
    }
  }, [timerIsOn]);

  useAsyncEffect(async () => {
    if ((aiRunFinished || aiFailed) && !timerIsOn) {
      onClose();
    }
  }, [aiRunFinished, aiFailed]);

  return (
    <>
      <Modal open={open} closeAfterTransition sx={{ zIndex: 4000 }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            outline: "none",
            width: "600px",
          }}
        >
          <Box
            sx={{
              minHeight: "340px",
              width: "100%",
              bgcolor: "#f5f8f7",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "30px",
            }}
          >
            {showAiFailedMessage ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  fontFamily: "Nunito",
                  justifyContent: "center",
                  width: "60%",
                  marginTop: "40px",
                  paddingBottom: "20px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <FailedSvg />
                </Box>
                <Box sx={{ fontWeight: 500, marginTop: "32px" }}>
                  {t("cms.progress_popup.ai_failed.full_message")}
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                    alignSelf: "center",
                    justifyContent: "center",
                    color: "#6c3dab",
                    padding: "35px 0px 30px",
                  }}
                  onClick={() => onClose()}
                >
                  <ColorButton onClick={() => onCloseAfterFail()}>
                    Continue
                  </ColorButton>
                </Box>
              </Box>
            ) : (
              <SecondStageMsg overRideTitle={overRideTitle} />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AiDialogForPost;
