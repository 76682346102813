import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Box,
} from "@mui/material";
import * as React from "react";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../cms.scss";
import {
  CMSListComponent,
  ListValues,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
  updateCmsHiddenValues,
} from "../cmsSlice";
import { ServiceComp } from "./ServiceComp";
import { cloneDeep } from "lodash";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { DeleteConfirmationPopup } from "../../PopUpMessage/DeleteProjectPopup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { TextWithTooltip } from "../../projects/ProjectUIHelper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";

export interface ServiceListCompProps {
  comp: CMSListComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
}

export const ServiceListComp: React.FC<ServiceListCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [indexToDelete, setIndexToDelete] = React.useState<number>();
  const [fakeNumber, setFakeNumber] = React.useState<number>(0);
  const cmsState = useAppSelector((state: RootState) => state.cmsData);
  const [expanded, setExpanded] = React.useState<number | false>(0);
  const dispatch = useAppDispatch();

  const onUpdateList = (item: ListValues, index: number) => {
    const deepClone = cloneDeep(comp);
    deepClone.value[index] = item;
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const addNewService = () => {
    const deepClone = cloneDeep(comp);
    if (comp.labels?.length === 5) {
      deepClone.value = [
        ...comp.value,
        { name: "", title: "", subTitle: "", paragraph: "", image: "" },
      ];
    } else {
      deepClone.value = [
        ...comp.value,
        { title: "", subTitle: "", paragraph: "", image: "" },
      ];
    }
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const onDeleteItem = (anchor: HTMLDivElement, index: number) => {
    setAnchorEl(anchor);
    setIndexToDelete(index);
  };

  const onHideItem = (index: number, show: boolean) => {
    const deepClone = cloneDeep(comp);
    deepClone.value[index].isHidden = show;
    // const hideList = _.get(cmsState.hiddenList, comp.mapKey, []);
    // dispatch(
    //   updateCmsHiddenValues({
    //     key: comp.mapKey,
    //     value: hideList.includes(index)
    //       ? hideList.filter((item: number) => item !== index)
    //       : [...hideList, index],
    //   })
    // );
    onUpdateComp(UpdateCompBuilder(location, deepClone));
    setTimeout(() => {
      setFakeNumber(fakeNumber + 1);
    }, 200);
  };

  const onCancelDelete = () => {
    setIndexToDelete(undefined);
    setAnchorEl(null);
  };

  const onDeleteApproved = async () => {
    if (indexToDelete !== undefined) {
      const deepClone = cloneDeep(comp);
      deepClone.value.splice(indexToDelete, 1);
      onUpdateComp(UpdateCompBuilder(location, deepClone));
      setAnchorEl(null);
      setIndexToDelete(undefined);
      setTimeout(() => {
        setFakeNumber(fakeNumber + 1);
      }, 200);
    }
  };
  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div style={{ width: "100%", marginTop: "25px" }} className={"serviceList"}>
      {cmsState.sideBarMode ? (
        <Box sx={{ marginLeft: "15px" }}>
          {comp.value.map((item, idx) => (
            <Accordion
              expanded={expanded === idx}
              onChange={handleChange(idx)}
              key={`service-${idx}-${fakeNumber}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, .03)",
                  minHeight: "auto !important",
                  "& .MuiAccordionSummary-content": {
                    margin: "12px 0px !important",
                  },
                }}
              >
                <TextWithTooltip
                  text={`${comp.description} ${idx + 1}`}
                  maxWidth={"70%"}
                />
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <ServiceComp
                  key={`service-${idx}-${fakeNumber}`}
                  item={item}
                  index={idx}
                  labels={comp.labels}
                  desctiption={comp.description}
                  updateList={onUpdateList}
                  readOnly={readonly}
                  isAi={comp.isAI}
                  showContentPopup={comp.showContentPopup}
                  compMapKey={comp.mapKey}
                  hideList={[
                    ...(comp.hide ?? []),
                    ..._.get(cmsState.hiddenList, comp.mapKey, []),
                  ]}
                  onDeleteItem={onDeleteItem}
                  onHideItem={onHideItem}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ) : (
        comp.value.map((item, idx) => (
          <ServiceComp
            key={`service-${idx}-${fakeNumber}`}
            item={item}
            index={idx}
            labels={comp.labels}
            desctiption={comp.description}
            updateList={onUpdateList}
            readOnly={readonly}
            isAi={comp.isAI}
            showContentPopup={comp.showContentPopup}
            compMapKey={comp.mapKey}
            hideList={comp.hide ?? []}
            onDeleteItem={onDeleteItem}
            onHideItem={onHideItem}
          />
        ))
      )}
      {!readonly && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginRight: "12px",
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Add new
          </Box>
          <Box sx={{marginTop:'10px'}}>
            <HelpToolTipOnHover
              position="bottom-end"
              message={comp.action}
              actionNode={
                <Fab
                  size="small"
                  sx={{
                    backgroundImage:
                      "linear-gradient(143deg, #3023ae -60%, #c86dd7 102%)",
                    color: "#fff",
                    "&:hover": { color: "#000" },
                    width: "35px",
                    height: "35px",
                  }}
                  onClick={() => addNewService()}
                >
                  <AddIcon fontSize="medium" sx={{ width: "18px" }} />
                </Fab>
              }
            ></HelpToolTipOnHover>
          </Box>
        </Box>
      )}
      <DeleteConfirmationPopup
        title={`Are you sure you want to delete this item ?`}
        description={""}
        placement="left"
        onDeleteSite={() => onDeleteApproved()}
        onClose={() => onCancelDelete()}
        anchorEl={anchorEl}
        autoClose={true}
      />
    </div>
  );
};
