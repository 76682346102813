import React from "react";
import { BasicMessagePopup } from "../../Massages/BasicMessagePopup";
import { Box } from "@mui/material";
import { TaskInfo, TaskSavedList } from "../../api/tasksApi";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { ReactComponent as CheckedBoxSVG } from "../../assets/whiteCheck.svg";
import "../task.scss";
import { useAccountSettingsData } from "../../hooks/useAccountTasksTemplates";
import { DesignerContext } from "../../routes/AppRouter";
import { getAccessTokenSilence } from "../../auth/auth";
import { useMsal } from "@azure/msal-react";
import { backofficeApi } from "../../api/backofficeApi";


interface ImportTaskPopupProps {
  onClose(): void;
  onSelectedImportList(taskList: TaskInfo[]): void;
}

const ImportTaskPopup: React.FC<ImportTaskPopupProps> = ({
  onClose,
  onSelectedImportList,
}) => {
  // Define component logic here
  const [taskImportLists, setTaskImportLists] = React.useState<
    TaskSavedList[]
  >([]);
  const [selectedTaskList, setSelectedTaskList] =
    React.useState<TaskSavedList>();
  const { getTaskTemplateList } = useAccountSettingsData();
  const designerContext = React.useContext(DesignerContext);
  const msalInstance = useMsal();


  const onSelectClick = () => {
    if (selectedTaskList) {
      onSelectedImportList(selectedTaskList.tasks.map((t) =>
            ({ name: t.name,
              assignedTo: t.toMe? designerContext.email :'',
              createdBy: designerContext.email,
              id: "",
              status: "Not Started",
              tag: t.tag,
              priority: t.priority})
          )
        )
    }
  };

  const getTaskLists = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const taskListToken = await backofficeApi.getParamFromServer("tasklist", token.accessToken);
      const settingsObj = JSON.parse(taskListToken.value);
      setTaskImportLists([...getTaskTemplateList(), ...settingsObj])
    }
  };

  React.useEffect(() => {
    getTaskLists();
  }, []);

  return (
    <BasicMessagePopup
      extraWidth="750px"
      onClose={onClose}
      open={true}
      disableAutoClose={true}
    >
      <Box sx={{ margin: "20px 70px 0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: 600,
            marginBottom: "60px",
          }}
        >
          Choose a saved checklist that you would like to import
        </Box>
        <Box
          sx={{
            maxHeight: "50vh",
            overflowY: "auto",
            paddingRight: "20px",
          }}
        >
          {taskImportLists.map((taskList: TaskSavedList, index: number) => (
            <Box
              key={`import-list-${index}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
            >
              <Box sx={{ fontSize: "16px" }}>{`${taskList.name} (${taskList.tasks.length} tasks)`}</Box>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => setSelectedTaskList(taskList)}
                className={
                  selectedTaskList === taskList ? "radioFull" : "radioEmpty"
                }
              >
                {selectedTaskList === taskList && <CheckedBoxSVG />}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={onClose}>
            Cancel
          </Box>
          <ColorButton
            onClick={() => onSelectClick()}
            disabled={!selectedTaskList}
          >
            Select
          </ColorButton>
        </Box>
      </Box>
    </BasicMessagePopup>
  );
};

export default ImportTaskPopup;
