import { Box, Fab, Grid, Tab, Tabs, Tooltip } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { CMSFieldCopyBtn } from "../../basic/CMSFieldCopyBtn";
import TreeUtils from "../../utils/TreeUtils";
import "../cms.scss";
import {
  CMSComponent,
  CMSState,
  PositionIndex,
  setTypesDics,
  updateCmsItemsUndoData,
  updateCmsValues,
  UpdateComp,
} from "../cmsSlice";
import { SingleCMSSvg } from "../SingleCMSSvg";
import { ContentHubSocialConnectedFields } from "../../utils/Constants";
import {
  AvaliableSocial,
  LynxiInfo,
  SocialItemPostData,
} from "../../Social/Posts/PostUIHelper";
import AiDialogForPost from "../../Social/Posts/NewPostSwitcher/AiDialogForPost";
import { ProjectState } from "../../projects/projectsSlice";
import parse from "html-react-parser";
import { BasicRichEditorCMS } from "../../basic/BasicRichEditor/BasicRichEditorCMS";
import RestoreIcon from "@mui/icons-material/Restore";
import { ColorButton } from "../../projects/ProjectUIHelper";
import _ from "lodash";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";

export interface ParagraphCompProps {
  comp: CMSComponent;
  padding: number;
  location: PositionIndex;
  forceUpdate: number;
  onUpdateComp(updateComp: UpdateComp): void;
  onShowContentPopupDialog?(): void;
  readonly?: boolean;
  showContentPopup?: boolean;
}

export const ParagraphComp: React.FC<ParagraphCompProps> = ({
  comp,
  padding,
  location,
  forceUpdate,
  onUpdateComp,
  onShowContentPopupDialog,
  readonly,
  showContentPopup,
}) => {
  const [currentSocialTab, setCurrentSocialTab] = useState<number>(0);
  const [onProcessingAi, setOnProcessingAi] = React.useState<boolean>(false);

  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [socialTabs, setSocialTabs] = useState<SocialItemPostData[]>([]);
  const [polishUpUsed, setPolishUpUsed] = useState<boolean>(false);

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  const projectsState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  const hasConnectedSocial = () => {
    if (projectsState.activeProject && projectsState.activeProject.social) {
      return (
        projectsState.activeProject.social.facebook.connected &&
        projectsState.activeProject.social.facebook.id
      );
    } else {
      return false;
    }
  };

  const dispatch = useAppDispatch();

  const updateCompData = (htmlText: string, forceUpdate?: boolean) => {
    if (currentSocialTab > 0) {
      const cmsSocial = get(cmsState.cmsValuesByTypes, "social", {});
      const cmsSocialKeyValues = get(cmsSocial, comp.mapKey, {});
      const currentSocial = { ...cmsSocialKeyValues, facebook: htmlText };
      const updatedSocial: { [id: string]: any } = {
        ...cmsSocial,
        [comp.mapKey]: currentSocial,
      };
      if (cmsState.cmsValuesByTypes) {
        dispatch(
          setTypesDics({
            ...cmsState.cmsValuesByTypes,
            social: updatedSocial,
          })
        );
      }
    } else {
      const updateComp = { ...comp, value: htmlText, forceUpdate };
      onUpdateComp({ location, comp: updateComp, forceRender: forceUpdate });
    }
  };

  useAsyncEffect(async () => {
    const hasSocial = ContentHubSocialConnectedFields.find(
      (social: any) => social.cmsKey === comp.mapKey
    );
    if (hasSocial && hasConnectedSocial()) {
      const socialsDataList = [
        LynxiInfo,
        ...AvaliableSocial.filter(
          (social) => hasSocial.social.indexOf(social.title) > -1
        ),
      ];
      setSocialTabs(socialsDataList);
    }
  }, []);

  const getTextForAi = () => {
    // const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    return TreeUtils.htmlToText(comp.value);
  };

  const paragraphCompIsEmpty = () => {
    // const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    return comp.value === undefined || TreeUtils.htmlToText(comp.value) === "";
  };

  const onAiFinish = (postText: string, aiFailed?: boolean) => {
    setOnProcessingAi(false);
    if (postText !== "") {
      //setPolishUpUsed(true);
      if (cmsState.cmsValuesByTypes) {
        const updateDic = { ...cmsState.cmsValuesByTypes.expert };
        updateDic[comp.mapKey] = comp.value;
        dispatch(
          setTypesDics({
            ...cmsState.cmsValuesByTypes,
            expert: { ...updateDic },
          })
        );
      }
      updateCompData(postText, true);
      // updateEditorValue(postText);
    }
  };

  const redoIsEmpty = () => {
    const currentItemUndoData = _.get(cmsState.cmsItemsUndoData, comp.mapKey);
    return !currentItemUndoData || _.isEmpty(currentItemUndoData.future);
  };

  const undoIsEmpty = () => {
    const currentItemUndoData = _.get(cmsState.cmsItemsUndoData, comp.mapKey);
    return !currentItemUndoData || _.isEmpty(currentItemUndoData.past);
  };

  const undo = () => {
    const currentItemUndoData = _.get(cmsState.cmsItemsUndoData, comp.mapKey);
    if (currentItemUndoData) {
      const previous =
        currentItemUndoData.past[currentItemUndoData.past.length - 1];
      const newPast = currentItemUndoData.past.slice(
        0,
        currentItemUndoData.past.length - 1
      );
      dispatch(
        updateCmsItemsUndoData({
          key: comp.mapKey,
          value: {
            past: newPast,
            present: previous,
            future: [
              currentItemUndoData.present,
              ...currentItemUndoData.future,
            ],
          },
        })
      );
      dispatch(
        updateCmsValues({
          key: comp.mapKey,
          value: previous,
          forceRender: true,
        })
      );
    }
  };

  const redo = () => {
    const currentItemUndoData = _.get(cmsState.cmsItemsUndoData, comp.mapKey);
    if (currentItemUndoData) {
      const next = currentItemUndoData.future[0];
      const newFuture = currentItemUndoData.future.slice(1);
      dispatch(
        updateCmsItemsUndoData({
          key: comp.mapKey,
          value: {
            past: [...currentItemUndoData.past, currentItemUndoData.present],
            present: next,
            future: newFuture,
          },
        })
      );
      dispatch(
        updateCmsValues({
          key: comp.mapKey,
          value: next,
          forceRender: true,
        })
      );
    }
  };

  const onChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentSocialTab(newValue);
    if (newValue > 0) {
      const cmsSocialValues = get(
        get(cmsState.cmsValuesByTypes, "social", {}),
        comp.mapKey,
        {}
      );
      const currentSocial = get(
        cmsSocialValues,
        socialTabs[newValue].title.toLowerCase(),
        ""
      );
      const cleanSocialText = TreeUtils.htmlToText(currentSocial);
      // updateEditorValue(cleanSocialText !== "" ? currentSocial : undefined);
    } else {
      // updateEditorValue();
    }
  };

  const renderSocialTabs = () => {
    if (socialTabs.length === 0 || readonly) {
      return null;
    }
    return (
      <Box>
        <Tabs
          value={currentSocialTab}
          onChange={onChangeTab}
          aria-label="icon position tabs example"
          sx={{
            display: "flex",
            minHeight: "30px !important",
            "& button": {
              boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.08)",
              borderTopLeftRadius: "7.2px",
              borderTopRightRadius: "7.2px",
              minHeight: "20px !important",
              marginLeft: "10px",
              minWidth: "40px !important",
              padding: "5px 0px",
              textTransform: "none",
            },
            "& .Mui-selected ": { background: "#f9f9f9 !important" },
            "& .MuiTabs-indicator": { display: "none" },
          }}
        >
          {socialTabs.map((tab, idx) => (
            <Tab
              key={`social-tab=${idx}`}
              icon={
                <img
                  width="20px"
                  src={`/images/social/${tab.title.toLowerCase()}_${
                    currentSocialTab === idx ? "on" : "off"
                  }.svg`}
                />
              }
              iconPosition="start"
            />
          ))}
        </Tabs>
      </Box>
    );
  };

  return (
    <Grid item xs={padding} sx={{ position: "relative", display: "flex" }}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
        id={`compKey-${comp.mapKey}`}
        // onMouseLeave={() => callUpdateComp()}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box
            style={{
              marginTop: "6px",
              fontFamily: "Nunito",
              fontWeight: "500",
              paddingBottom: "6px",
              fontSize: "14px",
            }}
            className={"compDescription"}
          >
            <div>{parse(comp.description)}</div>
          </Box>
          {renderSocialTabs()}
        </Box>

        <BasicRichEditorCMS
          cmsKey={comp.mapKey}
          value={comp.value}
          onUpdate={(value: string) => updateCompData(value)}
          readonly={readonly}
          placeHolder={comp.placeHolder}
          forceRender={forceUpdate}
        />

        {/* <Editor
          onFocus={() => {
            setHasFocus(true);
          }}
          readOnly={readonly}
          onBlur={() => setHasFocus(false)} //callUpdateComp()}
          placeholder={`${comp.placeHolder}`}
          wrapperClassName={"paragrpah-wrapper"}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          editorClassName={classnames(
            "paragraphEditor",
            comp.type === "paragraph" ? "parEditor" : "",
            hasFocus ? "EditorFocus" : ""
          )}
          toolbarClassName="paragrpah-editor-toolbar"
          toolbar={{
            options: ["inline"],
            inline: {
              inDropdown: false,
              className: readonly ? "boldtexthidden" : "boldtext",
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
              bold: { className: "iconButton" },
              italic: { className: "iconButton" },
              underline: { className: "iconButton" },
            },
          }}
        /> */}
        {!readonly && !cmsState.isClientMode && (
          <Box
            sx={
              cmsState.sideBarMode
                ? {
                    display: "flex",
                    cursor: "pointer",
                    color: "#6c3dab",
                    fontSize: "14px",
                    alignItems: "center",
                    marginTop: "10px",
                    justifyContent: "flex-end",
                  }
                : {
                    display: "flex",
                    position: "absolute",
                    bottom: "0px",
                    cursor: "pointer",
                    color: "#6c3dab",
                    alignItems: "center",
                    right: "0px",
                    fontSize: "14px",
                  }
            }
          >
            <Box sx={{ display: "flex", gap: "2px" }}>
              <Fab
                size="small"
                sx={{
                  background: "#fff",
                  color: "#6c3dab",
                  cursor: "pointer",
                  "&:hover": { background: "#e0e0e0" },
                }}
                style={{ transform: "scale(0.7)" }}
                aria-label="redo"
                onClick={() => {
                  redo();
                }}
                disabled={redoIsEmpty()}
              >
                <RedoIcon width="20px" />
              </Fab>
              <Fab
                size="small"
                sx={{
                  background: "#fff",
                  color: "#6c3dab",
                  cursor: "pointer",
                  "&:hover": { background: "#e0e0e0" },
                }}
                style={{ transform: "scale(0.7)" }}
                aria-label="undo"
                disabled={undoIsEmpty()}
                onClick={() => {
                  undo();
                }}
              >
                <UndoIcon width="20px" />
              </Fab>
            </Box>
            <Tooltip
              title={"Let Lynxi AI improve your text"}
              componentsProps={{
                tooltip: {
                  sx: {
                    background: "#292B28",
                    "& .MuiTooltip-arrow": {
                      color: "#292B28",
                    },
                  },
                },
              }}
              arrow={true}
              placement={"top"}
            >
              <Box
                sx={{
                  padding: "0px 10px",
                  fontWeight: paragraphCompIsEmpty() ? "400" : "800",
                  color: paragraphCompIsEmpty() ? "#878787" : "#6c3dab",
                }}
                onClick={() =>
                  paragraphCompIsEmpty() ? {} : setOnProcessingAi(true)
                }
              >
                Polish up
              </Box>
            </Tooltip>
            {polishUpUsed && <RestoreIcon />}
          </Box>
        )}
      </Box>

      {!readonly &&
        !cmsState.isClientMode &&
        showContentPopup &&
        onShowContentPopupDialog && (
          <Box
            onClick={onShowContentPopupDialog}
            sx={{
              position: "absolute",
              bottom: "-2px",
              width: "32px",
              cursor: "pointer",
            }}
          >
            <SingleCMSSvg name="cmsContentPopupSmall" />
          </Box>
        )}
      <CMSFieldCopyBtn value={TreeUtils.htmlToText(comp.value)} isPara={true} />
      <AiDialogForPost
        projectId={projectsState.activeProject?.id ?? ""}
        helpParagraph={getTextForAi()}
        audiance={""}
        startAiProcess={onProcessingAi}
        onFinish={onAiFinish}
        isPerfectText={true}
        timeOut={5000}
        overRideTitle={
          "Lynxi AI is polishing up your text and making it even better..."
        }
      />
    </Grid>
  );
};
