import { useMsal } from "@azure/msal-react";
import AddIcon from "@mui/icons-material/Add";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Fab,
  Grid,
} from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { Project } from "../projects/projectsSlice";
import { getAccessTokenSilence } from "../auth/auth";
import { TableHeader } from "../projects/ProjectUIHelper";
import { DeleteConfirmationPopup } from "../PopUpMessage/DeleteProjectPopup";
import { ReactComponent as SaveTaskListSVG } from "../assets/tasks/save.svg";
import { ReactComponent as ImportTaskListSVG } from "../assets/tasks/importTaskList.svg";
import { ReactComponent as SaveSvg } from "../assets/brief/saveBrief.svg";

import { HelpToolTipOnHover } from "../basic/HelpToolTipOnHover";
import { TaskInfo, TaskStatus, tasksApi } from "../api/tasksApi";
import { EmptyTaskList } from "./EmptyTaskList";
import { TaskLine } from "./TaskLine";
import { FilesCommentsManager } from "../FileSharing/FilesCommentsManager";
import { NewTaskForm } from "./NewTaskForm/NewTaskForm";
import ImportTaskPopup from "./ImportTaskPopup/ImportTaskPopup";
import SaveTaskListPopup from "./SaveTaskListPopup/SaveTaskListPopup";
import _ from "lodash";
import { AccountMember } from "../api/backofficeApi";
import { FoldersList } from "../basic/FoldersList";
import { useAccountSettingsData } from "../hooks/useAccountTasksTemplates";
import { DeleteMessageDialog } from "../Massages/DeleteMessageDialog";
import { BasicMessagePopup } from "../Massages/BasicMessagePopup";
import { ReminderHandle } from "../projects/ProjectBriefs/BriefStageStatus/ReminderHandle";
import BasicStaticDropDown from "../basic/BasicStaticDropDown";
import { TaskSearch } from "./TaskSearch/TaskSearch";

const DefaultTags = [
  "Desktop",
  "Mobile",
  "SEO",
  "Accessibility",
  "Function Tests",
  "General",
];

export interface TaskManagerViewProps {
  project: Project;
  userEmail: string;
  isClient?: boolean;
  coWorkers?: AccountMember[];
  designerEmail?: string;
}

export const TaskManagerView: FC<TaskManagerViewProps> = ({
  project,
  userEmail,
  isClient,
  coWorkers,
  designerEmail,
}) => {
  const [showManager, setShowManager] = useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<TaskInfo>();
  const [taskToEdit, setTaskToEdit] = useState<TaskInfo>();
  const [tasksList, setTasksList] = useState<TaskInfo[]>([]);
  const [avaliableTags, setAvaliableTags] = useState<string[]>(DefaultTags);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [commentAnchorEl, setCommentAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const [usedTags, setUsedTags] = useState<string[]>([]);
  const [showSaveTaskListDialog, setShowSaveTaskListDialog] =
    useState<boolean>(false);
  const [showAddNewTaskDialog, setShowAddNewTaskDialog] =
    useState<boolean>(false);
  const [showImportTaskListPopup, setShowImportTaskListPopup] =
    useState<boolean>(false);
  const [saveListAnchorEl, setSaveListAnchorEl] =
    React.useState<SVGSVGElement | null>(null);
  const [importAnchorEl, setImportAnchorEl] =
    React.useState<SVGSVGElement | null>(null);

  const [searchInTasks, setSearchInTasks] = useState<string>("");

  const [taskForComments, setTaskForComments] = useState<TaskInfo | undefined>(
    undefined
  );
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const [reminderResponse, setReminderResponse] = useState<
    boolean | undefined
  >();
  const [onProcessing, setOnProcessing] = useState<boolean>(false);
  const { t } = useTranslation();
  const msalInstance = useMsal();
  const { addTaksTemplatesList } =
    useAccountSettingsData();

  const TableTitleList = [
    {
      name: t("tasks.table.header.taskName"),
      size: 4,
      align: "left",
    },
    {
      name: t("tasks.table.header.assgnedTo"),
      size: 1.7,
      align: "center",
    },
    {
      name: t("tasks.table.header.tag"),
      size: 1,
      align: "center",
    },
    {
      name: t("tasks.table.header.createdDate"),
      size: 1,
      align: "center",
    },
    {
      name: t("tasks.table.header.comment"),
      size: 1,
      align: "center",
    },
    {
      name: t("tasks.table.header.priority"),
      size: 1,
      align: "center",
    },
    {
      name: t("tasks.table.header.status"),
      size: 1.5,
      align: "center",
    },
    {
      name: "",
      size: 0.8,
      align: "center",
    },
  ];

  const onDeleteTask = (task: TaskInfo) => {
    setTaskToDelete(task);
  };

  const onSendReminder = async (task: TaskInfo) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnProcessing(true);
      const res = await tasksApi.sendReminder(task.id, token.accessToken);
      setReminderResponse(res);
      setOnProcessing(false);
    }
  };

  const onCancelDelete = () => {
    setTaskToDelete(undefined);
  };

  const onDeleteApproved = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token && taskToDelete) {
      setOnProcessing(true);
      await tasksApi.deleteTask(taskToDelete.id, token.accessToken);
      await getTasKlist();
      setOnProcessing(false);
    }
    setTaskToDelete(undefined);
  };

  useAsyncEffect(async () => {
    removeClientSelfTasks(project.tasks);
    setShowManager(true);
  }, [project]);

  const onUpdateTask = async (task: TaskInfo) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnProcessing(true);
      setTaskToEdit(undefined);
      await tasksApi.updateTask(task, token.accessToken);
      await getTasKlist();
      setOnProcessing(false);
    }
  };

  const removeClientSelfTasks = async (taskList: TaskInfo[]) => {
    let _tasks = _.cloneDeep(taskList);
    if (coWorkers && !isClient) {
      const lowerCoWorkers = [
        ...coWorkers.map((r) => r.email.toLowerCase()),
        userEmail.toLowerCase(),
      ];
      _tasks = taskList.filter(
        (t) =>
          lowerCoWorkers.includes(t.createdBy.toLowerCase()) ||
          lowerCoWorkers.includes(t.assignedTo.toLowerCase())
      );
    }
    setTasksList(_tasks);
    calculateFolders(_tasks);
  };

  const getTasKlist = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    setOnProcessing(true);
    if (token) {
      const taskResponse = await tasksApi.getProjectTasks(
        project.id,
        token.accessToken
      );
      removeClientSelfTasks(taskResponse.tasks);
      calculateFolders(taskResponse.tasks);
    }
    setOnProcessing(false);
  };

  const onCommentClick = (task: TaskInfo, comp: HTMLDivElement) => {
    setCommentAnchorEl(comp);
    setTaskForComments(task);
  };

  const updateTaskComments = async (task: TaskInfo, comments: any[]) => {
    const _tasks = _.cloneDeep(tasksList);
    const fileIndex = _tasks.findIndex((f) => f.id === task.id);
    if (fileIndex > -1) {
      _tasks[fileIndex].comments = comments;
      setTasksList(_tasks);
    }
  };

  const onSaveTaskList = async (taskListName: string) => {
    const taskTemplate = {
      name: taskListName,
      tasks: tasksList.map((t) => ({
        name: t.name,
        tag: t.tag,
        priority: t.priority,
        toMe:
          userEmail.toLowerCase() === t.assignedTo.toLowerCase() &&
          t.assignedTo.toLowerCase() === t.createdBy.toLowerCase(),
      })),
    };
    addTaksTemplatesList(taskTemplate);
    setShowSaveTaskListDialog(false);
  };

  const onTaskToEdit = (task: TaskInfo) => {
    setTaskToEdit(task);
  };

  const calculateFolders = (taskList: TaskInfo[]) => {
    const _tags = _.cloneDeep(DefaultTags);
    const _usedTags: string[] = [];
    taskList.forEach((t) => {
      if (t.tag !== "") {
        if (!_tags.includes(t.tag)) {
          _tags.push(t.tag);
        }
        if (!_usedTags.includes(t.tag)) {
          _usedTags.push(t.tag);
        }
      }
    });
    setUsedTags(_usedTags);
    setAvaliableTags(_tags);
  };

  const onSelectedImportList = async (taskList: TaskInfo[]) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setShowImportTaskListPopup(false);
      setOnProcessing(true);
      await Promise.all(
        taskList.map((t) =>
          tasksApi.createTask(t, project.id, token.accessToken)
        )
      );
      await getTasKlist();
      setOnProcessing(false);
    }
  };

  const onCreateNewTask = async (task: TaskInfo) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setShowAddNewTaskDialog(false);
      setTaskToEdit(undefined);
      setOnProcessing(true);
      await tasksApi.createTask(task, project.id, token.accessToken);
      await getTasKlist();
      if (selectedFolders.indexOf(task.tag) === -1) {
        setSelectedFolders([]);
      }
      setOnProcessing(false);
    }
  };

  const onShowAddNewTask = () => {
    setShowAddNewTaskDialog(true);
  };

  const onImportTaskList = () => {
    setShowImportTaskListPopup(true);
  };

  return (
    <>
      {showManager && (
        <Box sx={{ display: "flex", width: "100%", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              borderRadius: "10px",
              flexGrow: 1,
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "27px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "30px",
                  flexGrow: 1,
                  fontFamily: "Nunito",
                  fontSize: "17px",
                  fontWeight: "bold",
                }}
              >
                {t("tasks.title")}
              </Box>
              <Box sx={{ marginRight: "30px", cursor: "pointer" }}>
                {!isClient && tasksList.length > 0 && (
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    {/* {getTaskTemplateList().length > 0 && ( */}
                      <HelpToolTipOnHover
                        position="top"
                        message={"Import existing task list"}
                        actionNode={
                          <ImportTaskListSVG
                            onClick={(e) => setImportAnchorEl(e.currentTarget)}
                          />
                        }
                      />
                    {/* )} */}
                    <HelpToolTipOnHover
                      position="top"
                      message={"Save task list"}
                      actionNode={
                        <SaveTaskListSVG
                          onClick={(e) => setSaveListAnchorEl(e.currentTarget)}
                        />
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {tasksList.length === 0 ? (
              <EmptyTaskList
                isClient={isClient}
                onCreateNewTask={() => onShowAddNewTask()}
                onImportTaskList={() => onImportTaskList()}
              />
            ) : (
              <Box
                sx={{
                  flexGrow: 1,
                  margin:
                    usedTags.length > 0 ? "20px 30px 0px" : "30px 30px 0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <Box sx={{ marginBottom: "8px" }}>
                    <FoldersList
                      folderList={usedTags}
                      selectedFolder={selectedFolders}
                      onFolderSelected={(folders: string[]) =>
                        setSelectedFolders(folders)
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      background: "#fff",
                      padding: "0 36px",
                      alignItems: "flex-end",
                      display: "flex",
                      height: "60px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box sx={{ width: "70px", marginBottom: "8px" }}>
                      Filter by:{" "}
                    </Box>
                    <Box sx={{ marginLeft: "10px", alignItems: "flex-end" }}>
                      <BasicStaticDropDown
                        options={["All", ...TaskStatus]}
                        value={`${statusFilter}`}
                        onChange={(value: number) => {
                          setStatusFilter(value);
                        }}
                        overRideWidth={140}
                        keyName={"TaskPriority"}
                      />
                    </Box>
                    <Divider
                      sx={{ margin: "20px 20px 0px" }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box sx={{ marginBottom: "8px" }}>
                      <TaskSearch
                        startOpen={true}
                        onSelectedSearchRes={(val: string) => {
                          setSearchInTasks(val);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={1}>
                  {TableTitleList.map((title, idx) => (
                    <Grid item xs={title.size} key={`table-title-${idx}`}>
                      <TableHeader
                        sx={{
                          paddingLeft: title.align === "left" ? "10px" : "0px",
                          justifyContent: title.align,
                        }}
                      >
                        {title.name}
                      </TableHeader>
                    </Grid>
                  ))}
                </Grid>
                <Box>
                  {(selectedFolders.length > 0
                    ? tasksList.filter(
                        (r) => selectedFolders.indexOf(r.tag) !== -1
                      )
                    : tasksList
                  )
                    .filter((t) =>
                      statusFilter === 0
                        ? true
                        : t.status === TaskStatus[statusFilter - 1]
                    )
                    .filter((t) =>
                      searchInTasks.length > 1
                        ? t.name
                            .toLowerCase()
                            .includes(searchInTasks.toLowerCase())
                        : true
                    )
                    .map((task, idx) => (
                      <TaskLine
                        key={`task-${idx}`}
                        task={task}
                        selected={false}
                        isClient={isClient}
                        onDeleteTask={onDeleteTask}
                        onCommentClick={onCommentClick}
                        onTaskToEdit={onTaskToEdit}
                        onUpdteTask={onUpdateTask}
                        onSendReminder={onSendReminder}
                      />
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    marginTop: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        marginRight: "12px",
                        fontFamily: "Nunito",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Add new task
                    </Box>
                    <Box>
                      <Fab
                        size="small"
                        sx={{
                          background: "#000",
                          color: "#fff",
                          width: "35px",
                          height: "35px",
                          "&:hover": { color: "#000" },
                        }}
                        onClick={() => onShowAddNewTask()}
                      >
                        <AddIcon fontSize="medium" sx={{ width: "18px" }} />
                      </Fab>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {taskForComments && commentAnchorEl && (
              <FilesCommentsManager
                anchorEl={commentAnchorEl}
                isClient={isClient}
                email={userEmail}
                onClose={() => {
                  setTaskForComments(undefined);
                  setCommentAnchorEl(null);
                }}
                isTask={true}
                projectId={project.id}
                comments={taskForComments.comments ?? []}
                commentId={taskForComments.id}
                setComments={(comments: any[]) => {
                  updateTaskComments(taskForComments, comments);
                }}
              />
            )}

            {showImportTaskListPopup && (
              <ImportTaskPopup
                onClose={() => setShowImportTaskListPopup(false)}
                onSelectedImportList={onSelectedImportList}
              />
            )}

            {showSaveTaskListDialog && (
              <SaveTaskListPopup
                onClose={() => setShowSaveTaskListDialog(false)}
                onSaveTaskList={onSaveTaskList}
              />
            )}

            {taskToDelete && (
              <DeleteMessageDialog
                onClose={() => onCancelDelete()}
                onDelete={() => onDeleteApproved()}
                title={`Are you sure you want to delete this task?`}
                message={`This action will permanently remove the task${
                  taskToDelete.assignedTo.toLowerCase() !==
                  userEmail.toLowerCase()
                    ? ", and it will no longer be available to your client."
                    : "."
                }`}
              />
            )}
            {(showAddNewTaskDialog || taskToEdit) && (
              <NewTaskForm
                onClose={() => {
                  setShowAddNewTaskDialog(false);
                  setTaskToEdit(undefined);
                }}
                onCreateNewTask={onCreateNewTask}
                onUpdateTask={onUpdateTask}
                task={taskToEdit}
                coWorkers={coWorkers}
                designerEmail={designerEmail}
                avaliableTags={avaliableTags}
                isClient={isClient}
              />
            )}
            <DeleteConfirmationPopup
              title={`Save this task list for future projects`}
              description={""}
              onDeleteSite={() => {
                setSaveListAnchorEl(null);
                setShowSaveTaskListDialog(true);
              }}
              placement={"bottom"}
              icon={<SaveSvg />}
              action={"Save"}
              onClose={() => setSaveListAnchorEl(null)}
              anchorEl={saveListAnchorEl}
              autoClose={true}
            />
            <DeleteConfirmationPopup
              title={`Add tasks from exisiting list`}
              description={""}
              onDeleteSite={() => {
                setImportAnchorEl(null);
                setShowImportTaskListPopup(true);
              }}
              placement={"bottom"}
              icon={<SaveSvg />}
              action={"Choose task list"}
              onClose={() => setImportAnchorEl(null)}
              anchorEl={importAnchorEl}
              autoClose={true}
            />
            {reminderResponse !== undefined && (
              <BasicMessagePopup
                onClose={() => setReminderResponse(undefined)}
                open={true}
                disableAutoClose={false}
                size="compact"
              >
                <ReminderHandle
                  shareResult={reminderResponse}
                  successMessage={"A reminder has been sent successfully."}
                  onCloseShare={() => setReminderResponse(undefined)}
                />
              </BasicMessagePopup>
            )}
          </Box>
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={onProcessing}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
    </>
  );
};
