import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const LazyNewAiForm = React.lazy(() => import("./NewAiForm"));
const LazyAiItems = React.lazy(() => import("./AiItems"));
const LazyAnaylzeUrlTest = React.lazy(() => import("./AnaylzeUrlTest"));
const LaztTemplateUpdate = React.lazy(() => import("./templates/UpdateTemplateData/UpdateTemplateData"));

const LazyUsersList = React.lazy(() => import("./UsersList"));
const LazyTemplateCreate = React.lazy(
  () => import("./templates/TemplateCreate")
);
const LazyTemplateMapping = React.lazy(
  () => import("./templates/TemplateMapping/TemplateMapping")
);

const LazyInvoices = React.lazy(
  () => import("./Paypal/AddInvoiceForAccount")
);

export const AdminRouter: React.FC = () => {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route path="/ai" element={<LazyAiItems />} />
          <Route path="/ai/:aiId" element={<LazyNewAiForm />} />
          <Route path="/website" element={<LazyAnaylzeUrlTest />} />
          <Route path="/users" element={<LazyUsersList />} />
          <Route path="/templates" element={<LazyTemplateCreate />} />
          <Route path="/invoices" element={<LazyInvoices />} />
          <Route path="/templates/update" element={<LaztTemplateUpdate />} />
          <Route path="/mapping" element={<LazyTemplateMapping />} />
          <Route path="*" element={<div>sdfsdfdsfdsfsdfsdfsdf</div>} />
        </Routes>
      </DndProvider>
    </>
  );
};
