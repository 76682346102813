import { useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { useAsyncEffect } from "use-async-effect";
import { FaceBookPageInfo, socialApi } from "../../api/socialApi";
import { ReactComponent as FaceBookBigSvg } from "../../assets/social/facebookBig.svg";
import { ReactComponent as FaceBookSvg } from "../../assets/social/facebookRound.svg";
import { ReactComponent as NoPagesSvg } from "../../assets/social/noPageFound.svg";
import { ReactComponent as CheckedBoxSVG } from "../../assets/whiteCheck.svg";
import { BasicMessagePopup } from "../../Massages/BasicMessagePopup";
import {
  FaceBookConnectedPageInfo,
  refreshActiveProject,
} from "../../projects/projectsSlice";
import { ColorButton } from "../../projects/ProjectUIHelper";

import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { getAccessTokenSilence } from "../../auth/auth";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import "../social.scss";
import { SocialConnect } from "../Manager/SocialConnect";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import { ClientUrl } from "../../authConfig";
import { SuccessMessagePopup } from "../../Massages/SuccessMessagePopup";

export interface FacebookConnectProps {
  projectId: string;
  projectFaceBookInfo: FaceBookConnectedPageInfo;
  isClientView?: boolean;
  onConneted?(): void;
}

export const FacebookConnect: FC<FacebookConnectProps> = ({
  projectId,
  isClientView,
  projectFaceBookInfo,
  onConneted,
}) => {
  const [showFacebookPages, setShowFacebookPages] =
    React.useState<boolean>(false);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [showNoPagesMessagePopup, setShowNoPagesMessagePopup] = React.useState<boolean>(false);
  const [showDeleteMessagePopup, setShowDeleteMessagePopup] =
    React.useState<boolean>(false);
  const [pageFetchDone, setPageFetchDone] = React.useState<boolean>(false);
  const [fbPages, setFbPages] = React.useState<FaceBookPageInfo[]>([]);
  const [faceBookInfo, setFaceBookInfo] = React.useState<FaceBookPageInfo>();
  const [fbShortAccessToken, setFbShortAccessToken] =
    React.useState<string>("");
  const msalInstance = useMsal();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useAsyncEffect(async () => {
    if (fbShortAccessToken !== "") {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const longAccessInfoResponse =
          await socialApi.getLongFaceBookAccessCode(
            fbShortAccessToken,
            projectId,
            token.accessToken
          );
        if (longAccessInfoResponse && longAccessInfoResponse.access_token) {
          getUserPages(token.accessToken);
        } else {
          getUserPages(token.accessToken);
        }
      }
    }
  }, [fbShortAccessToken]);

  const logInToFB = async () => {
    setInProgress(true);
    window.FB.login(
      (response: any) => {
        if (response.authResponse && response.authResponse.accessToken) {
          setFbShortAccessToken(response.authResponse.accessToken);
        } else {
          setInProgress(false);
        }
      },
      { config_id: "553587100153614" }
    );
  };

  const getUserPages = async (token: string) => {
    setInProgress(true);
    if (!pageFetchDone) {
      const pageResponse = await socialApi.getFaceBookPageList(
        projectId,
        token
      );
      if (pageResponse.pages.length === 0) {
        setShowNoPagesMessagePopup(true);
      } else {
        setFbPages(pageResponse.pages);
        if (projectFaceBookInfo.name) {
          const connectedPage = pageResponse.pages.find(
            (p) => p.name === projectFaceBookInfo.name
          );
          if (connectedPage) {
            setFaceBookInfo(connectedPage);
          }
        }
        setPageFetchDone(true);
      }
    } else if (projectFaceBookInfo.name) {
      const connectedPage = fbPages.find(
        (p) => p.name === projectFaceBookInfo.name
      );
      if (connectedPage) {
        setFaceBookInfo(connectedPage);
      }
    }
    setShowFacebookPages(true);
    setInProgress(false);
  };

  const handleClose = () => {
    setShowFacebookPages(false);
  };

  const selectFaceBookPage = async (page?: FaceBookPageInfo) => {
    if (page) {
      setInProgress(true);
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const data = await socialApi.setFaceBookPage(
          projectId,
          page.facebookId,
          token.accessToken
        );
        if (data) {
          setFaceBookInfo(data);
          setShowFacebookPages(false);
          if (onConneted) {
            onConneted();
          } else {
            dispatch(refreshActiveProject(token.accessToken));
            setTimeout(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
              });
            }, 500);
          }
        }
      }
      setInProgress(false);
    }
  };

  const onEditClick = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await getUserPages(token.accessToken);
    }
  };

  const sendLinkToClient = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await socialApi.sendClientRequuestToConnectToSocial(
        projectId,
        "facebook",
        `${ClientUrl}/social/collaborator/${projectId}`,
        token.accessToken
      );
    }
  };

  const disconnectFromFacebook = async () => {
    setShowDeleteMessagePopup(false);
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setInProgress(true);
      await socialApi.disconnectFromFacebook(projectId, token.accessToken);
      setFaceBookInfo(undefined);
      dispatch(refreshActiveProject(token.accessToken));
      setInProgress(false);
      setShowFacebookPages(false);
    }
  };

  return (
    <>
      <SocialConnect
        isClientView={isClientView}
        isConnected={projectFaceBookInfo.connected && projectFaceBookInfo.id!== undefined}
        onConnectClick={() => logInToFB()}
        onSendClientConnect={() => sendLinkToClient()}
        socialIcon={<FaceBookSvg />}
        onEditSocail={() => onEditClick()}
        bigSocialIcon={<FaceBookBigSvg />}
        socialName={"Facebook"}
      />
      <BasicPageBlocker show={inProgress} />

      {showFacebookPages && (
        <BasicMessagePopup
          extraWidth="750px"
          onClose={projectFaceBookInfo.connected && projectFaceBookInfo.pageAccessToken!== undefined ? handleClose: undefined}
          open={showFacebookPages}
          disableAutoClose={true}
        >
          <Box sx={{ margin: "20px 70px 0px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: "60px",
              }}
            >
              Please select the page you would like Lynxi to sync and manage
            </Box>
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "auto",
                paddingRight: "20px",
              }}
            >
              {fbPages.map((page: FaceBookPageInfo, pageIdx: number) => (
                <Box
                  key={`facebook-page-${pageIdx}`}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "50px",
                  }}
                >
                  <Box sx={{ fontSize: "16px" }}>
                    {page.name} ({page.category})
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => setFaceBookInfo(page)}
                    className={
                      faceBookInfo === page ? "radioFull" : "radioEmpty"
                    }
                  >
                    {faceBookInfo === page && <CheckedBoxSVG />}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{ cursor: "pointer", fontSize: "14px", fontWeight: 500, color:'#6c3dab' }}
                onClick={() => setShowDeleteMessagePopup(true)}
              >
                Disconnect from Facebook
              </Box>
              <ColorButton
                onClick={() => selectFaceBookPage(faceBookInfo)}
                disabled={!faceBookInfo}
              >
                Connect
              </ColorButton>
            </Box>
          </Box>
        </BasicMessagePopup>
      )}
      {showDeleteMessagePopup && (
        <DeleteMessageDialog
          onClose={() => setShowDeleteMessagePopup(false)}
          onDelete={disconnectFromFacebook}
          title={`Are you sure?`}
          message={`Please note disconnecting from Facebook will remove all your posts from Lynxi`}
        />
      )}
      {showNoPagesMessagePopup && (
          <SuccessMessagePopup
            open={showNoPagesMessagePopup}
            icon={<NoPagesSvg />}
            message={t("social.no_pages_facebook.message")}
            title={t("social.no_pages_facebook.title")}
            onClose={() => setShowNoPagesMessagePopup(false)}
            onContinue={() => setShowNoPagesMessagePopup(false)}
            continueAction={t("social.no_pages_facebook.action")}
            showAction={true}
          />
        )}
    </>
  );
};
