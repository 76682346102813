import { Box, Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";
import { ReactComponent as CLoseDialogSvg } from "../assets/closeDialog.svg";

interface BasicDraggableDialogProps {
  title: string;
  onClose?: () => void;
  disableDrag?: boolean;
  children?: React.ReactNode;
  extraWidth?: string;
}

const PaperComponent = (props: any) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{ maxWidth: "none", borderRadius: "30px" }}
      />
    </Draggable>
  );
};

export const BasicDraggableDialog: React.FC<BasicDraggableDialogProps> = ({
  title,
  onClose,
  disableDrag,
  children,
  extraWidth
}) => {
  return (
    <Dialog
      open={true}
      sx={{ zIndex: 4000 }}
      PaperComponent={PaperComponent}
       PaperProps={{ sx: {width: extraWidth ??'600px'} }}
      aria-labelledby="draggable-dialog-title"
      disableEscapeKeyDown={true} // Disable closing on Escape key
    >
      <DialogTitle
        style={{ cursor: disableDrag ? "auto" : "move" }}
        id="draggable-dialog-title"
        sx={{
          backgroundImage:
            "linear-gradient(148deg, #fb8332 -39%, #f8c332 102%)",
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginLeft: "30px" }}>{title}</Box>
          <Box sx={{ display: "flex", flexGrow: 1 }} />
          {onClose && (
            <Box
              sx={{
                marginRight: "5px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              onClick={() => onClose()}
            >
              <CLoseDialogSvg width="15px" />
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            margin: "0 auto",
          }}
        >
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
