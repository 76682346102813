import { useMsal } from "@azure/msal-react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Grid } from "@mui/material";
import * as React from "react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { QuestionnaireData, projectsApi } from "../../../api/projectsApi";
import { getAccessTokenSilence } from "../../../auth/auth";
import { DeleteConfirmationPopup } from "../../../PopUpMessage/DeleteProjectPopup";
import { BriefData, Project, refreshActiveProject } from "../../projectsSlice";
import { TableHeader } from "../../ProjectUIHelper";
import { BriefLineView } from "./BriefLineView";
import { EmptyProjectBriefs } from "./EmptyProjectBriefs";
import { ShareMessagePopup } from "../../../Massages/ShareMessagePopup";
import { DESIGNER_LANG_TOKEN } from "../../../utils/Constants";
import { useAppDispatch } from "../../../app/hooks";
import CHFormUpdatePopup from "../../../cms/Form/CHFormUpdatePopup";
import { useDesginerInfo } from "../../../hooks/useDesginerName";

export interface BriefsManagerViewProps {
  project: Project;
  onSelectedBrief(brief: BriefData): void;
  onReloadProject(): void;
  selectedBrief?: BriefData;
}

export const BriefsManagerView: FC<BriefsManagerViewProps> = ({
  project,
  onSelectedBrief,
  onReloadProject,
  selectedBrief,
}) => {
  const [briefToDelete, setBriefToDelete] = useState<BriefData>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [fake, setFake] = useState<number>(0);
  const [briefToReopen, setBriefToReopen] = useState<BriefData>();
  const [formToEdit, setFormToEdit] = useState<QuestionnaireData>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const msalInstance = useMsal();
  const { t } = useTranslation();
  const { getDesignerName } = useDesginerInfo();

  const TableTitleList = [
    {
      name: t("projects.briefs.table.header.brief_name"),
      size: 2,
      align: "left",
    },
    {
      name: t("projects.briefs.table.header.created"),
      size: 1,
      align: "center",
    },
    {
      name: t("projects.briefs.table.header.updated"),
      size: 1,
      align: "center",
    },
    {
      name: t("projects.briefs.table.header.sent_to"),
      size: 2.5,
      align: "left",
    },
    {
      name: t("projects.briefs.table.header.comments"),
      size: 1,
      align: "center",
    },
    {
      name: t("projects.briefs.table.header.status"),
      size: 2,
      align: "center",
    },
    {
      name: t("projects.briefs.table.header.action"),
      size: 2,
      align: "center",
    },
    { name: "", size: 0.5, align: "center" },
  ];

  const onDeleteBrief = (brief: BriefData, anchor: HTMLDivElement) => {
    setAnchorEl(anchor);
    setBriefToDelete(brief);
  };

  const onCancelDelete = () => {
    setBriefToDelete(undefined);
    setAnchorEl(null);
  };

  useAsyncEffect(async () => {
    setFake(fake + 1);
  }, [project.briefs]);

  const onDeleteApproved = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token && briefToDelete) {
      await projectsApi.deleteBriefFromProject(
        briefToDelete.id,
        token.accessToken
      );
      onReloadProject();
    }
    setAnchorEl(null);
    setBriefToDelete(undefined);
  };

  const onReopenBriefShare = async (
    shareEmails: string[],
    description: string,
    subject: string
  ): Promise<boolean> => {
    const token = await getAccessTokenSilence(msalInstance);
    if (briefToReopen && token) {
      const reopenResponse = await projectsApi.reopenUserBrief(
        briefToReopen.id,
        subject,
        description,
        token.accessToken
      );
      if (reopenResponse) {
        dispatch(refreshActiveProject(token.accessToken));
      }
      return reopenResponse;
    } else {
      return false;
    }
  };

  const onOpenNewFormForEdit = async (brief: QuestionnaireData) => {
    setFormToEdit(brief);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            borderRadius: "10px",
            flexGrow: 1,
            paddingBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "27px",
              marginLeft: "30px",
              flexGrow: 1,
              fontFamily: "Nunito",
              fontSize: "17px",
              fontWeight: "bold",              
            }}
            id='formBox'
          >
            {t("projects.briefs.title")}
          </Box>
          {project.briefs.length === 0 ? (
            <EmptyProjectBriefs project={project} />
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                margin: "30px 30px 0px",
              }}
            >
              <Grid container spacing={1}>
                {TableTitleList.map((title, idx) => (
                  <Grid item xs={title.size} key={`table-title-${idx}`}>
                    <TableHeader
                      sx={{
                        paddingLeft: title.align === "left" ? "10px" : "0px",
                        justifyContent: title.align,
                      }}
                    >
                      {title.name}
                    </TableHeader>
                  </Grid>
                ))}
              </Grid>
              <Box>
                {project.briefs.map((row, idx) => (
                  <BriefLineView
                    project={project}
                    briefData={row}
                    selected={row === selectedBrief}
                    key={`brief-${idx}-${fake}`}
                    onShowSelectedBrief={(brief: BriefData) => {
                      onSelectedBrief(brief);
                    }}
                    onReopenBriefShare={() => setBriefToReopen(row)}
                    onDeleteBrief={onDeleteBrief}
                    showTestPopup={
                      // project.briefs.length === 1 &&
                      // !sessionStorage.getItem("showSendTest")
                      false
                    }
                    onOpenNewFormForEdit={onOpenNewFormForEdit}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      marginRight: "12px",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Add new form
                  </Box>
                  <Box>
                    <Fab
                      size="small"
                      sx={{
                        background: "#000",
                        color: "#fff",
                        width: "35px",
                        height: "35px",
                        "&:hover": { color: "#000" },
                      }}
                      onClick={() =>
                        navigate(`/projects/${project.id}/questionnaire/pick`)
                      }
                    >
                      <AddIcon fontSize="medium" sx={{ width: "18px" }} />
                    </Fab>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <DeleteConfirmationPopup
            title={`Are you sure you want to delete "${briefToDelete?.name}" ?`}
            description={
              "By deleting this form all data gathered in it will be deleted."
            }
            onDeleteSite={() => onDeleteApproved()}
            onClose={() => onCancelDelete()}
            anchorEl={anchorEl}
            autoClose={true}
          />
        </Box>
      </Box>
      {briefToReopen && (
        <ShareMessagePopup
          allowAnonymous={true}
          project={project}
          onClose={() => setBriefToReopen(undefined)}
          onShare={onReopenBriefShare}
          title={t("brief.viewer.designer.reopen_popup.title")}
          header={t("brief.viewer.designer.reopen_popup.header")}
          subject={t("brief.viewer.designer.reopen_popup.subject").replace(
            DESIGNER_LANG_TOKEN,
            getDesignerName()
          )}
          action={t("brief.viewer.designer.reopen_popup.action")}
          hideEmail={true}
          message={t("brief.viewer.designer.reopen_popup.message").replace(
            DESIGNER_LANG_TOKEN,
            getDesignerName()
          )}
        />
      )}
      {formToEdit && (
        <CHFormUpdatePopup
          show={false}
          onClose={() => setFormToEdit(undefined)}
          userFormData={formToEdit}
          project={project}
        />
      )}
    </>
  );
};
