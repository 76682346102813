import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { FC } from "react";
import {
  FaceBookOpenHours,
  FaceBookPageAttributes,
  socialApi,
} from "../../api/socialApi";
import { ReactComponent as FaceBookSvg } from "../../assets/social/facebookRound.svg";
import {
  FaceBookConnectedPageInfo,
  Project,
} from "../../projects/projectsSlice";

import { cmsApi, CMSResponse } from "../../api/cmsApi";
import { DAYS_OF_THE_WEEK } from "../../utils/Constants";
import "../social.scss";
import { getAccessTokenSilence } from "../../auth/auth";
import {
  SocialItemDataDefinitions,
  SocialSync,
  SocialSyncResponse,
  SocialSyncSuccessResponse,
} from "../Manager/SocialSync";
import { DayOpenHoursInfo } from "../../cms/components/OpenHours/OpenHoursComp";
import { get, keys } from "lodash";

const faceBookHoursDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const TranslationMapFromFaceBook: SocialItemDataDefinitions[] = [
  { key: "about", cmsKey: 48, compType: "paragraph", socialLimit: 255 },
  { key: "emails", cmsKey: 2, compType: "textArray" },
  { key: "hours", cmsKey: 180, compType: "hours" },
  { key: "phone", cmsKey: 3, compType: "text" },
  { key: "website", cmsKey: 5, compType: "text" },
  { key: "name", cmsKey: 42, compType: "text" },
  { key: "location", subKey: "city", cmsKey: 70, compType: "text" },
  { key: "location", subKey: "state", cmsKey: 71, compType: "text" },
  { key: "location", subKey: "zip", cmsKey: 72, compType: "text" },
  { key: "location", subKey: "country", cmsKey: 73, compType: "dropdown" },
  {
    key: "single_line_address",
    cmsKey: 1,
    compType: "text",
    ignoreForSocial: true,
  },
  { key: "picture", cmsKey: 43, compType: "img", apiKey: "logo" },
  { key: "cover", cmsKey: 45, compType: "img", apiKey: "cover" },
  { key: "link", cmsKey: 6, compType: "text", ignoreForSocial: true },
];

const TranslationMapFromFaceBookV2: SocialItemDataDefinitions[] = [
  { key: "about", cmsKey: 49, compType: "paragraph", socialLimit: 100, socialOverideDescription:"Intro" },
  { key: "mission", cmsKey: 512, compType: "paragraph" },
  { key: "emails", cmsKey: 2, compType: "textArray" },
  { key: "hours", cmsKey: 180, compType: "hours" },
  { key: "impressum", cmsKey: 48, compType: "paragraph", socialOverideDescription: "Details about you" },
  { key: "phone", cmsKey: 3, compType: "text" },
  { key: "name", cmsKey: 42, compType: "text",ignoreForSocial: true },
  { key: "website", cmsKey: 5, compType: "text" },
  { key: "location", subKey: "city", cmsKey: 70, compType: "text" },
  { key: "location", subKey: "state", cmsKey: 71, compType: "text" },
  { key: "location", subKey: "zip", cmsKey: 72, compType: "text" },
  { key: "location", subKey: "country", cmsKey: 73, compType: "dropdown" },
  {
    key: "single_line_address",
    cmsKey: 1,
    compType: "text",
    ignoreForSocial: true,
  },
  { key: "picture", cmsKey: 43, compType: "img", apiKey: "logo" },
  { key: "cover", cmsKey: 45, compType: "img", apiKey: "cover" },
  { key: "link", cmsKey: 6, compType: "text", ignoreForSocial: true },
];

export interface FacebookSyncProps {
  project: Project;
  projectFaceBookInfo: FaceBookConnectedPageInfo;
}

export const FacebookSync: FC<FacebookSyncProps> = ({
  project,
  projectFaceBookInfo,
}) => {

  const msalInstance = useMsal();

  const updateCMSFromFaceBook = async (
    facebookData: FaceBookPageAttributes
  ) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (!token) {
      return { success: false };
    }
    const cmsResponse: CMSResponse = await cmsApi.getCmsDataFromServer(
      project.cmsDataId ?? "",
      token.accessToken
    );
    const cmsDic = JSON.parse(cmsResponse.content);
    const cmsAiDic = JSON.parse(cmsResponse.aiContent);
    const city = cmsDic["70"];
    const country = cmsDic["73"];
    const noLocationExist = city === "" || get(country, "value", "") === "";

    const emptyCmsKeys = projectFaceBookInfo.newPageExperience
      ? TranslationMapFromFaceBookV2
      : TranslationMapFromFaceBook;

    let facebookImages = {};
    const hasImages =
      emptyCmsKeys.filter((r) => r.compType === "img").length > 0;
    if (hasImages) {
      facebookImages = await socialApi.getFaceBookPageImages(
        project.id,
        token.accessToken
      );
    }

    const faceBookcmsDic: { [id: string]: any } = {};

    emptyCmsKeys.forEach((mapDic) => {
      switch (mapDic.compType) {
        case "paragraph": {
          faceBookcmsDic[mapDic.cmsKey] = get(facebookData, mapDic.key);
          break;
        }
        case "img": {
          const src = get(facebookImages, mapDic.apiKey ?? mapDic.key);
          if (src) {
            faceBookcmsDic[mapDic.cmsKey] = src;
          } else {
            faceBookcmsDic[mapDic.cmsKey] = ''
          }
          break;
        }
        case "text": {
          if (mapDic.subKey) {
            if (mapDic.key === "location") {
              faceBookcmsDic[mapDic.cmsKey] = get(
                get(facebookData, mapDic.key),
                mapDic.subKey
              );
            } else {
              faceBookcmsDic[mapDic.cmsKey] = get(
                get(facebookData, mapDic.key),
                mapDic.subKey
              );
            }
          } else {
            faceBookcmsDic[mapDic.cmsKey] = get(facebookData, mapDic.key);
          }
          break;
        }
        case "textArray": {
          const data = get(facebookData, mapDic.key, []);
          if (data.length > 0) {
            faceBookcmsDic[mapDic.cmsKey] = data[0];
          }
          break;
        }
        case "dropdown": {
          if (mapDic.subKey) {
            faceBookcmsDic[mapDic.cmsKey] = {
              value: get(get(facebookData, mapDic.key), mapDic.subKey),
              other: "",
            };
          } else {
            faceBookcmsDic[mapDic.cmsKey] = {
              value: get(facebookData, mapDic.key),
              other: "",
            };
          }
          break;
        }
        case "hours": {
          const hours: FaceBookOpenHours = get(facebookData, mapDic.key);
          if (hours) {
            const cmsHours = DAYS_OF_THE_WEEK.map(
              (day: string, idx: number) => {
                const openHours = get(
                  hours,
                  `${faceBookHoursDays[idx]}_1_open`
                );
                if (openHours) {
                  return {
                    name: day,
                    openHour: openHours,
                    closeHour:
                      get(hours, `${faceBookHoursDays[idx]}_1_close`) ?? "",
                    closeOnDay: false,
                  };
                } else {
                  return {
                    name: day,
                    openHour: "",
                    closeHour: "",
                    closeOnDay: true,
                  };
                }
              }
            );
            faceBookcmsDic[mapDic.cmsKey] = cmsHours;
          } else {
            faceBookcmsDic[mapDic.cmsKey] = {}
          }
          break;
        }
      }
    });

    return {
      success: true,
      socialSyncData: {
        cmsDic,
        cmsSchema: cmsResponse.schema,
        socialItems: projectFaceBookInfo.newPageExperience
          ? TranslationMapFromFaceBookV2
          : TranslationMapFromFaceBook,
        locationExists: !noLocationExist,
        socialCmsDic: faceBookcmsDic,
        cmsAiDic        
      },
    };
  };

  const onSync = async (): Promise<SocialSyncResponse> => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const facebookPageData = await socialApi.getFaceBookInfo(
        project.id,
        token.accessToken
      );
      if (facebookPageData) {
        return await updateCMSFromFaceBook(facebookPageData);
      }
    }
    return { success: false };
  };

  const prepareSocialDataForSync = async (
    socialItems: SocialItemDataDefinitions[],
    socialUpdateCmsRec: { [id: string]: any },
    token: string
  ): Promise<SocialSyncSuccessResponse> => {
    const socialUpdateRec: { [id: string]: any } = {};
    const syncKeys = keys(socialUpdateCmsRec);
    syncKeys.map((key) => {
      //
      const socialItem = socialItems.find((item) => `${item.cmsKey}` === key);
      if (socialItem) {
        const cmsItem = get(socialUpdateCmsRec, key, "");
        if (!cmsItem || cmsItem === "" || socialItem.ignoreForSocial) {
          //
        } else {
          switch (socialItem.compType) {
            case "paragraph": {
              socialUpdateRec[socialItem.key] = cmsItem;
              break;
            }
            case "img": {
              //upload Imag
              socialApi.updateFaceBookPageImages(
                project.id,
                { url: cmsItem, type: socialItem.apiKey ?? socialItem.key },
                token
              );
              break;
            }
            case "text": {
              if (socialItem.subKey) {
                // break;
                if (socialItem.subKey && !get(socialUpdateRec, socialItem.key)) {
                  socialUpdateRec[socialItem.key] = {}
                }
                socialUpdateRec[socialItem.key][socialItem.subKey] = cmsItem
              } else {
                socialUpdateRec[socialItem.key] = cmsItem;
              }
              break;
            }
            case "textArray": {
              socialUpdateRec[socialItem.key] = [cmsItem];
              break;
            }
            case "dropdown": {
              if (socialItem.subKey) {
                // break;
                if (socialItem.subKey && !get(socialUpdateRec, socialItem.key)) {
                  socialUpdateRec[socialItem.key] = {}
                }
                socialUpdateRec[socialItem.key][socialItem.subKey] = cmsItem.value
              } else {
                socialUpdateRec[socialItem.key] = cmsItem.value
              }
              break;
            }
            case "hours": {
              const hours: { [id: string]: string } = {};
              DAYS_OF_THE_WEEK.map((day: string, idx: number) => {
                const cmsOpenHourLine: DayOpenHoursInfo = cmsItem.find(
                  (daySettings: DayOpenHoursInfo) => daySettings.name === day
                );
                if (cmsOpenHourLine && !cmsOpenHourLine.closeOnDay) {
                  if (cmsOpenHourLine.openHour !== "") {
                    hours[`${faceBookHoursDays[idx]}_1_open`] =
                      cmsOpenHourLine.openHour;
                  }
                  if (cmsOpenHourLine.closeHour !== "") {
                    hours[`${faceBookHoursDays[idx]}_1_close`] =
                      cmsOpenHourLine.closeHour;
                  }
                }
              });
              socialUpdateRec["hours"] = hours;
              break;
            }
          }
        }
      }
    });
    const location = get(socialUpdateRec, 'location')
    if (location) {
      delete socialUpdateRec['location']
      const syncWithoutAddressSuccess =  await socialApi.updateFaceBookPageInfo(
        project.id,
        socialUpdateRec,
        token
      );
      if (syncWithoutAddressSuccess) {
        return {success: await socialApi.updateFaceBookPageInfo(
          project.id,
          {location : location},
          token
        ), failedItems: ["location"]}
      } else {
        return {success: false}
      }
    } else {
      return {success: await socialApi.updateFaceBookPageInfo(
        project.id,
        socialUpdateRec,
        token
      )};
    }
  };

  return (
    <>
      <SocialSync
        project={project}
        onUpdateSocialData={prepareSocialDataForSync}
        onSync={onSync}
        socialName="Facebook"
        updateDate={projectFaceBookInfo.updated}
        icon={<FaceBookSvg />}
        socialLink={`https://www.facebook.com/${project.social?.facebook.id}`}
      />
    </>
  );
};
