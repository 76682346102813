import moment from "moment";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { ProjectState } from "../projects/projectsSlice";
import { getAccessTokenSilence } from "../auth/auth";
import { useMsal } from "@azure/msal-react";
import { paymentApi } from "../api/paymentApi";

export const useAccountSubscriptions = () => {
  const projectState: ProjectState = useAppSelector(
    (state: RootState) => state.projectsData
  );
  const msalInstance = useMsal()

  const isPackageRequiredOrExceeded = () => {
    const localTime  = moment.utc(projectState.accountInfo?.created).toDate();
    if (moment(localTime).isAfter(moment("2023-11-08"))) {
      const subscriptions = projectState.accountInfo?.subscriptions ?? [];
      const projectCount = projectState.projects.length;
      if (subscriptions.length === 0) {
        if (projectCount >=1 ) {
          return true;
        } else {
          return false;
        }
      } else {
        const activeSubscription = subscriptions.find((s) => s.status === "Approved");
        if (activeSubscription) {
          const activeSubscriptionProjectCount = activeSubscription.plan.numOfProjects
          if (projectCount >= activeSubscriptionProjectCount) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      return false;
    } 
  };


  const getActiveSubscription = () => {
    const subscriptions = projectState.accountInfo?.subscriptions ?? [];
    const activeSubscription = subscriptions.find((s) => s.status === "Approved");
    return activeSubscription;
  }

  const getPaypalSubscription = async () => {
    const subscriptions = projectState.accountInfo?.subscriptions ?? [];
    const activeSubscription = subscriptions.find((s) => s.status === "Approved");
    const token =  await getAccessTokenSilence(msalInstance)
    if (activeSubscription && token) {
      const subResponse = await paymentApi.getSusbcriptionData(activeSubscription.subscriptionId, token.accessToken)
      if (subResponse) {
         return subResponse
      }
    }
    return undefined;
  }


  return {getPaypalSubscription, isPackageRequiredOrExceeded, getActiveSubscription };
};
