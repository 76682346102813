import * as React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../projectsSlice";
import { useMsal } from "@azure/msal-react";
import { ReactComponent as DrawerReminderSVG } from "../../assets/project/drawerReminder.svg";
import { ReminderHandle } from "../ProjectBriefs/BriefStageStatus/ReminderHandle";
import { cmsApi, ShareCmsInfo } from "../../api/cmsApi";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../ProjectUIHelper";
import { getAccessTokenSilence } from "../../auth/auth";

export interface CMSFillStageProps {
  project: Project;
  shareInfo: ShareCmsInfo;
  onClose(reload?: boolean): void;
}

export const CMSFillStage: FC<CMSFillStageProps> = ({
  project,shareInfo, onClose
}) => {
  const [shareResult, setShareResult] = React.useState<boolean | undefined>();
  const [onLaoding, setOnLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const msalInstance = useMsal();
  const { t } = useTranslation();

  const sendCMSEmailReminder = async () => {
    if (project.cmsDataId) {
      const token = await getAccessTokenSilence(msalInstance)
      if (token) {
        setOnLoading(true);
        setShareResult(
          await cmsApi.sendCMSEmailReminder(project.cmsDataId, shareInfo.email, token.accessToken)
        );
        setOnLoading(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          position: "absolute",
          borderTopLeftRadius: "30px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={onLaoding}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {shareResult === undefined ? (
        <>
        <Box sx={{ fontSize: "14px", fontWeight: 300 }}>
          Help your client finish the job by{" "}
          <Typography
            color="secondary"
            onClick={() =>
              navigate(
                `/projects/${project.id}/cms/${project.cmsDataId}/content`
              )
            }
            sx={{
              cursor: "pointer",
              display: "inline",
              fontWeight: 500,
            }}
          >
            reviewing the Content hub
          </Typography>{" "}
          and leaving comments or{" "}
          <Typography
            color="secondary"
            onClick={() => sendCMSEmailReminder()}
            sx={{
              cursor: "pointer",
              display: "inline",
              fontWeight: 500,
            }}
          >
            send a reminder
          </Typography>{" "}
          to keep going.{" "}
        </Box>
      

      <Divider sx={{ marginTop: "40px" }} />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          margin: "15px 0 17px",
          color: "#6c3dab",
          cursor: "pointer",
          justifyContent: "space-between",
          fontSize: "14px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            onClick={() =>
              navigate(
                `/projects/${project.id}/cms/${project.cmsDataId}/content`
              )
            }
          >
            Content Hub
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ColorButton sx={{padding: '4px 15px' }} onClick={() => sendCMSEmailReminder()}>Send a reminder</ColorButton>
        </Box>
      </Box>
      </>
      ) : (
        <ReminderHandle
          successMessage={t("projects.content_approval.drawer.success_share.message")}
          shareResult={shareResult}
          onCloseShare={() => {
            setShareResult(undefined)
            if (shareResult) {
              onClose()
            }
          }}
        />
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
        <DrawerReminderSVG />
      </Box>
    </>
  );
};
