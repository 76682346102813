import { Box } from "@mui/material";
import * as React from "react";
import { FC } from "react";

import { ReactComponent as WhiteCheckSVG } from "../../assets/whiteCheck.svg";
import { ReactComponent as SyncSvg } from "../../assets/social/sync.svg";
import "../social.scss";
import { CardView } from "../../collaborator/CardView";
import { ColorButton, ColorButtonPurpleOutLined } from "../../projects/ProjectUIHelper";
import { useTranslation } from "react-i18next";
import { HelpPopplerOnHover } from "../../basic/HelpPopplerOnHover";
import { SOCIAL_NAME_LANG_TOKEN } from "../../utils/Constants";

export interface SocialConnectProps {
  isClientView?: boolean;
  isConnected: boolean
  onConnectClick():void
  onSendClientConnect():void
  onEditSocail():void
  socialIcon: React.ReactNode
  bigSocialIcon: React.ReactNode
  socialName: string
}

export const SocialConnect: FC<SocialConnectProps> = ({
  isClientView,
  isConnected,
  onConnectClick,
  onSendClientConnect,
  onEditSocail,
  socialIcon,
  bigSocialIcon,
  socialName
}) => {
  const [forceCloseHelp, setForceCloseHelp] = React.useState<boolean>(false);
  const {t} = useTranslation()
  return (
    <>
      {isClientView ? (
        <CardView icon={bigSocialIcon}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {isConnected ? (
              <Box className={"social_connect"}>
                <WhiteCheckSVG />
              </Box>
            ) : (
              <ColorButton onClick={() => onConnectClick()}>Connect</ColorButton>
            )}
          </Box>
        </CardView>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "90px",
            justifyContent: "center",
            position:'relative'
          }}
        >
          {isConnected && (
              <Box className={"social_connect"}>
                <WhiteCheckSVG />
              </Box>
          )}
          <Box className="socialLogo">
           {socialIcon}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {isConnected ? (
              <Box>
                 <ColorButtonPurpleOutLined onClick={ () => onEditSocail()}>
                    {t("social.manager.connectSelect.edit")}
                  </ColorButtonPurpleOutLined>
              </Box>
            ) : (
              <HelpPopplerOnHover
                position="bottom"
                useClick={true}
                overrideWidth={"350px"}
                autoClose={true}
                forceClose={forceCloseHelp}
                cancelText={'Cancel'}
                message={t("social.manager.connectSelect.message").replace(SOCIAL_NAME_LANG_TOKEN, socialName)}
                disabled={true}
                showCloseBtn={true}
                icon={<SyncSvg />}
                onCancel={() => {
                  setForceCloseHelp(true);
                  // onSendClientConnect()
                }}
                actionNode={
                  <ColorButtonPurpleOutLined>
                    {t("social.manager.connectSelect.connect")}
                  </ColorButtonPurpleOutLined>
                }
              >
                <Box
                  sx={{ fontWeight: 600, cursor: "pointer" }}
                  onClick={() => {
                    setForceCloseHelp(true);
                    onConnectClick()
                  }
                  }
                >
                  {t("social.manager.connectSelect.actionSelf")}
                </Box>
              </HelpPopplerOnHover>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
