import { useMsal } from "@azure/msal-react";
import { Backdrop, Box, Divider } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ShareCmsInfo } from "../../api/cmsApi";
import { projectsApi } from "../../api/projectsApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

import { ReactComponent as BlackLogoSVG } from "../../assets/lynxiblack.svg";
import { ReactComponent as SmallTrashSvg } from "../../assets/project/smallTrash.svg";
import { ReactComponent as SettingsSmallSvg } from "../../assets/project/settingsSmall.svg";

import { CMSShareManager } from "../CMSShare/CMSShareManager";
import { CMSStageStatusSwitcher } from "../CMSShare/CMSStageStatusSwitcher";
import { BriefsManagerView } from "../ProjectBriefs/BriefManager/BriefsManagerView";
import { BriefStageStatusSwitcher } from "../ProjectBriefs/BriefStageStatus/BriefStageStatusSwitcher";
import { ProjectPageStruct } from "../ProjectPageStruct";
import {
  BriefData,
  refreshActiveProject,
  setActiveProject,
  setDrawerOpen,
} from "../projectsSlice";
import { SchedulerMeeting } from "../SharePopups/SchedulerMeeting";
import { ProjectSummary } from "./ProjectSummary";

import { useTranslation } from "react-i18next";
import { BasicPageBlocker } from "../../basic/BasicPageBlocker";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { setCMSFilterMode } from "../../cms/cmsSlice";
import { ContentHubFilterType } from "../../utils/Constants";
import { ColorButtonPurpleOutLined } from "../ProjectUIHelper";
import { getAccessTokenSilence } from "../../auth/auth";
import useAsyncEffect from "use-async-effect";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import { BasicContextMenu } from "../../basic/BasicContextMenu";
import { DownloadContentPopover } from "../CMSShare/DownloadContentPopover";
import { ReactComponent as DownloadSvg } from "../../assets/project/download.svg";
import { FilesManagerView } from "../../FileSharing/FilesManagerView";
import { TaskManagerView } from "../../Tasks/TaskManagerView";
import { DesignerContext } from "../../routes/AppRouter";
import { WebSiteManager } from "../WebSiteManager";
import SocialManager from "../../Social/Manager/SocialManager";

export const ProjectView: React.FC = () => {
  const [selectedBrief, setSelectedBrief] = useState<BriefData>();
  const [selectedShareInfo, setSelectedShareInfo] = useState<ShareCmsInfo>();
  const [onDeleting, setOnDeleting] = React.useState<boolean>(false);

  const [showDeleteMessagePopup, setShowDeleteMessagePopup] =
    React.useState<boolean>(false);

  const msalInstance = useMsal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const desingerContext = React.useContext(DesignerContext);

  const projectsState = useAppSelector(
    (state: RootState) => state.projectsData
  );

  React.useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        // Tab has gained focus, perform data refresh here
        const token = await getAccessTokenSilence(msalInstance);
        if (token) {
          dispatch(refreshActiveProject(token.accessToken));
        }
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const jumpTo = (target: string) => {
    setTimeout(() => {
      const element = document.getElementById(target);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  };

  useAsyncEffect(async () => {
    const target = searchParams.get("target");
    if (target) {
      setTimeout(() => {
        if (target === "recentPosts") {
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
        } else {
          const element = document.getElementById(target);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
      }, 500);
    }
    const formId = searchParams.get("formId");
    if (formId && projectsState.activeProject) {
      const locateForm = projectsState.activeProject.briefs.find(
        (b) => b.id === formId
      );
      if (locateForm) {
        onSelectedBrief(locateForm);
      }
    }
  }, [searchParams]);

  const updateProject = async () => {
    if (projectsState.activeProject) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        const projectInfo = await projectsApi.getUserProjectById(
          projectsState.activeProject.id,
          token.accessToken
        );
        dispatch(setActiveProject(projectInfo));
      }
    }
  };

  const onDeleteApproved = async () => {
    setShowDeleteMessagePopup(false);
    setOnDeleting(true);
    if (projectsState.activeProject) {
      const token = await getAccessTokenSilence(msalInstance);
      if (token) {
        await projectsApi.deleteUserProject(
          projectsState.activeProject.id,
          token.accessToken
        );
        setOnDeleting(false);
        navigate("/projects");
      } else {
        setOnDeleting(false);
      }
    } else {
      setOnDeleting(false);
    }
  };

  const onSelectedBrief = (brief: BriefData) => {
    setSelectedShareInfo(undefined);
    setSelectedBrief(brief);
    dispatch(setDrawerOpen(true));
  };

  const onSelectedCMSShare = (shareInfo: ShareCmsInfo) => {
    setSelectedBrief(undefined);
    setSelectedShareInfo(shareInfo);
    dispatch(setDrawerOpen(true));
  };

  const closeStatusDrawer = async (reload?: boolean) => {
    if (reload) {
      await updateProject();
    }
    dispatch(setDrawerOpen(false));
    setSelectedBrief(undefined);
    setSelectedShareInfo(undefined);
  };

  const navigateToCms = () => {
    if (projectsState.activeProject) {
      dispatch(setCMSFilterMode(ContentHubFilterType.None));
      navigate(
        `/projects/${projectsState.activeProject.id}/cms/${projectsState.activeProject.cmsDataId}/content`
      );
    }
  };

  return (
    <>
      {projectsState.activeProject && (
        <Box sx={{ background: "#fff", display: "flex", width: "100%" }}>
          <Box
            sx={{
              background: "#F5F8F7",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              borderRadius: "19px",
            }}
          >
            <ProjectPageStruct
              noscroll={true}
              topLine={
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        marginLeft: "50px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#666",
                        marginBottom: "5px",
                      }}
                      onClick={() => navigate(`/projects`)}
                    >
                      My Projects
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        marginLeft: "30px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#666",
                        marginBottom: "5px",
                      }}
                      onClick={() => navigate(`/forms`)}
                    >
                      Form Templates
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></Box>
                </Box>
              }
              whiteLine={
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: "26px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Project Dashboard&nbsp;
                    <span style={{ fontWeight: "normal" }}>
                      {"  "} {` | `}
                    </span>
                    &nbsp;
                    <BasicContextMenu
                      title={projectsState.activeProject.name}
                      menuItems={[
                        {
                          label: "Edit settings",
                          icon: <SettingsSmallSvg />,
                          func: () =>
                            navigate(
                              `/projects/${
                                projectsState.activeProject
                                  ? projectsState.activeProject.id
                                  : ""
                              }/view`
                            ),
                        },
                        {
                          label: "Delete Project",
                          icon: <SmallTrashSvg />,
                          func: () => setShowDeleteMessagePopup(true),
                        },
                      ]}
                    />
                  </Box>
                  <Box
                    className="project-dashboard-menu"
                    sx={{
                      display: selectedBrief ? "none" : "flex",
                      fontWeight: 600,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => jumpTo("contentHubBox")}
                    >
                      Content
                    </Box>
                    <Divider
                      sx={{
                        margin: "15px 10px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => jumpTo("formBox")}
                    >
                      Forms
                    </Box>
                    <Divider
                      sx={{
                        margin: "15px 10px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />

                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => jumpTo("files")}
                    >
                      Files
                    </Box>
                    <Divider
                      sx={{
                        margin: "15px 10px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => jumpTo("checklist")}
                    >
                      Checklists
                    </Box>
                    <Divider
                      sx={{
                        margin: "15px 10px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => jumpTo("websiteBox")}
                    >
                      Website
                    </Box>
                    <Divider
                      sx={{
                        margin: "15px 10px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => jumpTo("socialBox")}
                    >
                      Social
                    </Box>
                  </Box>
                  {/* <Box sx={{ flexGrow: 1 }} /> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <DownloadContentPopover
                      project={projectsState.activeProject}
                      showToolTip={true}
                      anchorElement={<DownloadSvg />}
                    />
                    <Divider
                      sx={{
                        margin: "15px 30px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box sx={{ cursor: "pointer" }}>
                      <SchedulerMeeting project={projectsState.activeProject} />
                    </Box>
                    <Divider
                      sx={{
                        margin: "15px 30px",
                        bgcolor: "#e9ebea",
                        height: "30px",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Box
                      sx={{ cursor: "pointer", marginRight: "0px" }}
                      onClick={() => navigateToCms()}
                    >
                      <HelpToolTipOnHover
                        position="bottom-end"
                        message={t("projects.content_hub_tooltip")}
                        actionNode={
                          <ColorButtonPurpleOutLined
                            sx={{
                              border: "2px solid #c86dd7",
                              background: "#F2EDFC",
                              padding: "0 10px",
                              height: "36px",
                              borderRadius: "16px !important",
                            }}
                            startIcon={
                              <BlackLogoSVG
                                style={{
                                  transform: "scale(1)",
                                  marginBottom: "23px",
                                }}
                              />
                            }
                          >
                            Content Hub
                          </ColorButtonPurpleOutLined>
                        }
                      ></HelpToolTipOnHover>
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={selectedBrief || selectedShareInfo ? true : false}
              ></Backdrop>
              <Box
                sx={{
                  minHeight: "calc(100vh - 375px)",
                  margin: selectedBrief
                    ? "10px 10px 40px 10px"
                    : "10px 3% 40px 3%",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box
                  id={"containerDiv"}
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "50px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <ProjectSummary project={projectsState.activeProject} />
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "50px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <CMSShareManager
                      project={projectsState.activeProject}
                      onSelectedCMSShare={onSelectedCMSShare}
                      selectedInfo={selectedShareInfo}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginBottom: "50px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <BriefsManagerView
                      project={projectsState.activeProject}
                      onSelectedBrief={onSelectedBrief}
                      onReloadProject={updateProject}
                      selectedBrief={selectedBrief}
                    />
                  </Box>
                  <WebSiteManager project={projectsState.activeProject} />
                  <Box
                    id="files"
                    sx={{
                      marginBottom: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <FilesManagerView
                      project={projectsState.activeProject}
                      shareTitle="Share files with your client"
                    />
                  </Box>
                  <Box
                    id="checklist"
                    sx={{
                      marginBottom: "50px",
                    }}
                  >
                    <TaskManagerView
                      userEmail={desingerContext.email}
                      project={projectsState.activeProject}
                      coWorkers={(
                        projectsState.accountInfo?.members ?? []
                      ).filter(
                        (r) =>
                          r.email.toLowerCase() !==
                          desingerContext.email.toLowerCase()
                      )}
                    />
                  </Box>

                  <Box  id="socialBox">
                    <SocialManager project={projectsState.activeProject} />
                  </Box> 
                </Box>
              </Box>
            </ProjectPageStruct>
          </Box>

          <Box
            sx={{
              display: selectedBrief || selectedShareInfo ? "auto" : "none",
              position: "relative",
            }}
          >
            {selectedBrief && (
              <BriefStageStatusSwitcher
                project={projectsState.activeProject}
                brief={selectedBrief}
                showComments={false}
                onClose={(reload?: boolean) => closeStatusDrawer(reload)}
              />
            )}
            {selectedShareInfo && (
              <CMSStageStatusSwitcher
                project={projectsState.activeProject}
                shareInfo={selectedShareInfo}
                onClose={(reload?: boolean) => closeStatusDrawer(reload)}
              />
            )}
          </Box>
          {showDeleteMessagePopup && (
            <DeleteMessageDialog
              onClose={() => setShowDeleteMessagePopup(false)}
              onDelete={() => onDeleteApproved()}
              title={`Are you sure you want to delete "${projectsState.activeProject.name}" ?`}
              message={`By deleting this project all data will be deleted from your
              account.`}
            />
          )}
          <BasicPageBlocker show={onDeleting} />
        </Box>
      )}
    </>
  );
};
