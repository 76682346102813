import { Backdrop, CircularProgress } from "@mui/material";
import React, { FC } from "react";

export interface BasicPageBlockerProps {
  show: boolean;
}

export const BasicPageBlocker: FC<BasicPageBlockerProps> = ({ show }) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 5000 }} open={show}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
