import { Box, Fab, Grid } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { Project } from "../projectsSlice";
import { EmptyContentShare } from "./EmptyContentShare";
import { ShareCmsInfo } from "../../api/cmsApi";
import { TableHeader } from "../ProjectUIHelper";
import { CMSShareLineView } from "./CMSShareLineView";
import AddIcon from "@mui/icons-material/Add";
import { ContentApprovalPopup } from "../SharePopups/ContentApprovalPopup";
import { useTranslation } from "react-i18next";
import { ReactComponent as SmallCMSBtnSVG } from "../../assets/project/smallCMSBtn.svg";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { useNavigate } from "react-router-dom";


export interface CMSShareManagerProps {
  project: Project;
  selectedInfo?: ShareCmsInfo;
  onSelectedCMSShare(shareInfo: ShareCmsInfo): void;
}

export const CMSShareManager: FC<CMSShareManagerProps> = ({
  project,
  selectedInfo,
  onSelectedCMSShare,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const navigateToCms = () => {
    navigate(`/projects/${project}.id}/cms/${project.cmsDataId}/content`);
  };
  const TableTitleList = [
    {
      name: t("projects.content_approval.table.header.sent_to"),
      size: 3,
      align: "left",
    },
    {
      name: t("projects.content_approval.table.header.last_shared"),
      size: 2,
      align: "center",
    },
    {
      name: t("projects.content_approval.table.header.status"),
      size: 2,
      align: "center",
    },
    {
      name: t("projects.content_approval.table.header.action"),
      size: 2,
      align: "center",
    },
    { name: "", size: 1, align: "center" },
  ];

  return (
    <>
      <Box sx={{ display: "flex", width:'100%' }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            borderRadius: "10px",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "27px",
              alignItems: "flex-top",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginLeft: "30px",
                flexGrow: 1,
                fontFamily: "Nunito",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              id='contentHubBox'
            >
              {t("projects.content_approval.title")}
            </Box>
            <Box sx={{ marginRight: "30px", marginTop:'-15px', cursor:'pointer' }}>
            {project.cmsShareInfo.length > 0 && (
            <HelpToolTipOnHover
              position="top"
              message={t("projects.content_hub_tooltip")}
              actionNode={<SmallCMSBtnSVG onClick={() => navigateToCms()}/>}
            />
            )}
            </Box>
          </Box>
          {project.cmsShareInfo.length > 0 ? (
            <Box
              sx={{
                flexGrow: 1,
                margin: "30px 30px 0px",
                paddingBottom: "20px",
              }}
            >
              <Grid container spacing={1}>
                {TableTitleList.map((title, idx) => (
                  <Grid item xs={title.size} key={`table-title-${idx}`}>
                    <TableHeader
                      sx={{
                        paddingLeft: title.align === "left" ? "10px" : "0px",
                        justifyContent: title.align,
                      }}
                    >
                      {title.name}
                    </TableHeader>
                  </Grid>
                ))}
              </Grid>
              <Box>
                {project.cmsShareInfo.map((row, idx) => (
                  <CMSShareLineView
                    project={project}
                    shareCmsInfo={row}
                    selected={row === selectedInfo}
                    key={`cms-dhare-${idx}`}
                    onShowSelectedShare={(shareInfo: ShareCmsInfo) => {
                      onSelectedCMSShare(shareInfo);
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      marginRight: "12px",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Send your client a content approval request
                  </Box>
                  <Box>
                    <ContentApprovalPopup
                      project={project}
                      disabled={!project.cmsLastPublished}
                      noTooltip={true}
                    >
                      <Fab
                        size="small"
                        sx={{
                          background: "#000",
                          color: "#fff",
                          width: "35px",
                          height: "35px",
                          "&:hover": { color: "#000" },
                        }}
                        disabled={!project.cmsLastPublished}
                      >
                        <AddIcon fontSize="medium" sx={{ width: "18px" }} />
                      </Fab>
                    </ContentApprovalPopup>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <EmptyContentShare project={project} />
          )}
        </Box>
      </Box>
    </>
  );
};
