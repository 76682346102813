import { useMsal } from "@azure/msal-react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { projectsApi } from "../../api/projectsApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { getAccessToken, getAccessTokenSilence } from "../../auth/auth";
import { ProjectLine } from "../ProjectLine/ProjectLine";
import { Project, setProjects } from "../projectsSlice";
import AddIcon from "@mui/icons-material/Add";
import "./Project.scss";
import * as React from "react";
import { EmptyProject } from "./EmptyProject";
import { resetCMS } from "../../cms/cmsSlice";
import { useTranslation } from "react-i18next";
import { breakTextToLines } from "../ProjectUIHelper";
import { FlowMessagePopup } from "../../Massages/FlowMessagePopup";
import { ReactComponent as FireworksSVG } from "../../assets/project/fireworks.svg";
import { DeleteMessageDialog } from "../../Massages/DeleteMessageDialog";
import { ProjectsSearch } from "./ProjectsSearch";
import { UserUpgradeMessage } from "../../Packages/UserUpgradeMessage/UserUpgradeMessage";
import { useAccountSubscriptions } from "../../hooks/useAccountSubscriptions";

const TableHeader = styled(Box)(() => ({
  alignItems: "center",
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontWeight: "500",
}));

export const Projects: FC = () => {
  const [onLaoding, setOnLoading] = React.useState<boolean>(false);
  const [showGlobalWelcomeMessage, setShowGlobalWelcomeMessage] =
    React.useState<boolean>(false);
  const [fake, setFake] = useState<number>(0);
  const [searchQyery, setSearchQuery] = React.useState<string>("");
  const [projectForDeletion, setProjectForDeletion] = React.useState<Project>();
  const [fliterById, setFilterById] = React.useState<string>("All");
  const [showNeedAccountMessage, setShowNeedAccountMessage] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const projects: Project[] = useAppSelector(
    (state: RootState) => state.projectsData.projects
  );
  const navigate = useNavigate();
  const msalInstance = useMsal();
  const {isPackageRequiredOrExceeded} = useAccountSubscriptions()

  const TableTitleList = [
    { name: t("projects.table.header.project_name"), size: 2 },
    { name: t("projects.table.header.project_created"), size: 1.5 },
    { name: t("projects.table.header.project_updated"), size: 1.5 },
    { name: t("projects.table.header.project_time"), size: 1.5 },
    { name: t("projects.table.header.project_briefs"), size: 1 },
    { name: t("projects.table.header.project_cms"), size: 1.5 },
    { name: t("projects.table.header.project_comments"), size: 1 },
    { name: t("projects.table.header.project_status"), size: 1 },
    { name: "", size: 1 },
  ];

  const fetchUserProjects = async () => {
    setOnLoading(true);
    const accessToken = await getAccessToken(msalInstance);
    if (accessToken) {
      const response = await projectsApi.getUserProjects(accessToken);
      dispatch(setProjects(response.projects));
      if (response.projects.length === 0) {
        setShowGlobalWelcomeMessage(true);
      }
      dispatch(resetCMS());
      setOnLoading(false);
    }
  };

  const checkIfAllowedToAdd = async () => {
    if (isPackageRequiredOrExceeded()) {
      setShowNeedAccountMessage(true)
     } else {
       navigate("/intro/new_project")
     }
  }

  const setProjectForStart = async (project: Project) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await projectsApi.setStaringProjectTime(project.id, token.accessToken);
      await fetchUserProjects();
    } 
  }

  const setProjectForStop = async (project: Project) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await projectsApi.setEndingProjectTime(project.id, token.accessToken);
      await fetchUserProjects();
    } 
  }  

  useAsyncEffect(async () => {
    setFake(fake + 1);
  }, [projects]);

  useAsyncEffect(fetchUserProjects, []);


  const onDeleteApproved = async (project: Project) => {
    setProjectForDeletion(undefined);
    setOnLoading(true);
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await projectsApi.deleteUserProject(project.id, token.accessToken);
      await fetchUserProjects();
      setOnLoading(false);
    } else {
      setOnLoading(false);
    }
  };

  const onFilterChangeEvent = async (event: SelectChangeEvent) => {
    event.stopPropagation();
    setFilterById(event.target.value);
  };

  const filterProjectsByKeywordAndStatus = (projects: Project[]) => {
    if (searchQyery === "") {
      if (fliterById !== "All") {
        return projects.filter(
          (f) => f.status.toLowerCase() === fliterById.toLowerCase()
        );
      } else {
        return projects;
      }
    } else {
      return projects.filter(
        (f) => f.name.toLowerCase().indexOf(searchQyery.toLowerCase()) > -1
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          alignItems: "center",
          display: "flex",
          height: "78px",
        }}
      >
        <Box
          sx={{
            marginLeft: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {t("projects.my_projects")}
        </Box>
        <Box
          sx={{
            marginLeft: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#666",
            cursor: "pointer",
          }}
          onClick={() => navigate("/forms")}
        >
          Form Templates
        </Box>
        {/* <Box
          sx={{
            marginLeft: "50px",
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "bold",
            color: "#666",
            cursor: "pointer",
          }}
          onClick={() => navigate("/pricing")}
        >
          Pricing
        </Box> */}
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          background: "#fff",
          padding: "0 36px",
          alignItems: "center",
          display: "flex",
          height: "60px",
        }}
      >
        <Box>Filter by: </Box>
        <Box sx={{ marginLeft: "10px" }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 140 }}>
            <Select
              sx={{
                textDecoration: "none",
                fontFamily: "Nunito",
                "&.MuiInput-root::before": {
                  borderBottomColor: "#d8d8d8",
                },
                "&.MuiInput-root:hover::before": {
                  borderBottomColor: "#7C48C2",
                },
                "&.MuiInput-root.Mui-focused::after": {
                  borderBottomColor: "#7C48C2",
                },
              }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={`${fliterById}`}
              onChange={onFilterChangeEvent}
              inputProps={{
                sx: {
                  disableUnderline: true,
                  fontFamily: "Nunito",
                  "&:focus": {
                    background: "none",
                  },
                },
              }}
              MenuProps={{
                sx: {
                  "& .MuiMenuItem-root.Mui-selected": {
                    background: "transparent",
                  },
                },
              }}
            >
              <MenuItem value={"All"} sx={{ fontFamily: "Nunito" }}>
                All
              </MenuItem>
              <MenuItem value={"In progress"} sx={{ fontFamily: "Nunito" }}>
                In progress
              </MenuItem>
              <MenuItem value={"Pending"} sx={{ fontFamily: "Nunito" }}>
                Pending
              </MenuItem>
              <MenuItem value={"Canceled"} sx={{ fontFamily: "Nunito" }}>
                Canceled
              </MenuItem>
              <MenuItem value={"Completed"} sx={{ fontFamily: "Nunito" }}>
                Completed
              </MenuItem>
              <MenuItem value={"On hold"} sx={{ fontFamily: "Nunito" }}>
                On hold
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Divider sx={{ margin: "0 20px" }} orientation="vertical" flexItem />
        <Box>
          <ProjectsSearch
            startOpen={true}
            onSelectedSearchRes={(val: string) => setSearchQuery(val)}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}></Box>
      </Box>
      <Box sx={{ margin: "30px 0", display: "flex" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={onLaoding}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {projects.length === 0 && !onLaoding ? (
          <EmptyProject />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Box sx={{ padding: "30px 67px 0px" }}>
              <Grid container spacing={1}>
                {TableTitleList.map((title, idx) => (
                  <Grid item xs={title.size} key={`table-title-${idx}`}>
                    <TableHeader>{title.name}</TableHeader>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              sx={{
                height: "calc(100vh - 400px)",
              }}
            >
              <Box
                sx={{
                  maxHeight: "calc(100vh - 410px)",
                  overflowY: "auto",
                  marginTop: "10px",
                  padding: "0px 3% 0px",
                }}
              >
                {filterProjectsByKeywordAndStatus(projects).map((row, idx) => (
                  <ProjectLine
                    project={row}
                    selected={false}
                    key={`project-${idx}-${fake}`}
                    onDeleteClick={() => setProjectForDeletion(row)}
                    onSetStartProjectTime={() => setProjectForStart(row)}
                    onSetStopProjectTime={() => setProjectForStop(row)}
                  />
                ))}
                {/* <FloatAddButton onClick={() => navigate("/projects/new")} /> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                  padding: "0px 67px 0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "40px",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "12px",
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Create new project
                  </Box>
                  <Box>
                    <Fab
                      size="small"
                      sx={{
                        background: "#000",
                        color: "#fff",
                        width: "35px",
                        height: "35px",
                        boxShadow: "none",
                        "&:hover": { color: "#000" },
                      }}
                      onClick={() => checkIfAllowedToAdd()}
                    >
                      <AddIcon fontSize="medium" sx={{ width: "18px" }} />
                    </Fab>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {showGlobalWelcomeMessage && (
        <FlowMessagePopup
          open={showGlobalWelcomeMessage}
          title={t("projects.first_welcome_message.title")}
          message={breakTextToLines(
            t("projects.first_welcome_message.message")
          )}
          action={t("projects.first_welcome_message.action")}
          onClose={() => {
            setShowGlobalWelcomeMessage(false);
          }}
          icon={<FireworksSVG />}
        />
      )}
      {showNeedAccountMessage && (
        <UserUpgradeMessage onClose={() => setShowNeedAccountMessage(false)} />
      )}
      {projectForDeletion && (
        <DeleteMessageDialog
          onClose={() => setProjectForDeletion(undefined)}
          onDelete={() => onDeleteApproved(projectForDeletion)}
          title={`Are you sure you want to delete "${projectForDeletion.name}" ?`}
          message={`By deleting this project all data will be deleted from your
            account.`}
        />
      )}
    </>
  );
};
