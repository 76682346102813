import { Box, Grid } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { Project, refreshActiveProject } from "../projectsSlice";
import { useTranslation } from "react-i18next";
import { EmptyWebSite } from "./EmptyWebSite";
import { ColorButtonPurpleOutLined } from "../ProjectUIHelper";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";
import { ReactComponent as ViewSvg } from "../../assets/project/eye.svg";
import { ReactComponent as TrashSvg } from "../../assets/project/trash.svg";
import { ReactComponent as EditSvg } from "../../assets/project/purplePen.svg";
import { getPrettyDate } from "../../utils/DateUtils";
import { ClientUrl } from "../../authConfig";
import { DeleteConfirmationPopup } from "../../PopUpMessage/DeleteProjectPopup";
import { getAccessTokenSilence } from "../../auth/auth";
import { templatesApi } from "../../api/templatesApi";
import { useAppDispatch } from "../../app/hooks";
import { useMsal } from "@azure/msal-react";
import { useAccountSettingsData } from "../../hooks/useAccountTasksTemplates";

export interface WebSiteManagerProps {
  project: Project;
}

export const WebSiteManager: FC<WebSiteManagerProps> = ({ project }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const msalInstance = useMsal();
  const {getAccountDateFormat} = useAccountSettingsData()


  const onGoToSiteBuilder = () => {
    window.open(
      `${ClientUrl}/projects/${project.id}/websiteBuilder/${project.cmsDataId}`,
      "_lynxiEditor"
    );
  };

  const onDeleteSite = (anchor: HTMLDivElement) => {
    setAnchorEl(anchor);
  };

  const onDeleteApproved = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      await templatesApi.deleteSiteByProjectID(project.id, token.accessToken);
      dispatch(refreshActiveProject(token.accessToken));
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: "30px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            borderRadius: "10px",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "27px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginLeft: "30px",
                flexGrow: 1,
                fontFamily: "Nunito",
                fontSize: "17px",
                fontWeight: "bold",
              }}
              id='websiteBox'
            >
              {t("projects.website.manager.title")}
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              // margin: "30px 30px 0px",
              paddingBottom: "20px",
            }}
          >
            {project.site ? (
              <Box
                className="briefline"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  background: "#f9f9f9",
                  alignItems: "center",
                  borderRadius: "10px",
                  boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
                  height: "70px",
                  margin: "20px 30px",
                }}
              >
                <Grid container spacing={1} alignItems="center" height="100%">
                  <Grid item xs={2}>
                    <Box
                      sx={{
                        fontWeight: "500",
                        height: "100%",
                        cursor: "pointer",
                        marginRight: "5px",
                        marginLeft: "20px",
                        fontSize: "12px",
                      }}
                    >
                      {getPrettyDate(
                        project.site.lastPublished ?? project.site.updated,
                        false,
                        getAccountDateFormat()
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box sx={{ fontSize: "14px" }}>
                      {project.site.lastPublished
                        ? "Published"
                        : "Not published"}
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box>
                      {project.site.lastPublished && (
                        <ColorButtonPurpleOutLined>
                          Republish
                        </ColorButtonPurpleOutLined>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        fontSize: "14px",
                        color:'#6c3dab' 
                      }}
                      onClick={
                        project.site.template.subDomainName
                          ? () =>
                              window.open(
                                `https://${
                                  project.site?.template.subDomainName ?? ""
                                }.mylynxisite.com`,
                                "_blank"
                              )
                          : undefined
                      }
                    >
                      {project.site.template.subDomainName !== ""
                        ? `https://${project.site.template.subDomainName}.mylynxisite.com`
                        : "No subdomain"}
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingTop: "5px",
                        gap: "25px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <Box
                          sx={{ cursor: "pointer", alignItems: "center" }}
                          onClick={() => onGoToSiteBuilder()}
                        >
                          <HelpToolTipOnHover
                            position="top"
                            message={"Website settings"}
                            actionNode={<EditSvg />}
                          />
                        </Box>
                        <Box sx={{ cursor: "pointer", alignItems: "center" }}>
                          <HelpToolTipOnHover
                            position="top"
                            message={"Website preview"}
                            actionNode={<ViewSvg />}
                          />
                        </Box>
                        <Box
                          sx={{ cursor: "pointer", alignItems: "center" }}
                          onClick={(e) => onDeleteSite(e.currentTarget)}
                        >
                          <HelpToolTipOnHover
                            position="top"
                            message={"Delete Website"}
                            actionNode={<TrashSvg />}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <EmptyWebSite project={project} />
            )}
          </Box>
        </Box>
        <DeleteConfirmationPopup
          title={`Are you sure you want to delete the site ?`}
          description={
            "The site will be deleted and you will not be able to visit it."
          }
          onDeleteSite={() => onDeleteApproved()}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          autoClose={true}
        />
      </Box>
    </>
  );
};
