import { Badge, Box, Grid , Typography} from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EyeSvg } from "../../assets/project/eye.svg";
import { ReactComponent as CommentSvg } from "../../assets/project/purpleComment.svg";
import { ShareCmsInfo } from "../../api/cmsApi";
import {
  CMSShareStatusStages,
  ContentHubFilterType,
} from "../../utils/Constants";
import { getPrettyDate } from "../../utils/DateUtils";
import { Project } from "../projectsSlice";
import { ColorButton, TableCell } from "../ProjectUIHelper";
import { WalkThrowPopup } from "../../PopUpMessage/WalkThrowPopup";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks";
import { setCMSFilterMode } from "../../cms/cmsSlice";
import { HelpToolTipOnHover } from "../../basic/HelpToolTipOnHover";

export interface CMSShareLineViewProps {
  project: Project;
  shareCmsInfo: ShareCmsInfo;
  selected: boolean;
  onShowSelectedShare(shareInfo: ShareCmsInfo): void;
}

export const CMSShareLineView: FC<CMSShareLineViewProps> = ({
  project,
  shareCmsInfo,
  selected,
  onShowSelectedShare,
}) => {
  const navigate = useNavigate();
  const [heplerAnchorEl, setHeplerAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getStatus = () => {
    switch (shareCmsInfo.state) {
      case CMSShareStatusStages.Shared:
        return "Shared (not opened yet)";
      case CMSShareStatusStages.InProgress:
        return (
          <>
            Partially Approved <br />
            (still working on it)
          </>
        );
      case CMSShareStatusStages.Viewed:
      case CMSShareStatusStages.Reopened:
        return "Shared";
      case CMSShareStatusStages.PartiallyApproved:
        return "Partially Approved (Done)";
      case CMSShareStatusStages.Approved:
        return "Approved";
      default:
        return "";
    }
  };

  const getShareAction = () => {
    switch (shareCmsInfo.state) {
      case CMSShareStatusStages.Shared:
        return "Send reminder";
      case CMSShareStatusStages.Viewed:
      case CMSShareStatusStages.InProgress:
      case CMSShareStatusStages.Reopened:
        return "Send reminder";
      case CMSShareStatusStages.PartiallyApproved:
        return "Use content";
      case CMSShareStatusStages.Approved:
        return "Use content";
      default:
        return "";
    }
  };

  const checkForClientComments = () => {
    return (
      <Box
        sx={{ cursor: "pointer", marginLeft: "10px" }}
        onClick={(e) =>
          shareCmsInfo.unreadComments > 0
            ? navigate(
                `/projects/${project.id}/cms/${project.cmsDataId}/content?comment=true`
              )
            : setHeplerAnchorEl(e.currentTarget)
        }
      >
        {shareCmsInfo.unreadComments > 0 ? (
          <Badge badgeContent={shareCmsInfo.unreadComments} color="error">
            <CommentSvg />
          </Badge>
        ) : (
          <CommentSvg />
        )}
      </Box>
    );
  };

  return (
    <Box
      className="briefline"
      sx={{
        flexGrow: 1,
        display: "flex",
        background: selected ? "#f5effb" : "#f9f9f9",
        alignItems: "center",
        borderRadius: "10px",
        boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
        height: "70px",
        margin: "10px 0px",
      }}
    >
      <Grid container spacing={1} alignItems="center" height="100%">
        <Grid
          item
          xs={3}
          sx={{ borderRight: "solid 1px #e9ebea", height: "100%" }}
        >
          <TableCell
            sx={{
              fontWeight: "500",
              height: "100%",
              justifyContent: "flex-start",
              cursor: "pointer",
              marginRight: "5px",
              marginLeft: "20px",
            }}
          >
            <Typography
                        noWrap
                        sx={{ width: "100%", textAlign: "left" }}
                      >
                        { shareCmsInfo.email.toLowerCase()}
                      </Typography>
           
          </TableCell>
        </Grid>
        <Grid item xs={2}>
          <TableCell sx={{ fontSize: "12px" }}>
            {getPrettyDate(shareCmsInfo.sharedAt)}
          </TableCell>
        </Grid>
        <Grid item xs={2}>
          <TableCell>{getStatus()}</TableCell>
        </Grid>
        <Grid item xs={2}>
          <TableCell
            sx={{ cursor: "pointer", color: "secondary" }}
            onClick={() => {
              onShowSelectedShare(shareCmsInfo);
            }}
          >
            <ColorButton sx={{ width: "60%", padding: "5px" }}>
              {getShareAction()}
            </ColorButton>
          </TableCell>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ width: "20px" }} />

            <Box sx={{ width: "20px" }} />

            <HelpToolTipOnHover
              position="bottom"
              message={"Content Hub comments"}
              actionNode={checkForClientComments()}
            />
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(setCMSFilterMode(ContentHubFilterType.Approved));
                navigate(
                  `/projects/${project.id}/cms/${project.cmsDataId}/content`
                );
              }}
            >
              <HelpToolTipOnHover
                position="bottom"
                message={"Show Content Hub approvals"}
                actionNode={<EyeSvg />}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <WalkThrowPopup
        anchorEl={heplerAnchorEl}
        message={t("projects.project_line.comment_helper")}
        direction="left"
        autoClose={true}
        onClose={() => setHeplerAnchorEl(null)}
      />
    </Box>
  );
};
