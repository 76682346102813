import { Backdrop, Box, CircularProgress, Fab } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { Project } from "../../projects/projectsSlice";
import useAsyncEffect from "use-async-effect";
import { getAccessTokenSilence } from "../../auth/auth";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { PostInsightsData, socialApi, SocialItemData } from "../../api/socialApi";
import {
  ColorButton,
  ColorButtonPurpleOutLined,
} from "../../projects/ProjectUIHelper";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as EmptyPostSVG } from "../../assets/post/emptyPosts.svg";
import { PostHistoryLine } from "./PostHistoryLine";

export interface RecentPostsProps {
  project: Project;
}

export const RecentPosts: FC<RecentPostsProps> = ({ project }) => {
  const [onLoading, setOnLoading] = React.useState<boolean>(false);
  const msalInstance = useMsal();
  const navigate = useNavigate();
  const [posts, setPosts] = React.useState<SocialItemData[] | undefined>(
    undefined
  );
  const [postInsights, setPostInsights] = React.useState<PostInsightsData[]>([]);


  // useAsyncEffect(async () => {
  //   const target = searchParam.get("target");
  //   if (target) {
  //     setTimeout(() => {
  //       if (target ==="recentPosts") {
  //         window.scrollTo({
  //           top: document.body.scrollHeight,
  //           left: 0,
  //           behavior: "smooth",
  //         });
  //       } else {
  //         window.scrollTo({
  //           top: 0,
  //           left: 0,
  //           behavior: "smooth",
  //         });
  //       }
  //     }, 500);
  //   }
  // }, [searchParam]);


  const onDeletePost = async (post: SocialItemData, selectedSocial?:string) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      setOnLoading(true);
      if (selectedSocial) {
        const data = {facebook: selectedSocial === 'facebook', linkedin: selectedSocial === 'linkedin'}
        await socialApi.deletePost(post, project.id ,data, token.accessToken);
      } else {
        const data = {facebook: !!post.facebook, linkedin: !!post.linkedin}
        await socialApi.deletePost(post, project.id ,data, token.accessToken);
      }
      await getPosts()
      setOnLoading(false);
    }
  }

  const getPosts = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      const postsListResponse = await socialApi.getSocialPosts(
        project.id,
        token.accessToken,
        true
      );
      const realPosts = postsListResponse.items.filter(r => r.facebook || r.linkedin || r.instagram)
      setPosts(realPosts);
      if (realPosts.length > 0) {
        socialApi.getSocislPostsInsights(project.id, realPosts.map(r => r.id), token.accessToken).then( res => {
          if (res) {
            setPostInsights(res.insights)
          }
        })   
      }   
    }
  }

  useAsyncEffect(async () => {
    await getPosts()
    
  }, [project]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", position:'relative'}}>
        <Backdrop
          sx={{
            color: "#fff",
            position: "absolute",
            borderTopLeftRadius: "30px",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={onLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ padding: "40px 50px 0px" }}>
          <Box sx={{ fontSize: "16px", marginBottom: "40px" }}>
            Recent posts
          </Box>
        </Box>
        {posts !== undefined && (
          <>
            {posts.length === 0 ? (
              <Box sx={{ padding: "0px 50px 0px", display: "flex" }}>
                <Box sx={{ marginRight: "30px" }}>
                  <EmptyPostSVG />
                </Box>
                <Box>
                  <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
                   {`Manage your client's social media posts`}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    {`Post across all their channels in one place.`}
                  </Box>
                  <Box>
                    <ColorButton
                      onClick={() =>
                        navigate(
                          `/projects/${project.id}/posts/new/pick_social`
                        )
                      }
                      sx={{ marginTop: "30px" }}
                    >
                      Create post
                    </ColorButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "0 40px",
                  }}
                >
                  <ColorButtonPurpleOutLined
                    onClick={() =>
                      navigate(`/projects/${project.id}/posts/manager`)
                    }
                  >
                    View all
                  </ColorButtonPurpleOutLined>
                </Box>
                <Box
                  id={'recentPost'}
                  sx={{ display: "flex", flexWrap: "wrap", margin: "0 50px", flexDirection: "column" }}
                >
                  {posts.slice(0, 3).map((post, idx) => (
                    <PostHistoryLine
                      key={`recent-post-${idx}`}
                      post={post}
                      narrow={true}
                      onDeletePost={onDeletePost}
                      postInsights={postInsights.find(r => r.id === post.id)}
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "40px 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "auto",
                      margin: "0 40px",
                    }}
                  >
                    Create post&nbsp;&nbsp;
                    <Fab
                      size="small"
                      sx={{
                        boxShadow: "none",
                        background: "#000",
                        color: "#fff",
                        width: "30px",
                        height: "30px",
                        minHeight: "20px",
                        "&:hover": { color: "#000" },
                      }}
                      onClick={() =>
                        navigate(
                          `/projects/${project.id}/posts/new/pick_social`
                        )
                      }
                    >
                      <AddIcon fontSize="small" sx={{ width: "18px" }} />
                    </Fab>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
