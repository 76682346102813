import { useMsal } from "@azure/msal-react";
import { FC } from "react";
import * as React from "react";
import { ReactComponent as LinkedInSvg } from "../../assets/social/linkedinRound.svg";
import {
  LinkedInConnectedPageInfo,
  Project,
} from "../../projects/projectsSlice";

import { get } from "lodash";
import { cmsApi, CMSResponse } from "../../api/cmsApi";
import {
  LinkedInLocation,
  LinkedInResponseData,
  socialApi,
} from "../../api/socialApi";
import { getAccessTokenSilence } from "../../auth/auth";
import {
  SocialItemDataDefinitions,
  SocialSync,
  SocialSyncResponse,
  SocialSyncSuccessResponse,
} from "../Manager/SocialSync";
import "../social.scss";

export interface LinkedInSyncProps {
  project: Project;
  projectLinkedInInfo: LinkedInConnectedPageInfo;
}

const TranslationMapFromLinkedIn: SocialItemDataDefinitions[] = [
  { key: "localizedDescription", cmsKey: 48, compType: "paragraph" },
  { key: "localizedWebsite", cmsKey: 5, compType: "text" },
  {
    key: "localizedName",
    cmsKey: 42,
    compType: "text",
    ignoreForSocial: true,
  },
  {
    key: "foundedOn",
    cmsKey: 41,
    compType: "founded",
    ignoreForSocial: true,
  },
  {
    key: "locations",
    cmsKey: -1,
    compType: "locations",
    ignoreForSocial: true,
  },
  { key: "city", cmsKey: 70, compType: "text", calculated: true },
  { key: "state", cmsKey: 71, compType: "text", calculated: true },
  { key: "postal Code", cmsKey: 72, compType: "text", calculated: true },
  { key: "country", cmsKey: 73, compType: "dropdown", calculated: true },
  { key: "phone", cmsKey: 3, compType: "text", calculated: true },
  {
    key: "address",
    cmsKey: 1,
    compType: "text",
    calculated: true,
  },
];

export const LinkedInSync: FC<LinkedInSyncProps> = ({
  project,
  projectLinkedInInfo,
}) => {
  const msalInstance = useMsal();

  const updateCMSFromLinkedIn = async (linkedInData: LinkedInResponseData) => {
    const token = await getAccessTokenSilence(msalInstance);
    if (!token) {
      return { success: false };
    }
    const cmsResponse: CMSResponse = await cmsApi.getCmsDataFromServer(
      project.cmsDataId ?? "",
      token.accessToken
    );
    const cmsDic = JSON.parse(cmsResponse.content);
    const cmsAiDic = JSON.parse(cmsResponse.aiContent);
    const city = cmsDic["70"];
    const country = cmsDic["73"];
    const noLocationExist = city === "" || get(country, "value", "") === "";

    let facebookImages = {};
    const hasImages =
      TranslationMapFromLinkedIn.filter((r) => r.compType === "img").length > 0;
    if (hasImages) {
      facebookImages = await socialApi.getFaceBookPageImages(
        project.id,
        token.accessToken
      );
    }

    const linkedInCmsDic: { [id: string]: any } = {};

    TranslationMapFromLinkedIn.filter((r) => !r.calculated).forEach(
      (mapDic) => {
        switch (mapDic.compType) {
          case "paragraph": {
            linkedInCmsDic[mapDic.cmsKey] = get(linkedInData, mapDic.key);
            break;
          }
          case "img": {
            const src = get(facebookImages, mapDic.apiKey ?? mapDic.key);
            if (src) {
              linkedInCmsDic[mapDic.cmsKey] = src;
            }
            break;
          }
          case "locations": {
            const locationArray: LinkedInLocation[] = get(
              linkedInData,
              mapDic.key,
              []
            );
            if (locationArray.length > 0 && locationArray[0].address) {
              const location = locationArray[0].address;
              linkedInCmsDic[70] = location.city ?? "";
              linkedInCmsDic[71] = location.geographicArea ?? "";
              linkedInCmsDic[72] = location.postalCode ?? "";
              linkedInCmsDic[73] = {
                value: location.country ?? "",
                other: "",
              };
              linkedInCmsDic[1] = `${location.line1 ?? ""}, ${
                location.city ?? ""
              }, ${location.geographicArea ?? ""}, ${
                location.postalCode ?? ""
              }, ${location.country ?? ""}`;
            }
            if (locationArray.length > 0 && locationArray[0].phoneNumber1) {
              linkedInCmsDic[3] = locationArray[0].phoneNumber1.number ?? "";
            }
            break;
          }
          case "text": {
            linkedInCmsDic[mapDic.cmsKey] = get(linkedInData, mapDic.key);
            break;
          }
          case "founded": {
            linkedInCmsDic[mapDic.cmsKey] = `${get(
              get(linkedInData, mapDic.key),
              "year",
              ""
            )}`;
            break;
          }
          case "textArray": {
            const data = get(linkedInData, mapDic.key, []);
            if (data.length > 0) {
              linkedInCmsDic[mapDic.cmsKey] = data[0];
            }
            break;
          }
          case "dropdown": {
            if (mapDic.subKey) {
              linkedInCmsDic[mapDic.cmsKey] = {
                value: get(get(linkedInData, mapDic.key), mapDic.subKey),
                other: "",
              };
            } else {
              linkedInCmsDic[mapDic.cmsKey] = {
                value: get(linkedInData, mapDic.key),
                other: "",
              };
            }
            break;
          }
          case "hours":
            {
              //
            }
            break;
        }
      }
    );

    return {
      success: true,
      socialSyncData: {
        cmsDic,
        cmsSchema: cmsResponse.schema,
        socialItems: TranslationMapFromLinkedIn,
        locationExists: !noLocationExist,
        socialCmsDic: linkedInCmsDic,
        cmsAiDic,        
      },
    };
  };

  const onSync = async (): Promise<SocialSyncResponse> => {
    //
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      try {
        const linkedInOrgData = await socialApi.getLinkedInOrganizationData(
          project.id,
          token.accessToken
        );
        if (linkedInOrgData) {
          return await updateCMSFromLinkedIn(linkedInOrgData);
        }
      } catch (e: any) {
        if (e.message==="Reconnecting") {
          //
        }
      }
    }
    return { success: false };
  };

  const prepareSocialDataForSync = async (): Promise<SocialSyncSuccessResponse> => {
    return {success: true};
  };

  return (
    <>
      <SocialSync
        project={project}
        onUpdateSocialData={prepareSocialDataForSync}
        onSync={onSync}
        socialName="LinkedIn"
        icon={<LinkedInSvg />}
        onlyFromSocial={true}
        socialLink={`https://linkedin.com/company/${project.social?.linkedin.id}`}
      />
    </>
  );
};
