import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import React, { FC } from "react";
import { getPrettyDateOrTime } from "../utils/DateUtils";
import { ProjectActivity } from "./notificationsSlice";
import { useAccountSettingsData } from "../hooks/useAccountTasksTemplates";

export interface NotificatiionLineProps {
  notification: ProjectActivity;
  onDeleteNotification(activityId: string): void;
  // projects: Project[]
}

export const NotificationLine: FC<NotificatiionLineProps> = ({
  notification,
  onDeleteNotification,
  // projects
}) => {
  // const replaceProjectWithProjectName = () => {
  //   if (notification.projectId) {
  //      projects.find( p=> p.id === notification.projectId)
  //   }
  // }
  const {getAccountDateFormat} = useAccountSettingsData()


  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick= { () => onDeleteNotification(notification.id)}
        sx={{ position: "absolute", top: -2, right: "5px", cursor: "pointer" }}
      >
        x
      </Box>
      <Box
        sx={{
          fontFamily: "Nunito",
          fontSize: "12px",
          fontWeight: "normal",
        }}
      >
        {getPrettyDateOrTime(notification.createdAt, getAccountDateFormat())}
      </Box>
      <Box
        sx={{
          fontFamily: "Nunito",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        {notification.activity}
      </Box>
      <Box sx={{ width: "100%", margin: "10px 0" }}>
        <Divider sx={{ background: "#595858" }} />
      </Box>
    </Box>
  );
};
