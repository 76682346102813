import { Box } from "@mui/material";
import { FC } from "react";
import { Project } from "../projectsSlice";
import * as React from "react";
import { ReactComponent as EmptyContentShareSVG } from "../../assets/project/contentBox.svg";
import { ColorButton } from "../ProjectUIHelper";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { setCMSFilterMode } from "../../cms/cmsSlice";
import { ContentHubFilterType } from "../../utils/Constants";
import { BasicEmptyStage } from "../../basic/BasicEmptyStage/BasicEmptyStage";

export interface EmptyContentSharaProps {
  project: Project;
}

export const EmptyContentShare: FC<EmptyContentSharaProps> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToCms = () => {
    dispatch(setCMSFilterMode(ContentHubFilterType.None));
    navigate(`/projects/${project.id}/cms/${project.cmsDataId}/content`);
  };

  return (
    <BasicEmptyStage icon={<EmptyContentShareSVG />}>
      <>
        <Box sx={{ display: "flex", marginTop: "10px", maxWidth: "770px" }}>
          {`Add your client's content to the Content Hub and use Lynxi AI to fill in any gaps`}
          .<br /> Share content for approval and keep track of its progress.
        </Box>
        <Box onClick={() => navigateToCms()} sx={{ marginTop: "40px" }}>
          <ColorButton>Add Content</ColorButton>
        </Box>
      </>
    </BasicEmptyStage>
  );
};
