import Box from "@mui/material/Box";
import * as React from "react";

export interface CardViewProps {
  icon ?: React.ReactNode
  children?: React.ReactNode;
}

export const CardView: React.FC<CardViewProps> = ({
  icon,
  children
}) => {

  return (
    <Box
      sx={{
        background: "#f5f8f7",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        margin: "0 auto",
        width: "90%",
      }}
    >
      <Box
        sx={{
          width: "130px",
          height: "130px",
          boxShadow: "0 30px 60px 0 rgba(0, 0, 0, 0.07)",
          border: "solid 1px #eaeaea",
          backgroundColor: "#fff",
          borderRadius: "65px",
          alignItems: "center",
          display: "flex",
          zIndex: 10,
          justifyContent: "center",
        }}
      >
        <Box>
         {icon ? icon: <img src={`/images/singles/q1_temp.png`} height="81px" width="76px" />}
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "65px",
          left: 0,
          display: "flex",
          width: "100%",
          paddingTop: "82px",
          background: "#fff",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            minHeight: '200px'
          }}
        >
          {children}          
        </Box>
      </Box>
    </Box>
  );
};
