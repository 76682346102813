import { Grid, Box, styled } from "@mui/material";
import * as React from "react";
import "../cms.scss";
import {
  CMSComponent,
  PositionIndex,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";

const EmptyRadio = styled(Box)(() => ({
  width: "25px",
  height: "25px",
  borderRadius: "12.5px",
  boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
  backgroundColor: "#f9f9f9",
  marginRight: "10px",
}));

const FullRadio = styled(Box)(() => ({
  width: "25px",
  height: "25px",
  borderRadius: "12.5px",
	cursor: 'pointer',
  boxShadow: "inset 0 1px 3px 0 rgba(0, 0, 0, 0.08)",
  backgroundImage: "linear-gradient(159deg, #fb8332 0%, #f8c332 112%)",
  marginRight: "10px",
}));

export interface RadioSelectCompProps {
  comp: CMSComponent;
  location: PositionIndex;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean
}

export const RadioSelectComp: React.FC<RadioSelectCompProps> = ({
  comp,
  location,
  onUpdateComp,
  readonly
}) => {
  const updateCompData = (text: string) => {
    const updateComp = { ...comp, value: text };
    onUpdateComp(UpdateCompBuilder(location, updateComp));
  };

  const setSelectedOption = (newValue: string) => {
    if (newValue !== comp.value) {
      updateCompData(newValue);
    }
  };

  return (
    <Grid item xs={12} sx={{ fontFamily: "Nunito", fontSize: "14px" }}>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={{ padding: "0 10px" }}>{comp.description}</Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <Grid container gap={"30px"}>
            {comp.options &&
              comp.options.map((option, idx) => (
                <Grid item xs={3} key={`radio-${idx}`}>
                  <Box sx={{ display: "flex" }}>
                    {comp.value === option ? (
                      <FullRadio />
                    ) : (
                      <EmptyRadio onClick={() => readonly? {} :setSelectedOption(option)} />
                    )}
                    {option}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
