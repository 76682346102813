import { cloneDeep, get, intersection, uniq } from "lodash";
import { map, keys } from "lodash/fp";
import { SavedQuestion } from "../api/surveyApi";
import {
  CMSComponent,
  CMSListComponent,
  CmsValuesByTypes,
  EditorAddedData,
  EditorLabeledData,
  ListValues,
  Page,
  PageSeoInfo,
  Section,
  Single,
  SingleSerachItem,
} from "../cms/cmsSlice";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import { SERVICE_CMS_ID } from "./Constants";
import _ from "lodash";
import { DayOpenHoursInfo } from "../cms/components/OpenHours/OpenHoursComp";

export default class TreeUtils {
  static getFileNameWithoutExt = (file: File) => {
    try {
      return file.name.split(".").slice(0, -1).join(".");
    } catch {
      return "";
    }
  };

  static orderDaysofWeek = (days: DayOpenHoursInfo[]) => {
    const sortedData = _.sortBy(days, (item: DayOpenHoursInfo) => {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek.indexOf(item.name);
    });
    return sortedData;
  };


  static converSingles = (
    singles: Single[],
    oldDic: { [id: string]: string },
    cmsTypesDics: CmsValuesByTypes
  ) => {
    map((single) => {
      map((comp) => {
        if (comp.mapKey && get(cmsTypesDics.ai, comp.mapKey)) {
          if (comp.type === "serviceList") {
            comp.showContentPopup = true;
          } else {
            single.showContentPopup = true;
          }
        }
        switch (comp.type) {
          case "title":
          case "subtitle":
          case "dropdown":
          case "multidropdown":
          case "multiCheckBox":
          case "media":
          case "simpleText":
          case "gallery":
          case "imagereview":
          case "serviceList":
          case "seoPages":
          case "videos":
          case "openHours":
          case "paragraph": {
            const mapKey = comp.mapKey.replace("A", "");
            if (get(oldDic, mapKey)) {
              comp.value = oldDic[mapKey];
            }
            break;
          }
          case "list": {
            switch (comp.listType) {
              case "sociallink":
              case "actions":
              case "media": {
                map((image) => {
                  const mapKey = image.mapKey.replace("A", "");
                  if (get(oldDic, mapKey)) {
                    image.value = oldDic[mapKey];
                  }
                }, comp.components);
                break;
              }
              case "openhours": {
                map((openhourline) => {
                  map((openhourcomp) => {
                    const mapKey = openhourcomp.mapKey.replace("A", "");
                    if (get(oldDic, mapKey)) {
                      openhourcomp.value = oldDic[mapKey];
                    }
                  }, openhourline.components);
                }, comp.components);
              }
            }
            break;
          }
          default:
            break;
        }
      }, single.components);
    }, singles);
  };

  static extractFromSection = (
    singles: Single[],
    oldDic: { [id: string]: string },
    byRequired?: boolean,
    filteredSingles?: number[]
  ) => {
    map(
      (single) => {
        map((comp) => {
          switch (comp.type) {
            case "title":
            case "subtitle":
            case "simpleText":
            case "media":
            case "dropdown":
            case "multidropdown":
            case "multiCheckBox":
            case "paragraph": {
              if (comp.isFromTemplate) {
                return ""
              } else if (byRequired) {
                if (comp.required) {
                  oldDic[comp.mapKey] = comp.value;
                }
              } else if (comp.value && comp.value !== "") {
                oldDic[comp.mapKey] = comp.value;
              }
              break;
            }
            case "imagereview":
            case "gallery":
            case "openHours":
            case "serviceList":
            case "seoPages":
            case "videos": {
              if (byRequired) {
                if (comp.required) {
                  oldDic[comp.mapKey] = comp.value;
                }
              } else if (comp.value && comp.value.length > 0) {
                oldDic[comp.mapKey] = comp.value.filter((item: any) => _.get(item, 'fromTemplate', false) === false)
              }
              break;
            }
            case "list": {
              switch (comp.listType) {
                case "sociallink":
                case "actions":
                case "media": {
                  map((image) => {
                    if (image.value && image.value !== "") {
                      oldDic[image.mapKey] = image.value;
                    }
                  }, comp.components);
                  break;
                }
                case "openhours": {
                  map((openhourline) => {
                    map((openhourcomp) => {
                      const mapKey = openhourcomp.mapKey.replace("A", "");
                      oldDic[mapKey] = openhourcomp.value;
                    }, openhourline.components);
                  }, comp.components);
                }
              }
              break;
            }
            default:
              break;
          }
        }, single.components);
      },
      filteredSingles
        ? singles.filter((s) => filteredSingles.includes(s.singleKey))
        : singles
    );
  };

  static extractDicFromCms = (cmsPages: Page[]) => {
    const cmsDic: { [id: string]: string } = {};
    map((page) => {
      map((section) => {
        if (get(section, "subSections")) {
          map((subSection) => {
            TreeUtils.extractFromSection(subSection.singles, cmsDic);
          }, section.subSections);
        } else {
          TreeUtils.extractFromSection(section.singles, cmsDic);
        }
      }, page.sections);
    }, cmsPages);
    return cmsDic;
  };

  static extractDataFromSelectedFormQuestions = (
    cmsPages: Page[],
    selectedSingles?: number[]
  ) => {
    const cmsDic: { [id: string]: string } = {};
    map((page) => {
      map((section) => {
        TreeUtils.extractFromSection(
          section.singles,
          cmsDic,
          false,
          selectedSingles
        );
      }, page.sections);
    }, cmsPages);
    return cmsDic;
  };

  static extractTotalFormQuestionsFromSingles = (
    cmsPages: Page[],
    selectedSingles?: number[]
  ) => {
    let totalQuestions = 0;
    map((page) => {
      map((section) => {
        map(
          (single) => {
            totalQuestions += single.components.length;
          },
          selectedSingles
            ? section.singles.filter((s) =>
                selectedSingles.includes(s.singleKey)
              )
            : section.singles
        );
      }, page.sections);
    }, cmsPages);
    return totalQuestions;
  };

  static extractTypesDicFromCms = (cmsPages: Page[]) => {
    const cmsDic: { [id: string]: string } = {};
    map((page) => {
      map((section) => {
        map((single) => {
          map((comp) => {
            cmsDic[comp.mapKey] = comp.type;
          }, single.components);
        }, section.singles);
      }, page.sections);
    }, cmsPages);
    return cmsDic;
  };

  static singleHasValue = (single: Single) => {
    const singleDic: { [id: string]: any } = {};
    TreeUtils.extractFromSingle(single, singleDic);
    return keys(singleDic).length > 0;
  };

  static singleHasValueNew = (
    single: Single,
    cmsDic: { [id: string]: any }
  ) => {
    const foundValues = single.components.filter((comp) => {
      const value = _.get(cmsDic, comp.mapKey);
      if (value) {
        if (typeof value === "string") {
          return value !== "";
        } else if (Array.isArray(value)) {
          if (value.length > 0) {
            if (value.length === 1) {
              const keysWithValues = _.keys(value[0]).filter((key) => {
                return  value[0][key] && value[0][key] !== ""
              })
              return keysWithValues.length > 0 
            } else {
              return true
            }
          }
        }        
      }
      return false;
    });
    return foundValues.length > 0;
  };

  static singleValuesCount = (single: Single) => {
    const singleDic: { [id: string]: any } = {};
    TreeUtils.extractFromSingle(single, singleDic);
    return keys(singleDic).length;
  };

  static singleHasValueForPdf = (single: Single) => {
    const singleDic: { [id: string]: any } = {};
    TreeUtils.extractFromSingle(single, singleDic);
    return keys(singleDic).length > 0;
  };

  static calculateAnsweredQuestions = (
    cmsPages: Page[],
    filteredSingles?: number[]
  ) => {
    let answeredQuestions = 0;
    map((page) => {
      map((section) => {
        map(
          (single) => {
            answeredQuestions += TreeUtils.singleValuesCount(single);
          },
          filteredSingles
            ? section.singles.filter((s) =>
                filteredSingles.includes(s.singleKey)
              )
            : section.singles
        );
      }, page.sections);
    }, cmsPages);
    return answeredQuestions;
  };

  static fillCmsWithDataFromDic = (
    pages: Page[],
    dic: { [id: string]: string },
    cmsTypesDics: CmsValuesByTypes
  ) => {
    map((page) => {
      map((section) => {
        if (get(section, "subSections")) {
          map((subSection) => {
            TreeUtils.converSingles(subSection.singles, dic, cmsTypesDics);
          }, section.subSections);
        } else {
          TreeUtils.converSingles(section.singles, dic, cmsTypesDics);
        }
      }, page.sections);
    }, pages);

    return pages;
  };

  static getSinglesSearchList = (pages: Page[]) => {
    const singleArray: SingleSerachItem[] = [];
    map((page) => {
      _.map(page.sections, (section: Section, idx: number) => {
        map((single: Single) => {
          singleArray.push({
            title: single.description,
            sectionIndex: idx,
            singleId: single.singleKey,
          });
        }, section.singles);
      });
    }, pages);

    return singleArray;
  };

  static fillCMSWithContentFormDic = (
    section: Section,
    dic: { [id: string]: any }
  ) => {
    map((single) => {
      map((comp) => {
        const mapKey = comp.mapKey;
        if (get(dic, mapKey, "") !== "") {
          comp.value = dic[mapKey];
        }
      }, single.components);
    }, section.singles);
  };

  static htmlToText = (html: string) => {
    if (!html) return "";
    if (html.indexOf("<p") > -1) {
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        try {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          return editorState.getCurrentContent().getPlainText();
        } catch (e) {
          return html;
        }
      } else {
        return html;
      }
    } else {
      return html;
    }
  };

  static getDicFromProjectCms = (section: Section) => {
    const dic: { [id: string]: string } = {};
    map((single) => {
      map((comp) => {
        // if (comp.value !== "") {
        dic[comp.mapKey] = comp.value;
        // }
      }, single.components);
    }, section.singles);
    return dic;
  };

  static fillBriefWithProjectData = (
    projectData: string,
    briefData: string
  ) => {
    const briefJson = JSON.parse(briefData);
    const projectDic: { [id: string]: any } = JSON.parse(projectData);
    const briefPages = get(briefJson, "pages", []);
    const elememts = briefPages
      .flatMap((page: any) => {
        return get(page, "elements", []);
      })
      .flatMap((element: any) => {
        return [element, ...get(element, "elements", [])];
      })
      .flatMap((elem: any) => {
        const metaId = get(elem, "metaId", "") as string;
        if (metaId === SERVICE_CMS_ID) return [];
        const items = get(elem, "items", []);
        const itemskeys = items.map((item: any) => {
          return get(item, "metaId", "") as string;
        });
        if (metaId !== "" && keys(projectDic).indexOf(metaId) >= 0) {
          const elementType = get(elem, "type", "");
          if (elementType === "dropdown") {
            const elementValue = projectDic[metaId];
            if (elementValue.other && elementValue.other !== "") {
              return [
                { name: get(elem, "name", ""), value: "other" },
                {
                  name: `${get(elem, "name", "")}-Comment`,
                  value: elementValue.other,
                },
              ];
            } else {
              return [
                { name: get(elem, "name", ""), value: elementValue.value },
              ];
            }
          } else {
            const data = TreeUtils.htmlToText(projectDic[metaId]);
            return [{ name: get(elem, "name", ""), value: data }];
          }
        } else if (intersection(keys(projectDic), itemskeys).length > 0) {
          const valueDic: { [id: string]: string } = {};
          itemskeys.forEach((itemKey: string, idx: number) => {
            if (keys(projectDic).indexOf(itemKey) >= 0) {
              const name = get(items[idx], "name", "") as string;
              if (name !== "") {
                valueDic[name] = projectDic[itemKey];
              }
            }
          });
          return [{ name: get(elem, "name", ""), value: valueDic }];
        } else return [];
      });

    return elememts;
  };

  static extractFromSingle = (
    single: Single,
    oldDic: { [id: string]: string }
  ) => {
    map((comp) => {
      switch (comp.type) {
        case "title":
        case "subtitle":
        case "simpleText":
        case "media":
        case "paragraph": {
          if (comp.value && comp.value !== "") {
            oldDic[comp.mapKey] = comp.value;
          }
          break;
        }
        case "dropdown": {
          if (
            comp.value &&
            comp.value.value &&
            (comp.value.value !== "" || comp.value.other !== "")
          ) {
            oldDic[comp.mapKey] = comp.value;
          }
          break;
        }
        case "multiCheckBox":
        case "multidropdown":
        case "openHours":
        case "imagereview":
        case "gallery": {
          if (comp.value && comp.value.length > 0) {
            oldDic[comp.mapKey] = comp.value;
          }
          break;
        }
        case "videos": {
          if (comp.value && comp.value.length > 0) {
            if (comp.value[0] !== "") oldDic[comp.mapKey] = comp.value;
          }
          break;
        }
        case "serviceList": {
          if (comp.value && comp.value.length > 0) {
            const firstItem: ListValues = comp.value[0];
            if (
              (firstItem.image && firstItem.image !== "") ||
              (firstItem.paragraph && firstItem.paragraph !== "") ||
              (firstItem.subTitle && firstItem.subTitle !== "") ||
              (firstItem.title && firstItem.title !== "")
            ) {
              oldDic[comp.mapKey] = comp.value;
            }
          }
          break;
        }
        case "seoPages": {
          if (comp.value && comp.value.length > 0) {
            const firstItem: PageSeoInfo = comp.value[0];
            if (
              (firstItem.title && firstItem.title !== "") ||
              (firstItem.page && firstItem.page !== "") ||
              (firstItem.metaData && firstItem.metaData !== "") ||
              (firstItem.keywords && firstItem.keywords !== "")
            ) {
              oldDic[comp.mapKey] = comp.value;
            }
          }
          break;
        }
        case "list": {
          switch (comp.listType) {
            case "sociallink":
            case "actions":
            case "media": {
              map((image) => {
                if (image.value && image.value !== "") {
                  oldDic[image.mapKey] = image.value;
                }
              }, comp.components);
              break;
            }
            case "openhours": {
              map((openhourline) => {
                map((openhourcomp) => {
                  const mapKey = openhourcomp.mapKey.replace("A", "");
                  oldDic[mapKey] = openhourcomp.value;
                }, openhourline.components);
              }, comp.components);
            }
          }
          break;
        }
        default:
          break;
      }
    }, single.components);
  };

  static createSinglesFromQuestions = (
    metaId: number,
    singleId: number,
    questionForSection: SavedQuestion[]
  ) => {
    const newSingles: Single[] = [];
    map((question) => {
      const components: CMSComponent[] = [];
      let singleTitle = "";
      if (question.type === "text") {
        const component = {
          type: "simpleText",
          description: question.title ? question.title : question.name,
          metaId: metaId,
          mapKey: `${metaId}`,
          value: question.value,
          fullWidth: true,
          listType: "",
          components: [],
        };
        singleTitle = question.section ? question.section : "";
        metaId = metaId + 1;
        components.push(component);
      } else if (question.type === "comment") {
        const component = {
          type: "paragraph",
          description: question.title ? question.title : question.name,
          metaId: metaId,
          mapKey: `${metaId}`,
          value: question.value,
          fullWidth: true,
          listType: "",
          components: [],
        };
        metaId = metaId + 1;
        components.push(component);
      } else if (question.type === "file") {
        if (question.value.length > 0) {
          const component = {
            type: "gallery",
            description: question.title ? question.title : question.name,
            metaId: metaId,
            mapKey: `${metaId}`,
            value: question.value,
            listType: "media",
            components: [],
          };
          components.push(component);
        } else {
          const component = {
            type: "media",
            description: question.title ? question.title : question.name,
            metaId: metaId,
            mapKey: `${metaId}`,
            value: question.value,
            fullWidth: false,
            listType: "",
            components: [],
          };
          components.push(component);
        }
        singleTitle = question.section ? question.section : "";
        metaId = metaId + 1;
      } else if (question.type === "multipletext") {
        singleTitle = question.section
          ? question.section
          : question.title
          ? question.title
          : "";
        question.items.forEach((item) => {
          const component = {
            type: "simpleText",
            description: item.title ? item.title : item.name,
            metaId: metaId,
            mapKey: `${metaId}`,
            value: item.value,
            fullWidth: false,
            listType: "",
            components: [],
          };
          metaId = metaId + 1;
          components.push(component);
        });
      }
      if (components.length > 0) {
        const single = {
          description: singleTitle,
          icon: "",
          singleKey: singleId,
          components: components,
        };
        singleId += 1;
        newSingles.push(single);
      }
    }, questionForSection);
    return { singles: newSingles, metaId: metaId, singleId: singleId };
  };

  static getCompSection = (pages: Page[]) => {
    return map((section) => {
      return map((single) => {
        return `${single.singleKey}`;
      }, section.singles);
    }, pages[0].sections);
  };

  static updateCmsWithEditor = (
    pages: Page[],
    editor: EditorAddedData,
    onlyForThisProject?: boolean
  ) => {
    const _existingSections = _.get(editor, "existingSections", []);
    const _addedSections = _.get(editor, "addedSections", []);
    const existingSections = onlyForThisProject
      ? [..._existingSections, ..._addedSections]
      : _existingSections;
    if (existingSections.length > 0) {
      map((section) => {
        const existSection = existingSections.find(
          (s) => s.description === section.description
        );
        if (existSection) {
          const singles = cloneDeep(existSection.singles);
          section.singles = [...section.singles, ...singles];
        }
      }, pages[0].sections);
    }
    if (onlyForThisProject) {
      const existingProjectMenuItems = pages[0].sections.map(
        (s) => s.description
      );
      const newSections = editor.addedSections.filter(
        (s) => !existingProjectMenuItems.includes(s.description)
      );
      pages[0].sections = [...pages[0].sections, ...cloneDeep(newSections)];
    } else {
      pages[0].sections = [...pages[0].sections, ...cloneDeep(_addedSections)];
    }
  };

  static updateCmsWithLabels = (
    pages: Page[],
    editorLabelData: EditorLabeledData
  ) => {
    map((section) => {
      map((single: Single) => {
        if (
          editorLabelData.singlesLabels &&
          get(editorLabelData.singlesLabels, single.singleKey)
        ) {
          single.description = get(
            editorLabelData.singlesLabels,
            single.singleKey
          );
        }
        if (
          editorLabelData.singlesDescriptionLabels &&
          get(editorLabelData.singlesDescriptionLabels, single.singleKey)
        ) {
          single.header = get(
            editorLabelData.singlesDescriptionLabels,
            single.singleKey
          );
        }
        map((component: CMSComponent) => {
          if (
            editorLabelData.compLabels &&
            get(editorLabelData.compLabels, component.mapKey)
          ) {
            const compLabel = get(editorLabelData.compLabels, component.mapKey);
            if (get(compLabel, "title")) {
              component.description = get(compLabel, "title");
            }
            if (get(compLabel, "placeHolder")) {
              component.placeHolder = get(compLabel, "placeHolder");
            }
            if (get(compLabel, "options")) {
              component.options = get(compLabel, "options");
            }
            if (get(compLabel, "labels")) {
              (component as CMSListComponent).labels = get(compLabel, "labels");
            }
          }
        }, single.components);
      }, section.singles);
    }, pages[0].sections);
  };

  static updateCMSWithNonCmsList = (
    pages: Page[],
    questions: SavedQuestion[]
  ) => {
    let metaId = 9000;
    let singleId = 1000;
    const sectionNames: string[] = [];

    const updatePagesForQuestion = questions.map((q) => {
      return !q.cmsPage ||
        q.cmsPage === "" ||
        (q.cmsOtherPage && q.cmsOtherPage === "")
        ? { ...q, cmsPage: "General" }
        : q;
    });

    map((section) => {
      const sectionName = section.description;
      sectionNames.push(sectionName);
      const questionForSection = updatePagesForQuestion.filter(
        (q) => q.cmsPage && q.cmsPage === sectionName
      );
      const addSinglesResult = TreeUtils.createSinglesFromQuestions(
        metaId,
        singleId,
        questionForSection
      );
      metaId = addSinglesResult.metaId;
      singleId = addSinglesResult.singleId;
      section.singles = [...section.singles, ...addSinglesResult.singles];
    }, pages[0].sections);

    const questionWithNewPages = updatePagesForQuestion.filter(
      (q) =>
        !q.cmsPage || q.cmsPage === "" || sectionNames.indexOf(q.cmsPage) === -1
    );
    const pagesNamesInNewPages: string[] = uniq(
      questionWithNewPages.map((q) => {
        if (!q.cmsPage || q.cmsPage === "") {
          return "";
        } else if (q.cmsOtherPage) {
          return q.cmsOtherPage;
        } else {
          return "";
        }
      })
    );

    const newSections: Section[] = [];
    map((page) => {
      const questions = questionWithNewPages.filter((q) =>
        page === ""
          ? !q.cmsPage || q.cmsPage === ""
          : q.cmsOtherPage && q.cmsOtherPage === page
      );
      const addSinglesResult = TreeUtils.createSinglesFromQuestions(
        metaId,
        singleId,
        questions
      );
      metaId = addSinglesResult.metaId;
      singleId = addSinglesResult.singleId;
      const newSection: Section = {
        singles: addSinglesResult.singles,
        description: page === "" ? "General" : page,
        type: "",
      };
      newSections.push(newSection);
    }, pagesNamesInNewPages);
    pages[0].sections = [...pages[0].sections, ...newSections];
  };
}
