import { Box, Divider, TextField } from "@mui/material";
import { FC } from "react";
import * as React from "react";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { StaticDropDown } from "../../Intro/StaticDropDown";
import { useAppSelector } from "../../app/hooks";
import { CMSState } from "../cmsSlice";
import { RootState } from "../../app/store";
import useAsyncEffect from "use-async-effect";
import { BasicRichEditor } from "../../basic/BasicRichEditor/BasicRichEditor";
import { EditorEditMultiCheckBox } from "./EditorEditMultiCheckBox";
import { BasicDraggableDialog } from "../../basic/BasicDraggableDialog";

export interface AddNewTopicInfo {
  title: string;
  placeholder: string;
  type: string;
  sectionTitle: string;
  sectionImage: string;
  sectionName: string;
  options: string[];
  global: boolean;
}

export interface EditorNewTopicPopupProps {
  onClose(): void;
  onAddTopic(topicInfo: AddNewTopicInfo): void;
}

export const EditorNewTopicPopup: FC<EditorNewTopicPopupProps> = ({
  onClose,
  onAddTopic,
}) => {
  const [topicName, setTopicName] = React.useState<string>("");
  const [topicPlaceHolder, setTopicPlaceHolder] = React.useState<string>("");
  const [itemsList, setItemsList] = React.useState<string[]>([]);
  const [sectionTitle, setSectionTitle] = React.useState<string>("");
  const [topicType, setTopicType] = React.useState<string>("Paragraph");
  const [sectionsList, setSectionsList] = React.useState<string[]>([]);
  const [sectionName, setSectionName] = React.useState<string>("");
  const [global, setGlobal] = React.useState<boolean>(true);

  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );

  const onUpdateTopicType = async (val: string | null) => {
    setTopicType(val ?? "Paragraph");
  };

  const onUpdateSectionName = async (val: string | null) => {
    setSectionName(
      val ?? cmsState.pages[0].sections[cmsState.currentSection].description
    );
  };

  const onAddNewTopicClick = () => {
    onAddTopic({
      title: topicName,
      placeholder: topicPlaceHolder,
      type: topicType,
      sectionTitle: sectionTitle,
      sectionImage: "",
      sectionName: sectionName,
      options: itemsList,
      global: global,
    });
  };

  useAsyncEffect(async () => {
    if (cmsState.pages.length > 0) {
      setSectionsList(
        cmsState.pages[0].sections.map((section) => section.description)
      );
      setSectionName(
        cmsState.pages[0].sections[cmsState.currentSection].description
      );
    }
  }, [cmsState.pages]);

  return (
    <BasicDraggableDialog
      onClose={onClose}
      title="Add a new section to your Content Hub"
      extraWidth={"800px"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Box sx={{ fontWeight: 400, marginTop: "40px", marginBottom: "35px" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "30px",
              marginTop: "20px",
            }}
          >
            <Box sx={{ width: "60%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box>Section title</Box>
                <TextField
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    background: "#f9f9f9",
                    "& input.Mui-disabled": {
                      color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                      WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                    },
                  }}
                  inputProps={{ className: "dropDownOther" }}
                  placeholder={"Enter section title"}
                  maxRows={1}
                  value={sectionTitle}
                  onChange={(e) => {
                    setSectionTitle(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ width: "40%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box>Menu item</Box>
                <StaticDropDown
                  handleChange={(val: string | null) =>
                    onUpdateSectionName(val)
                  }
                  value={sectionName}
                  showError={false}
                  options={sectionsList}
                  placeHolder=""
                  background="#f9f9f9"
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", margin: "20px 0" }}>
            <Divider sx={{ borderColor: "#dfdfdf", width: "100%" }} />
          </Box>

          <Box sx={{ width: "40%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box>Type</Box>
              <StaticDropDown
                handleChange={(val: string | null) => onUpdateTopicType(val)}
                value={topicType}
                showError={false}
                options={[
                  "Paragraph",
                  "Image",
                  "Gallery",
                  "Dropdown",
                  "Multi select dropdown",
                  "Checkbox",
                  "Rating",
                ]}
                placeHolder=""
                background="#f9f9f9"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "30px",
              marginTop: "20px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box>Question</Box>
                {topicType === "Paragraph" ||
                topicType === "Checkbox" ||
                topicType === "Gallery" ||
                topicType === "Rating" ? (
                  <BasicRichEditor
                    value={topicName}
                    onUpdate={(value: string) => setTopicName(value)}
                  />
                ) : (
                  <TextField
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      background: "#f9f9f9",
                      "& input.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                        WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                      },
                    }}
                    inputProps={{ className: "dropDownOther" }}
                    placeholder={"Enter your question"}
                    maxRows={1}
                    value={topicName}
                    onChange={(e) => {
                      setTopicName(e.target.value);
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {(topicType === "Dropdown" ||
            topicType === "Multi select dropdown" ||
            topicType === "Checkbox") && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "30px",
                marginTop: "10px",
              }}
            >
              <EditorEditMultiCheckBox
                options={itemsList}
                onSetComponentsOptions={(options: string[]) =>
                  setItemsList(options)
                }
              />
            </Box>
          )}
          {topicType === "Paragraph" && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "30px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Box>Placeholder</Box>
                  <TextField
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      background: "#f9f9f9",
                      "& input.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                        WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                      },
                    }}
                    inputProps={{ className: "dropDownOther" }}
                    placeholder={"Help your client answer the question"}
                    multiline={true}
                    rows={4}
                    maxRows={4}
                    value={topicPlaceHolder}
                    onChange={(e) => {
                      setTopicPlaceHolder(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}>
            Cancel
          </Box>
          <ColorButton
            onClick={() => onAddNewTopicClick()}
            disabled={topicName === ""}
          >
            Add
          </ColorButton>
        </Box>
      </Box>
    </BasicDraggableDialog>
  );
};
