import { useMsal } from "@azure/msal-react";
import { Box, Fab, Grid, TextField, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import ProjectUtils from "../../projects/ProjectUtils";
import { CmsComment } from "../CmsComments/CmsComment";
import {
  CMSGalleryComponent,
  CMSState,
  ImageValues,
  PositionIndex,
  resetDirtyFlag,
  UpdateComp,
  UpdateCompBuilder,
} from "../cmsSlice";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { ImagePicker } from "./ImagePicker";
import { DeleteConfirmationPopup } from "../../PopUpMessage/DeleteProjectPopup";
import { useTranslation } from "react-i18next";
import { getAccessTokenSilence } from "../../auth/auth";
import ImageCommentViewerPopup from "./ImageCommentViewer/ImageCommentViewerPopup";


export interface ImageReviedData {
  imageSrc: string;
  imageTitle?: string;
  commentId: string;
}


export interface DesignImagesReviewProps {
  comp: CMSGalleryComponent;
  location: PositionIndex;
  onShowComment(e: any, commentKey: string): void;
  onUpdateComp(updateComp: UpdateComp): void;
  readonly?: boolean;
  singleKey: number;
}

export const DesignImagesReview: React.FC<DesignImagesReviewProps> = ({
  comp,
  location,
  onUpdateComp,
  onShowComment,
  singleKey,
  readonly,
}) => {
  const [showImages, setShowImages] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [deleteIndex, setDeleteIndex] = React.useState<number | undefined>()
  const [imageReviewData, setImageReviewData] = React.useState<ImageReviedData | undefined>()
  const cmsState: CMSState = useAppSelector(
    (state: RootState) => state.cmsData
  );
  const navigate = useNavigate();
  const instance = useMsal();
  const dispatch = useAppDispatch()
  const {t} = useTranslation()

  const addImage = (imgSrc: string, description?: string) => {
    updateImage(-1, imgSrc, description);
    setShowImages(false);
  };

  const updateImage = (index: number, img: string, description?: string) => {
    const deepClone = _.cloneDeep(comp);
    if (index === -1) {
      deepClone.value = [
        ...deepClone.value,
        { src: img, id: `${new Date().getTime()}`, title: description ?? "" },
      ];
    } else {
      deepClone.value[index].src = img;
      deepClone.value[index].title = description ?? "";
    }
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const onTextChange = (e: any, index: number) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value[index].title = e.target.value;
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const removeImage = (index: number) => {
    const deepClone = _.cloneDeep(comp);
    deepClone.value.splice(index, 1);
    onUpdateComp(UpdateCompBuilder(location, deepClone));
  };

  const addNewImageReview = () => {
    //
    setShowImages(true)
  }

  const onDeleteApproved = async () => {
    if (deleteIndex !== undefined) {
      removeImage(deleteIndex)
      onCancelDelete()
    }
  };

  const setItemToDelete = (item: HTMLDivElement, index: number) => {
      setDeleteIndex(index)
      setAnchorEl(item)
  }


  const onCancelDelete = () => {
    setDeleteIndex(undefined);
    setAnchorEl(null);
  };


  const onShowDesignComment = async (
    e: any,
    imgObj: ImageValues,
    index: number
  ) => {
    if (comp.hasFullReview) {
      if (cmsState.isClientMode) {
        navigate(
          `/cms/collaborator/${
            cmsState.metaDataId
          }/imageComments?commentId=${singleKey}__${imgObj.id}&title=${
            imgObj.title ?? ""
          }&img=${imgObj.src}`
        );
      } else if (cmsState.project) {
        const token = await getAccessTokenSilence(instance);
        if (token) {
           await ProjectUtils.silentSaveContentHubData(token.accessToken, cmsState);
        }
        dispatch(resetDirtyFlag())
        navigate(
          `/projects/${cmsState.project.id}/cms/${
            cmsState.project.cmsDataId
          }/imageComments?commentId=${singleKey}__${imgObj.id}&title=${
            imgObj.title ?? ""
          }&img=${imgObj.src}`
        );
        // setImageReviewData({ imageSrc: imgObj.src, imageTitle: imgObj.title ?? "", commentId: `${singleKey}__${imgObj.id}` })
      }
    } else {
      onShowComment(e, `${singleKey}__${index}`);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            marginBottom: "20px",
            fontWeight: "normal",
            fontSize: "14px",
          }}
        >
          {comp.description}
        </Typography>
        <Box sx={{ display: "flex", flexDirection:'column' }}>
          <Box sx={{  background: "#F9F9F9", display: "flex" , flexGrow:1 }}>
            <Grid container spacing={2} textAlign={"center"}>
              {comp.value.map((img, i) => (
                <Grid
                  item
                  xs={6}
                  key={`image-${i}`}
                  sx={{ position: "relative"}}
                >
                  <Box sx={{ display: "flex",background:'#fff', margin:'15px 10px', padding: '10px', position:'relative'}}>
                    <Box sx={{position:'absolute', top:'5px', right:'5px', cursor:'pointer'}} onClick={(e) => setItemToDelete(e.currentTarget, i)}>
                      <CloseIcon fontSize="small" />
                    </Box>
                    <Box sx={{ width: "150px", height: "112px" }}>
                      <img
                        src={img.src}
                        style={{ width: "150px", height: "112px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "20px",
                        display: "flex",
                        flexDirection: "column",
                        height: "112px",
                        flexGrow:1,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{width: '50px'}}>
                        <CmsComment
                          onShowComment={(e) => onShowDesignComment(e, img, i)}
                          singleKey={
                            comp.hasFullReview
                              ? `${singleKey}__${img.id}`
                              : `${singleKey}__${i}`
                          }
                          isClient={readonly}
                          noComment={false}
                          noText={true}
                        />
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                           sx={{
                            flexGrow:1,
                            width: "100%",
                            background: "#f9f9f9",
                            "& input.Mui-disabled": {
                              color: "rgba(0, 0, 0, 0.8) !important", // (default alpha is 0.38)
                              WebkitTextFillColor: "rgba(0, 0, 0, 0.8) !important",
                            },                
                          }}
                          className={img.title !=='' ? 'inputWithVal': ''}
                          label="Name"
                          maxRows={1}
                          value={img.title}
                          disabled={readonly}
                          onChange={(e) => onTextChange(e, i)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{display:'flex',width: '100%', height: 'auto', marginTop:'10px', justifyContent: 'flex-end', alignItems: 'center'}}>
                    Upload your wireframe or mockup
                     <Fab
                  size="small"
                  sx={{
											backgroundImage:
												"linear-gradient(143deg, #3023ae -60%, #c86dd7 102%)",
											color: "#fff",
                      marginLeft:'10px',
											"&:hover": { color: "#000" },
                    width: '35px',
                   height: '35px',
                  }}
                  onClick = { () => addNewImageReview()}
                >
                 <AddIcon fontSize="medium"  sx={{width:'18px'}}/>
                </Fab>             
          </Box>
        </Box>

       {imageReviewData && (
        <ImageCommentViewerPopup show={imageReviewData!== undefined} onClose={() => setImageReviewData(undefined)} 
        imageReviewData={imageReviewData} />
       )}
       
        <ImagePicker
          onClose={() => setShowImages(false)}
          onAddImages={(img: string, description?: string) => addImage(img, description)}
          showView={showImages}
          onlyUpload={false}
          allfiles={false}
        />
         <DeleteConfirmationPopup
          title={t("cms.delete_design_popup.title")}
          placement={'left'}
          description={t("cms.delete_design_popup.message")}
          onDeleteSite={() => onDeleteApproved()}
          onClose={() => onCancelDelete()}
          anchorEl={anchorEl}
          autoClose={true}
        />
      </Grid>
    </>
  );
};
