import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { FC } from "react";
import { useAsyncEffect } from "use-async-effect";

import { Box, Divider, Modal } from "@mui/material";
import { get, keys } from "lodash";
import { SiteInfo } from "../../api/cmsApi";
import { CMSComponent, CmsValuesByTypes } from "../../cms/cmsSlice";
import TreeUtils from "../../utils/TreeUtils";
import "../social.scss";
import { ColorButton } from "../../projects/ProjectUIHelper";
import { DayOpenHoursInfo } from "../../cms/components/OpenHours/OpenHoursComp";
import { getAccessTokenSilence } from "../../auth/auth";
import { ReactComponent as LynxiSyncIcon } from "../../assets/social/lynxiSyncIcon.svg";
import { ReactComponent as LeftPurpleIcon } from "../../assets/social/leftpurple.svg";
import { ReactComponent as RightPurpleIcon } from "../../assets/social/rightpurple.svg";
import { ReactComponent as RightGrayIcon } from "../../assets/social/rightgray.svg";
import { ReactComponent as LeftGrayIcon } from "../../assets/social/leftgray.svg";
import { SocialSyncData } from "./SocialSync";
import _ from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  width: "90vw",
};

export enum SyncDirectionEnum {
  None = 0,
  SocialToCMS = 1,
  CMSToSocial = 2,
  Both = 3,
}

export interface SyncDirectionInfo {
  currentDirection: SyncDirectionEnum;
  avaliableDirections: SyncDirectionEnum;
}

export interface SocialSyncPreviewProps {
  socialSyncData: SocialSyncData;
  show: boolean;
  onContineSync(
    cmsUpdateRec: { [id: string]: any },
    socialUpdateRec: { [id: string]: any },
    token: string
  ): void;
  onClose(noSocialSyncNeeded: boolean): void;
  icon: React.ReactNode;
  socialName: string;
  onlyFromSocial?: boolean;
}

export const SocialSyncPreview: FC<SocialSyncPreviewProps> = ({
  socialSyncData,
  show,
  onContineSync,
  onClose,
  icon,
  socialName,
  onlyFromSocial,
}) => {
  const [componentList, setCommentsList] = React.useState<CMSComponent[]>([]);
  const [cmsDic, setCmsDic] = React.useState<{ [id: string]: any }>({});
  const [syncDirectionDic, setSyncDirectionDic] = React.useState<{
    [id: string]: SyncDirectionInfo;
  }>({});
  const msalInstance = useMsal();

  const checkSocialDicForValue = (
    mapKey: string,
    currentValue: any,
    cmsAiDic?: CmsValuesByTypes
  ) => {
    if (!cmsAiDic) {
      return currentValue;
    }
    const cmsSocialValues = get(get(cmsAiDic, "social", {}), mapKey, {});
    const currentSocial = get(cmsSocialValues, socialName.toLowerCase(), "");
    const cleanSocialText = TreeUtils.htmlToText(currentSocial);
    return cleanSocialText !== "" ? currentSocial : currentValue;
  };

  const prepareCMSDataForSync = () => {
    const cmsUpdateRec: { [id: string]: any } = {};
    const syncKeys = keys(syncDirectionDic);
    syncKeys.map((key) => {
      if (
        syncDirectionDic[key].currentDirection === SyncDirectionEnum.SocialToCMS
      ) {
        cmsUpdateRec[key] = get(socialSyncData.socialCmsDic, key, "");
      }
    });
    return cmsUpdateRec;
  };

  const prepareSocialDataForSync = () => {
    const socialUpdateRec: { [id: string]: any } = {};
    const syncKeys = keys(syncDirectionDic);
    syncKeys.map((key) => {
      if (
        syncDirectionDic[key].currentDirection === SyncDirectionEnum.CMSToSocial
      ) {
        socialUpdateRec[key] = get(cmsDic, key, "");
      }
    });
    return socialUpdateRec;
  };

  const prepareSyncData = async () => {
    const token = await getAccessTokenSilence(msalInstance);
    if (token) {
      onContineSync(
        prepareCMSDataForSync(),
        prepareSocialDataForSync(),
        token.accessToken
      );
    }
  };

  const changeSyncDirection = (
    mapKey: string,
    syncDirectionsInfoForCmsKey: SyncDirectionInfo,
    direction: SyncDirectionEnum
  ) => {
    const newSyncDirectionDic = { ...syncDirectionDic };
    const newDirection =
      syncDirectionsInfoForCmsKey.avaliableDirections === SyncDirectionEnum.Both
        ? syncDirectionsInfoForCmsKey.currentDirection === direction
          ? SyncDirectionEnum.None
          : syncDirectionsInfoForCmsKey.currentDirection ===
            SyncDirectionEnum.CMSToSocial
          ? SyncDirectionEnum.SocialToCMS
          : SyncDirectionEnum.CMSToSocial
        : syncDirectionsInfoForCmsKey.currentDirection ===
          SyncDirectionEnum.None
        ? direction
        : SyncDirectionEnum.None;
    newSyncDirectionDic[mapKey] = {
      currentDirection: newDirection,
      avaliableDirections: syncDirectionsInfoForCmsKey.avaliableDirections,
    };
    setSyncDirectionDic(newSyncDirectionDic);
  };

  const renderSyncArrows = (mapKey: string) => {
    const syncDirection = syncDirectionDic[mapKey];
    return (
      <>
        {!onlyFromSocial &&
          !socialSyncData.socialItems.find((r) => `${r.cmsKey}` === mapKey)
            ?.ignoreForSocial && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor:
                  syncDirection.avaliableDirections ===
                    SyncDirectionEnum.None ||
                  syncDirection.avaliableDirections ===
                    SyncDirectionEnum.SocialToCMS
                    ? "auto"
                    : "pointer",
              }}
              onClick={() =>
                syncDirection.avaliableDirections === SyncDirectionEnum.None ||
                syncDirection.avaliableDirections ===
                  SyncDirectionEnum.SocialToCMS
                  ? {}
                  : changeSyncDirection(
                      mapKey,
                      syncDirection,
                      SyncDirectionEnum.CMSToSocial
                    )
              }
            >
              {syncDirection.currentDirection ===
              SyncDirectionEnum.CMSToSocial ? (
                <RightPurpleIcon />
              ) : (
                <RightGrayIcon />
              )}
            </Box>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            cursor:
              syncDirection.avaliableDirections === SyncDirectionEnum.None ||
              syncDirection.avaliableDirections ===
                SyncDirectionEnum.CMSToSocial
                ? "auto"
                : "pointer",
          }}
          onClick={() =>
            syncDirection.avaliableDirections === SyncDirectionEnum.None ||
            syncDirection.avaliableDirections === SyncDirectionEnum.CMSToSocial
              ? {}
              : changeSyncDirection(
                  mapKey,
                  syncDirection,
                  SyncDirectionEnum.SocialToCMS
                )
          }
        >
          {syncDirection.currentDirection === SyncDirectionEnum.SocialToCMS ? (
            <LeftPurpleIcon />
          ) : (
            <LeftGrayIcon />
          )}
        </Box>
      </>
    );
  };

  const forceDirection = (forceCMS?: boolean) => {
    const syncKeys = keys(syncDirectionDic);
    const syncDirectionDicNewSync: { [id: string]: SyncDirectionInfo } = {};
    syncKeys.map((key) => {
      if (forceCMS) {
        syncDirectionDicNewSync[key] = {
          currentDirection:
            syncDirectionDic[key].avaliableDirections ===
              SyncDirectionEnum.CMSToSocial ||
            syncDirectionDic[key].avaliableDirections === SyncDirectionEnum.Both
              ? SyncDirectionEnum.CMSToSocial
              : SyncDirectionEnum.None,
          avaliableDirections: syncDirectionDic[key].avaliableDirections,
        };
      } else {
        syncDirectionDicNewSync[key] = {
          currentDirection:
            syncDirectionDic[key].avaliableDirections ===
              SyncDirectionEnum.SocialToCMS ||
            syncDirectionDic[key].avaliableDirections === SyncDirectionEnum.Both
              ? SyncDirectionEnum.SocialToCMS
              : SyncDirectionEnum.None,
          avaliableDirections: syncDirectionDic[key].avaliableDirections,
        };
      }
    });
    setSyncDirectionDic(syncDirectionDicNewSync);
  };

  const calculateDirectionInfo = (
    hasCmsValue: boolean,
    hasSocialValue: boolean,
    allowOnlySocial?: boolean
  ) => {
    if (onlyFromSocial || allowOnlySocial) {
      hasCmsValue = false;
    }
    if (!hasCmsValue && !hasSocialValue) {
      return {
        currentDirection: SyncDirectionEnum.None,
        avaliableDirections: SyncDirectionEnum.None,
      };
    } else if (hasCmsValue && hasSocialValue) {
      return {
        currentDirection: SyncDirectionEnum.CMSToSocial,
        avaliableDirections: SyncDirectionEnum.Both,
      };
    } else if (hasCmsValue && !hasSocialValue) {
      return {
        currentDirection: SyncDirectionEnum.CMSToSocial,
        avaliableDirections: SyncDirectionEnum.CMSToSocial,
      };
    } else {
      return {
        currentDirection: SyncDirectionEnum.SocialToCMS,
        avaliableDirections: SyncDirectionEnum.SocialToCMS,
      };
    }
  };

  const calculateSyncDirection = (
    componentList: CMSComponent[],
    _cmsDic: { [id: string]: any }
  ) => {
    const tmpSyncDirectionDic: { [id: string]: SyncDirectionInfo } = {};
    componentList.forEach((component) => {
      const cmsItem = component.mapKey;

      switch (component.type) {
        case "sociallink":
        case "simpleText":
        case "paragraph": {
          const socialValue = get(socialSyncData.socialCmsDic, cmsItem, "");
          const cmsValue = get(_cmsDic, cmsItem, "");
          const socialText = TreeUtils.htmlToText(socialValue);
          tmpSyncDirectionDic[cmsItem] = calculateDirectionInfo(
            cmsValue !== "",
            socialText !== "",
            socialSyncData.socialItems.find((r) => `${r.cmsKey}` === cmsItem)
              ?.ignoreForSocial
          );
          break;
        }
        case "media":
          {
            const socialValue = get(socialSyncData.socialCmsDic, cmsItem, "");
            const cmsValue = get(_cmsDic, cmsItem, "");
            tmpSyncDirectionDic[cmsItem] = calculateDirectionInfo(
              cmsValue !== "",
              socialValue !== ""
            );
          }
          break;
        case "openHours":
          {
            const socialValue = get(socialSyncData.socialCmsDic, cmsItem, []);
            const cmsValue = get(_cmsDic, cmsItem, []);
            tmpSyncDirectionDic[cmsItem] = calculateDirectionInfo(
              cmsValue.length > 0,
              socialValue.length > 0
            );
          }
          break;
        case "dropdown":
          {
            const socialValue = get(socialSyncData.socialCmsDic, cmsItem, {
              value: "",
            })["value"];
            const cmsValue = get(_cmsDic, cmsItem, {
              value: "",
            })["value"];
            tmpSyncDirectionDic[cmsItem] = calculateDirectionInfo(
              cmsValue !== "",
              socialValue !== ""
            );
          }
          break;
      }
    });
    setSyncDirectionDic(tmpSyncDirectionDic);
  };

  useAsyncEffect(async () => {
    const cmsJson: SiteInfo = JSON.parse(socialSyncData.cmsSchema);
    const singles = cmsJson.pages[0].sections.flatMap((section) => {
      return section.singles.flatMap((single) => {
        return [single];
      });
    });
    const usedKeys = keys(socialSyncData.socialCmsDic);
    const components = singles.flatMap((single) => {
      return single.components.flatMap((comp) => {
        if (comp.components && comp.components.length > 0)
          return comp.components.flatMap((childcomp) => {
            if (usedKeys.includes(childcomp.mapKey)) {
              return { ...childcomp, title: single.description };
            } else return [];
          });
        if (usedKeys.includes(comp.mapKey)) {
          return { ...comp, title: single.description };
        } else return [];
      });
    });

    const cmsDicForSync: { [id: string]: any } = {};
    components.forEach((comp) => {
      if (comp.type === "paragraph") {
        const cmsItem = checkSocialDicForValue(
          comp.mapKey,
          socialSyncData.cmsDic[comp.mapKey] ?? "",
          socialSyncData.cmsAiDic
        );
        const socialConfig = socialSyncData.socialItems.find(
          (r) => `${r.cmsKey}` === comp.mapKey
        );
        const socialLimit = socialConfig ? socialConfig.socialLimit : undefined;
        const text = TreeUtils.htmlToText(cmsItem);
        cmsDicForSync[comp.mapKey] = socialLimit
          ? text.substring(0, socialLimit)
          : text;
      } else if (comp.type === "sociallink" || comp.type === "simpleText") {
        cmsDicForSync[comp.mapKey] = socialSyncData.cmsDic[comp.mapKey] ?? "";
      } else {
        cmsDicForSync[comp.mapKey] = socialSyncData.cmsDic[comp.mapKey];
      }
    });
    setCmsDic(cmsDicForSync);
    setCommentsList(components);
    calculateSyncDirection(components, cmsDicForSync);
  }, []);


  const orderDaysofWeek = (days: DayOpenHoursInfo[]) => {
    const sortedData = _.sortBy(days, (item: DayOpenHoursInfo) => {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return daysOfWeek.indexOf(item.name);
    })
    return sortedData;
  }

  const renderComponent = (
    component: CMSComponent,
    dict: { [id: string]: any }
  ) => {
    const value = dict[component.mapKey];
    switch (component.type) {
      case "sociallink":
      case "simpleText":
        return (
          <Box
            sx={{
              padding: "10px",
              height: "150px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                fontFamily: "Nunito",
                fontSize: "14px",
                padding: "10px",
                height: "50px",
                maxHeight: "50px",
                overflowY: "auto",
                color: "#555",
              }}
            >
              {value}
            </Box>
          </Box>
        );
      case "paragraph": {
        return (
          <Box
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              backgroundColor: "#fff",
              padding: "10px",
              height: "150px",
              maxHeight: "150px",
              color: "#555",
            }}
          >
            <Box sx={{ maxHeight: "130px", overflowY: "auto" }}>{value}</Box>
          </Box>
        );
      }
      case "media":
        return (
          value && value !== "" ? (
          <Box
            sx={{
              height: "130px",
              maxHeight: "130px",
              overflowY: "auto",
              alignItems: "center",
              display: "flex",
              width: component.fullWidth ? "100%" : "130px",
              backgroundImage: `url(${value})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
          </Box>
        ): (
          <Box
          className={'rect2'}
            sx={{
              border: "1px solid #ccc",
              height: "130px",
              maxHeight: "130px",
              overflowY: "auto",
              alignItems: "center",
              display: "flex",
              width: component.fullWidth ? "100%" : "200px",
            }}>
              <Box ></Box>
          </Box>
        ))
      case "openHours":
        return (
          <Box
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              backgroundColor: "#fff",
              padding: "10px",
              height: "170px",
              maxHeight: "170px",
              overflowY: "auto",
              color: "#555",
            }}
          >
            {value &&
              value.length > 0 &&
              orderDaysofWeek(value).map((item: DayOpenHoursInfo, idx: number) => (
                <Box key={`hour-sync-${idx}`}>
                  {item.name} -{" "}
                  {item.closeOnDay
                    ? "Closed"
                    : `${item.openHour} - ${item.closeHour}`}{" "}
                </Box>
              ))}
          </Box>
        );
      case "dropdown":
        return (
          <Box
            sx={{
              padding: "10px",
              height: "150px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                fontFamily: "Nunito",
                fontSize: "14px",
                padding: "10px",
                height: "50px",
                maxHeight: "50px",
                overflowY: "auto",
                color: "#555",
              }}
            >
              {value ? value.value : ""}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const getSocialTitle = (key: string) => {
    const item = socialSyncData.socialItems.find((r) => `${r.cmsKey}` === key);
    if (item) {
      return item.subKey
        ? item.subKey
        : item.socialOverideDescription
        ? item.socialOverideDescription
        : item.key;
    } else {
      return "";
    }
  };

  return (
    <>
      {show && cmsDic !== undefined && (
        <Modal open={show} onClose={onClose} sx={{ zIndex: 40000 }}>
          <Box sx={style}>
            <Box
              sx={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                display: "flex",
                alignItems: "center",
                height: "70px",
                backgroundColor: "#f4f8f7",
              }}
            >
              <Box
                sx={{
                  fontWeight: 800,
                  fontSize: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                Control how you sync
              </Box>
              {/* <Box sx={{ flexGrow: 1 }} /> */}
              <Box
                sx={{ paddingRight: "35px", cursor: "pointer", width: "60px" }}
                onClick={() => onClose(false)}
              >
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.676 1.919a1.145 1.145 0 0 0 0-1.596 1.146 1.146 0 0 0-1.595 0l-9.575 9.59L1.914.322a1.126 1.126 0 0 0-1.58 0 1.117 1.117 0 0 0 0 1.596l9.576 9.573-9.575 9.59a1.117 1.117 0 0 0 0 1.595c.431.43 1.15.43 1.58 0l9.59-9.59 9.576 9.59c.446.43 1.149.43 1.595 0 .432-.447.432-1.165 0-1.596l-9.575-9.589 9.575-9.573z"
                    fill="#000"
                    fillRule="nonzero"
                    opacity=".648"
                  />
                </svg>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                bgcolor: "#f5f8f7",
                paddingTop: "20px",
                paddingBottom: "30px",
                backgroundColor: "#f4f8f7",
              }}
            >
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px 30px",
                  margin: "0 auto",
                }}
              >
                <Box
                  sx={{
                    paddingBottom: "20px",
                    display: "flex",
                    fontSize: "16px",
                    justifyContent: "center",
                  }}
                >
                  {onlyFromSocial? `Sync your client's ${socialName} content with Content Hub` :
                  `For each section, decide which direction you want to pull
                  content from. ${socialName} to Lynxi or Lynxi to ${socialName}.`}
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "20%" }}></Box>
                  <Box
                    sx={{
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <LynxiSyncIcon />
                        <Box
                          sx={{
                            marginLeft: "20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          Lynxi
                        </Box>
                      </Box>
                      {!onlyFromSocial && (
                        <Box
                          sx={{
                            color: "#6c3dab",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          onClick={() => forceDirection(true)}
                        >
                          Sync all from Lynxi
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ margin: "20px 0px" }}>
                      <Divider />
                    </Box>
                  </Box>
                  <Box sx={{ width: "10%" }}></Box>
                  <Box
                    sx={{
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        {icon}
                        <Box
                          sx={{
                            marginLeft: "20px",
                            fontSize: "16px",
                            fontWeight: 600,
                          }}
                        >
                          {socialName}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          color: "#6c3dab",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => forceDirection(false)}
                      >
                        Sync all from {socialName}
                      </Box>
                    </Box>
                    <Box sx={{ margin: "20px 0px" }}>
                      <Divider />
                    </Box>
                  </Box>
                  <Box sx={{ width: "20%" }}></Box>
                </Box>
                <Box sx={{ height: "60vh", overflowY: "auto" }}>
                  {componentList.length > 0 &&
                    componentList.map((comp, idx) => {
                      return (
                        <Box
                          sx={{ display: "flex", marginBottom: "30px" }}
                          key={`complist-${idx}`}
                        >
                          <Box
                            sx={{
                              width: "20%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Box sx={{ fontWeight: "bold" }}>{comp.title}</Box>
                            <Box sx={{ fontSize: "14px" }}>
                              {comp.description}
                            </Box>
                          </Box>
                          <Box sx={{ width: "25%" }}>
                            {renderComponent(comp, cmsDic)}
                          </Box>
                          <Box
                            sx={{
                              width: "10%",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              gap: "20px",
                            }}
                          >
                            {renderSyncArrows(comp.mapKey)}
                          </Box>
                          <Box sx={{ width: "25%" }}>
                            {renderComponent(comp, socialSyncData.socialCmsDic)}
                          </Box>
                          <Box
                            sx={{
                              width: "20%",
                              display: "flex",
                              paddingLeft: "50px",
                              fontWeight: "bold",
                            }}
                          >
                            {getSocialTitle(comp.mapKey)}
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => onClose(false)}
                  >
                    Cancel
                  </Box>
                  <ColorButton onClick={() => prepareSyncData()}>
                    Sync now
                  </ColorButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
